import moment from "moment";
import { computed } from "vue";
import { filterDate } from "../utils/filterData";
import { notify } from "../utils/notification";
import store from "../store";

export function getReturnDateTime(duration) {
  if (!duration) {
    return;
  }
  const query = computed(() => store.state.vehicle.vehicleSearchQuery);
  var returnTime = null;
  var returnDate = null;
  if(!query.value.pickupdate) {
    notify("red", "Pickup date is required");
    return;
  }
  if(!query.value.pickupTime) {
    notify("red", "Pickup date is required");
    return;
  }
  const pickupDate = query.value.pickupdate;
  const unformat_date = moment(new Date(pickupDate)).format("YYYY-MM-DD");
  const unformat_time = query.value.pickupTime;
  const format_time = moment(unformat_time, "hh:mm a").format("HH:MM:SS");
  store.commit('vehicle/setDuration', duration);
  if (duration.value < 1) {
    returnTime = moment(unformat_time, "hh:mm a")
      .add(12, "hours")
      .format("hh:mm a");
    returnDate = moment(unformat_date + " " + format_time)
      .add(12, "hours")
      .format("YYYY/MM/DD");
    store.commit("vehicle/setSearchReturnDate", returnDate);
    store.commit("vehicle/setSearchReturnTime", returnTime);
    return;
  } else {
    returnTime = moment(unformat_time, "hh:mm a").format("hh:mm a");
    returnDate = moment(unformat_date)
      .add(duration.value, "days")
      .format("YYYY/MM/DD");
    store.commit("vehicle/setSearchReturnDate", returnDate);
    store.commit("vehicle/setSearchReturnTime", returnTime);
    return;
  }
}

export function runSearch(loadMore = false, page = 1) {
  return new Promise((resolve) => {
    const query = computed(() => store.state.vehicle.vehicleSearchQuery);
    const payload = {
      pickup_date: filterDate(
        query.value.pickupdate + " " + query.value.pickupTime,
        "YYYY/MM/DD HH:mm"
      ),
      estimated_return_date: filterDate(
        query.value.returndate + " " + query.value.returnTime,
        "YYYY/MM/DD HH:mm"
      ),
      yard_id: query.value.yard ? query.value.yard.id : null,
      transmission_id: query.value.filters.transmission,
      fuel_type_id: query.value.filters.fuel_type,
      model_id: query.value.filters.model,
      vehicle_type: query.value.filters.type,
      page: page,
    };

    if (loadMore) {
      store.dispatch("vehicle/searchMoreVehicles", payload).then((r) => {
        if (r.status == "empty") {
          notify("black", "There are no more vehicles available for rent on this set of dates.");
          return resolve(true);
        }
      });
    } else {
      store.dispatch("vehicle/searchVehicles", payload).then((r) => {
        if(r){
          return resolve(true);
        }
      });
    }
  });
}
