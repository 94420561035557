import axios from "axios";
const booking = {
  state: () => ({
    bookingData: null,
    bookingsByUser: null,
    bookingsByOperator:null,
    bookingsByVehicle: null,
    bookingOperator: null,
    pagination: null,
    bookingPaymentDetails: null
  }),

  getters: {
    getBookingDetails: (state) => state.booking_details,
  },

  actions: {
    createBooking(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/api/v1/bookings",
          method: "POST",
          data: payload,
        })
          .then((response) => {
            if (response.data.status == "success") {
              return resolve({
                status: "success",
                id: response.data.data.id,
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    updateBooking({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/bookings/${payload.id}`,
          method: "PUT",
          data: payload.data,
        })
          .then((response) => {
            if (response.data.status == "success") {
              commit("setBookingData", payload);
              return resolve({
                status: "success",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchSingleBooking({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/bookings/${payload}`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "success") {
              const booking = response.data.data.booking;
              commit("setBookingData", booking);
              return resolve({
                status: "success",
                booking: booking
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchBookingsFromOperator({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/operator/${payload}/bookings?sort=asc&perPage=100`,
          method: "GET",
        })
          .then((response) => {
            if (response.data && response.data.status == "success") {
              const bookings = response.data.data.bookings;
              commit("setBookingsByOperator", bookings);
              return resolve({
                status: "success",
              });
            } else if(response.status == 'failed'){
              commit("setBookingsByOperator", "");
              return resolve({
                status: "empty",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchBookingsFromCustomer({ commit }, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/users/${payload}/bookings?page=1&perPage=100&sort=desc`,
          method: "GET",
        })
          .then((response) => {
            if (response.data && response.data.status == "success") {
              const bookings = response.data.data.bookings;
              const pagination = response.data.data.pagination
              commit("setBookingsByUser", bookings);
              commit("setPagination", pagination);
              return resolve({
                status: "success",
              });
            } else {
              commit("setBookingsByUser", null);
            }
          })
          .catch((err) => {
            return resolve({
              status: err.response.data.data.message,
            });
          });
      });
    },
    fetchBookingsFromVehicle({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicle/${payload.vehicle_id}/bookings?page=${payload.page}&perPage=${payload.perPage}`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "success") {
              const bookings = response.data.data.bookings;
              commit("setBookingsByVehicle", bookings);
              return resolve({
                status: "success",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    createPaymentSource(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: "/api/v1/payments/create-source",
          method: "POST",
          headers: {
            "X-Papi-Key": "9BC265387896F51C58D57FC57C365",
          },
          data: payload ,
        }).then((response) => {
          if(response.data.status == 'payment_source_created') {
            const urls = response.data.data;
            return resolve({
              status: "success",
              urls: urls,
            });
          }
          
        });
      });
    },
    getPaymentSource(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/payments/get-source?invoice_number=${payload.booking_code}&booking_id=${payload.booking_id}`,
          method: "GET",
          headers: {
            "X-Papi-Key": "9BC265387896F51C58D57FC57C365",
          }
        }).then((response) => {
          if(response.data.status == 'chargeable') {
            return resolve({
              status: "chargeable",
            });
          }
        });
      });
    },
    transactPayment(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/payments/transact`,
          method: "POST",
          headers: {
            "X-Papi-Key": "9BC265387896F51C58D57FC57C365",
          },
          data: {
            invoice: payload
          }
        }).then((response) => {
          if(response.data.status == 'paid') {
            return resolve({
              status: "paid",
            });
          } else {
            return resolve({
              status: response.data.status
            })
          }
          
        });
      });
    },
    approveBooking(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/bookings/${payload}/approve`,
          method: "PUT",
        })
          .then((response) => {
            if (response.data.status == "success") {
              return resolve({
                status: "success",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    rejectBooking(_,payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/bookings/${payload}/disapprove`,
          method: "PUT",
        })
          .then((response) => {
            if (response.data.status == "success") {
              return resolve({
                status: "success",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    cancelBooking(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/bookings/${payload.booking_id}/cancel`,
          method: "PUT",
          data: {
            reason: payload.reason
          }
        }).then((response) => {
            return resolve(response);
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchOperatorData({ commit }, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/users/${payload}`,
          method: "GET",
        }).then((response) => {
          if (response.data.status == "success") {
            commit("setBookingOperator", response.data.data.user);
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    fetchUpcomingBookings({ commit }, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/vehicles/${payload}/bookings/?perPage=100&sort=asc`,
          method: "GET",
        }).then((response) => {
          if (response.data.status == "success") {
            commit("setBookingsByVehicle", response.data.data.bookings);
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    startBooking(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/bookings/${payload}/release`,
          method: "PUT",
        }).then((response) => {
          if (response.data.status == "success") {
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    stopBooking(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/bookings/${payload}/return`,
          method: "PUT",
        }).then((response) => {
          if (response.data.status == "success") {
            return resolve({
              status: "success",
            });
          }
        });
      });
    },


  },

  mutations: {
    setBookingData(state, payload) {
      state.bookingData = payload;
    },
    setBookingPaymentDetails(state, payload) {
      state.bookingPaymentDetails = payload;
    },
    setBookingOperator(state, payload) {
      state.bookingOperator = payload;
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
    setBookingsByUser(state, payload) {
      state.bookingsByUser = payload;
    },
    setBookingsByOperator(state, payload) {
      state.bookingsByOperator = payload;
    },
    setBookingsByVehicle(state, payload) {
      state.bookingsByVehicle = payload;
    },
    setBookingVehicle(state, payload) {
      state.bookingData["vehicle"] = payload;
    },
    logout(state) {
      state.bookingData = null;
      state.bookingsByUser = null;
      state.bookingsByVehicle = null;
    },
  },
  namespaced: true,
};

export default booking;
