<template>
    <div class="container profileDashboard">
        <ProfileSidebar />
        <section>
            <q-item>
                <q-item-section thumbnail>
                    <div class="q-py-xs">
                        <img style="height: auto" :src="vehicle ? vehicle.images[0] : require(`@/assets/images/${vehicle.type}.webp`)"/>
                    </div>
                </q-item-section>
                <q-item-section>
                    <div class="car-title ellipsis q-py-xs">
                        <h5 class="text-bold text-accent">{{ vehicle.vehicle_name }}</h5>
                    </div>
                    <div class="text-caption q-py-xs">
                        <div class="flex justify-left">
                            <div class="q-mr-md">
                            <q-icon name="fa-solid fa-car q-pr-sm" color="dark" />
                            {{ vehicle.type }}
                            </div>
                            <div>
                            <q-icon name="fa-solid fa-list q-pr-sm" color="dark" />
                            {{ vehicle.upcoming_bookings }} Upcoming Bookings
                            </div>
                        </div>
                    </div>
                    <div class="text-caption q-py-xs">
                        <q-icon
                            name="fa-solid fa-circle"
                            :color="
                            vehicle.status == 'Banned'
                                ? 'red'
                                : vehicle.status == 'In-Use'
                                ? 'warning'
                                : vehicle.status == 'Available'
                                ? 'positive'
                                : vehicle.status == 'Unavailable'
                                ? 'gray' 
                                : vehicle.status == 'Reserved'
                                ? 'yellow'
                                : ''
                            "
                        />
                        {{ vehicle.status }}
                    </div>
                    <div class="text-caption q-py-xs">
                        <q-badge color="blue" v-if="vehicle.is_carbnb_managed == 1">
                            Parked on Yard
                        </q-badge>
                        <q-badge v-else color="purple">
                            Outside Operator
                        </q-badge>
                    </div>
                </q-item-section>
            </q-item>
            <ControlPanel/>
     </section>
    </div>
</template>

<script setup>
import ProfileSidebar from "../../components/Profile/ProfileSidebar.vue";
import ControlPanel from "@/components/Operator/ControlPanel.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useQuasar } from 'quasar'

const store = useStore();
const route = useRoute();
const $q = useQuasar();
$q.loading.show();

/**
 * Loads the vehicle from the server.
 */
function loadVehicle() {
  store.dispatch("operator/getVehicle", route.params.id).finally(() => {
    $q.loading.hide();
  });
}

loadVehicle();
const vehicle = computed(() => store.state.operator.vehicle);
</script>