<template>
  <div class="container ip-cont">
    <h2>Privacy Policy</h2>

Carbnb Car Rental Services is dedicated to protecting users' privacy and addressing any privacy issues that may arise. The following information has been given to assist you, the client, in understanding what private information we gather and how we utilize it.
Types of private information collected
To best serve your needs, we collect the following types of information when you visit www.carbnbrentals.com or use our app.
<br><br>
<h3>Personal Information</h3>
We collect the following personal information from our users:<br>
• Name<br>
• Billing address<br>
• Phone number<br>
• Email address<br>
• Birthdate<br>
• Payment/Billing Information<br>
• Driver's License details and pictures<br>
• Booking details (Booking dates, booking destination, and desired vehicle)
<br><br>
You may always provide less information if you prefer to. However, omitting any of this personal information may limit your ability to fully utilize our website and services. We will not be capable of carrying out transactions, for example, without payment information and a driver's license.
<br><br>
<h3>Automatically collected information</h3>
We automatically collect data on how our users visit www.carbnbrentals.com, such as your IP address, location, browser, browser language, operating system, device identifiers, and cookies.
<br><br>
Our website currently uses cookies to enhance its functionality. You may disable cookies in your web browser, but this may limit your ability to access www.carbnbrentals.com.
<br><br>
<h3>Social Media Information</h3>
If you've linked your Carbnb Car Rental Services account with a social media account, we also collect information social media networks give us. This might contain your username, name, email address, and other information, depending on the social media network. Furthermore, when users engage with Carbnb Car Rental Services social media profiles, we collect demographic information.
<br><br>
<h3>Policy on collecting sensitive personal information</h3>
Carbnb Car Rental Services does not collect any sensitive personal information such as your race, ethnicity, religion, health metrics, political affiliations, or data about your bad credit history if you have one.

<br><br>
<h3>How the information collected is used</h3>
We only use your personal information to help you utilize everything Carbnb Car Rental Services has to offer. This includes:
• Creating and maintaining an account with us
• Completing your transactions
• Booking details
• Fulfilling customer service requests
• Communicating our sales and promotions
• Contacting you regarding updates to our company policies
• Making all of Carbnb Car Rental Services's services available to you in a convenient manner
• Providing advertisements that are relevant to you as you visit www.carbnbrentals.com
• Evaluating analytics to see how our customers engage with www.carbnbrentals.com
<br><br>
<h3>Length of time personal information is stored</h3>
We may keep your personal information for a significant period of time depending on how we need to utilize it. For example, as long as you have an account with us, we will retain your email address and name on file. Furthermore, in order to keep our internal transaction records, we may need to retain your billing information on file for an extended length of time.
<br><br>
<h3>Who the information is shared with</h3>
We only share your information with Carbnb Associates who have been fully vetted and advised by Carbnb Car Rental Services to handle bookings on our website.
The only reason we will ever share your information with our in-house team is for the following purposes:
• Processing transactions
• Fulfilling booking reservations
• Measuring marketing and advertising performance
• Providing excellent customer service
• Issuing email and text message communications
• Gathering booking information
<br><br>
<h3>Information Security</h3>
We do our best to keep your private information secure and safe. Most of your personal information is encrypted and is therefore protected from being accessed by outside users. However, data leaks and malicious attacks do happen. Please understand that while we do our best to protect your data, providing your personal information is at your own risk. But rest assured, that Carbnb Car Rental Services adhere to the Data Privacy Act of 2012 Republic of the Philippines.
<br><br>
<h3>Ability to decline to provide personal information</h3>
You may, of course, decline to provide us with your personal information. But, refusal to submit us with relevant information may limit your ability to fully benefit from Carbnb Car Rental Services and all of its offers. Remember, we only ask for personal information that will allow us to offer our clients the best possible self-drive car rental service.
Additional questions
If you have questions regarding this privacy policy, feel free to contact us at support@carbnbrentals.com. A Carbnb Car Rental Services representative will respond to your privacy policy questions as soon as possible.
<br><br>
<h3>Policy update</h3>
This privacy policy was last updated on March 01, 2023. If we adjust the privacy policy, we will email all account holders of this change and post the most recent update date here. Once posted, the new privacy policy shall be effective immediately.
Carbnb Car Rental Services

  </div>
</template>
<script setup>

</script>
<style scoped>
h2{
  font-size: 23px;
  text-align: center;
}
h3 {
  font-size: 18px;
}
a{
  font-size: 14px;
  color: var(--color-primary);
}
a:hover{
  text-decoration: underline;
}
  
</style>