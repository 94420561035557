import axios from "axios";
import fixtures from "../assets/json/vehicle.json";
import { filterDate } from "@/utils/filterData";
const vehicle = {
  state: () => ({
    vehicleResults: fixtures.vehicle_results,
    vehicleData: null,
    vehicleOwned: null,
    vehicleModels: null,
    vehicleBlockingDates:null,
    yardList: [],
    vehicleSearchQuery: {
      location: null,
      yard: null,
      yard_id: null,
      pickupdate: null,
      pickupTime: null,
      returndate: null,
      returnTime: null,
      filters: {
        brand: null,
        transmission: null,
        type: [],
        others: null,
        seat: null,
        with_driver: null,
        fuel_type: null,
        model: null,
      },
      total_price: null,
      duration: null,
      vehicle_id: null,
    },
  }),

  getters: {
    getvehicleResults: (state) => state.vehicleResults,
    getVehicleData: (state) => state.vehicleData,
    vehicleModels: (state) => state.vehicleData,
    isSearchQueryEmpty: (state) => {
      if (
        state.vehicleSearchQuery.pickupdate === null ||
        state.vehicleSearchQuery.pickupdate === undefined ||
        state.vehicleSearchQuery.pickupTime === null ||
        state.vehicleSearchQuery.pickupTime === undefined ||
        state.vehicleSearchQuery.returndate === null ||
        state.vehicleSearchQuery.returndate === undefined ||
        state.vehicleSearchQuery.returnTime === null ||
        state.vehicleSearchQuery.returnTime === undefined ||
        state.vehicleSearchQuery.duration === null ||
        state.vehicleSearchQuery.duration === undefined
      ) {
        return true;
      }

      return false;
    }
  },

  actions: {
    searchVehicles({ commit }, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/vehicles/available?page=${payload.page}`,
          method: "POST",
          data: payload,
        })
          .then((response) => {
            if (response.data.status == "success") {
              const vehicles = response.data.data.vehicles.result;
              
              commit("setVehicleResults", vehicles);
              return resolve({
                status: "success",
              });
            }
          })
          .catch((err) => {
            console.log("Search result: 0")
              commit("setVehicleResults", null);
              return resolve({
                status: err,
              });
          });
      });
    },
    searchMoreVehicles({ commit,state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/available?page=${payload.page}`,
          method: "POST",
          data: payload,
        })
          .then((response) => {
            if (response.data.status == "success") {
              const vehicles = response.data.data.vehicles.result;
              if(state.vehicleResults.length != response.data.data.vehicles.pagination.overallCount ){
                commit("loadMoreVehicles", vehicles);
                return resolve({
                  status: "success",
                });
              } else{
                return resolve({
                  status: "empty",
                });
              }
            
            }
          })
          .catch((err) => {
              return reject({
                status: err,
              });
          });
      });
    },
    createVehicle(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/api/v1/vehicles/add",
          method: "POST",
          data: payload,
        })
          .then((response) => {
            if (response.data.status == "application_submitted") {
              return resolve({
                status: "success",
                id: response.data.data.id
              });
            }
          })
          .catch((err) => {
            return reject(err.response);
          });
      });
    },
    applyAsOperator(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/api/v1/vehicles/application",
          method: "POST",
          data: payload,
        })
          .then((response) => {
            if (response.data.status) {
              return resolve({
                status: response.data.status,
                id: response.data.data.vehicle_application_id
              });
            }
          })
          .catch((err) => {
            return reject(err.response);
          });
      });
    },
    uploadVehicleGallery(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/${payload.id}/images`,
          method: "POST",
          data: payload.data,
        })
          .then((response) => {
            if (response.data.status == "success") {
              return resolve({
                status: "success",
                id: response.data.data.vehicle_id
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    updateVehicle(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/${payload.id}`,
          method: "POST",
          data: payload.data,
        })
          .then((response) => {
            if (response.data.status == "success") {
              return resolve({
                status: "success",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    deleteVehicle(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/${payload}`,
          method: "DELETE",
        })
          .then((response) => {
            if (response.data.status == "success") {
              return resolve({
                status: "success",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchSingleVehicle({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/${payload}`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "vehicle_retrieved") {
              const vehicle = response.data.data;

              commit("setVehicleData", vehicle);
              return resolve({
                status: "success",
                data: vehicle
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchYards({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/yards?page=1&perPage=100&sort=asc`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "success") {
              let yards = response.data.data.yard;
              yards.push({
                id: 0,
                name: 'Other Locations..',
                address: 'Metro Manila',
                value: 0
              });
              commit("setYardList", yards);
              return resolve({
                status: "success",
                yards: yards
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    checkAvailability({ state }, vehicle_id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/is_available`,
          method: "POST",
          data: {
            vehicle_id: vehicle_id,
            pickup_date: filterDate(
              state.vehicleSearchQuery.pickupdate + " " + state.vehicleSearchQuery.pickupTime,
              "YYYY/MM/DD HH:mm"
            ),
            estimated_return_date: filterDate(
              state.vehicleSearchQuery.returndate + " " + state.vehicleSearchQuery.returnTime,
              "YYYY/MM/DD HH:mm"
            )
          }
        })
          .then((response) => {
            return resolve({
              status: response.data.status,
            });
          }).catch((err) => {
            return reject(err);
          });
      });
    },
    addtoFavorites() {},
    fetchVehicleReview({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles${payload}`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "success") {
              const vehicle = response.data.data.vehicle;
              commit("setVehicleData", vehicle);
              return resolve({
                status: "success",
                
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchVehicleModels({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/list/vehicle/models`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "success") {
              commit("setVehicleModels", response.data.data);
              return resolve({
                status: "success",
                models: response.data.data
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchVehicleBlockingDates({ commit },payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/${payload.id}/blockings`,
          method: "GET",
        })
          .then((response) => {
            if (response?.data?.status == "blockings_retrieved") {
              commit("setVehicleBlockingDates", response.data.data);
              return resolve({status: "success"});
            } else {
              return resolve({
                status: "failed",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    createBlockingDate(_,payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/blockings`,
          method: "POST",
          data: payload
        })
          .then((response) => {
            if (response?.data?.status == "success") {
              return resolve({
                status: "success",
              });
            } else {
              return resolve({
                status: "failed",
              });
            }
          })
          .catch((err) => {
            return reject({
              status: err.response.status,
              message: err.response.data.data.message
            });
          });
      });
    },
    deleteBlockingDate(_,payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/blockings/${payload}`,
          method: "DELETE",
        })
          .then((response) => {
            if (response?.data?.status == "success") {
              return resolve({
                status: "success",
              });
            } else {
              return resolve({
                status: "failed",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    withdrawApplication(_, vehicleApplicationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/application/cancel/${vehicleApplicationId}`,
          method: "DELETE",
        })
          .then((response) => {
            if (response?.data?.status == "success") {
              return resolve({
                status: "success",
              });
            } else {
              return resolve({
                status: "failed",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    }
  },

  mutations: {
    setDuration(state, payload) {
      state.vehicleSearchQuery.duration = payload;
    },
    setVehicleResults(state, payload) {
      state.vehicleResults = payload;
    },
    setVehicleModels(state, payload) {
      state.vehicleModels = payload;
    },
    setVehicleBlockingDates(state, payload) {
      state.vehicleBlockingDates = payload;
    },
    setYardList(state, payload) {
      state.yardList = payload;
    },
    setVehicleOwned(state, payload) {
      state.vehicleOwned = payload;
    },
    setVehicleData(state, payload) {
      state.vehicleData = payload;
    },
    setSearchLocation(state, payload) {
      state.vehicleSearchQuery.location =  payload
    },
    setSearchYard(state, payload) {
      state.vehicleSearchQuery.yard =  payload
    },
    setSearchPickupDate(state, payload) {
      state.vehicleSearchQuery.pickupdate = payload;
    },
    setSearchPickupTime(state, payload) {
      state.vehicleSearchQuery.pickupTime = payload;
    },
    setSearchReturnTime(state, payload) {
      state.vehicleSearchQuery.returnTime = payload;
    },
    setSearchReturnDate(state, payload) {
      state.vehicleSearchQuery.returndate = payload;
    },
    setSearchYardData(state, payload) {
      state.vehicleSearchQuery.yard = payload.name;
      state.vehicleSearchQuery.yard_id = payload.id;
    },
    setSearchQuery(state, payload) {
      state.vehicleSearchQuery = payload;
    },
    setSearchQueryFilters(state, payload) {
      state.vehicleSearchQuery.filters = payload;
    },
    setSearchQueryFiltersType(state, payload) {
      state.vehicleSearchQuery.filters.type = payload;
    },
    logout(state) {
      state.vehicleResults = null;
      state.vehicleData = null;
      state.vehicleOwned = null;
    },
    loadMoreVehicles(state, payload) {
      payload.map((item) => {
        state.vehicleResults.push(item)
      })
    },
  },
  namespaced: true,
};

export default vehicle;
