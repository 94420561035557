<template>
  <div class="container profileDashboard">
    <ProfileSidebar />
    <section>
      <h5>Manage Booking</h5>

      <q-separator class="q-my-md" />

      <div class="q-mb-md"></div>

      <q-dialog v-model="displayNotice">
      <q-card class="q-pa-sm">
        <q-card-section>
          <div class="text-h6">Note:</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          You are only allowed to change your rental dates of the selected vehicle. If you wish to update other than this, you may contact our Admin Support Team to assist you.
        </q-card-section>

        <q-card-actions align="right">
          <q-btn  label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    </section>
  </div>
</template>
<script setup>
import ProfileSidebar from "../components/Profile/ProfileSidebar.vue";
import { useRoute } from "vue-router";
import { ref } from "vue";

const route = useRoute();
const displayNotice = ref(true);
const id = route.params.id
console.log(id)
</script>
<style scoped></style>
