<template>
  <div style="height: 100vh">
    <div v-if="isLoading" class="text-center">
      <q-spinner
        color="primary"
        size="3em"
        class="full-width q-mt-xl"
      />
      <p class="q-mt-lg">Loading your schedules..</p>
    </div>
    <div v-else>
      <div class="row justify-between">
        <div>
          <h6>Your Blocked Schedules</h6>
          <p>This page enables you to set blocked dates for your vehicle. When you block a date, your vehicle will not be available for booking on that span of dates.</p>
        </div>
        <div>
          <q-btn
            label="Add Blocked Date"
            color="positive"
            text-color="white"
            class="q-px-md"
            icon="add"
            @click="showAddBlockDateForm = !showAddBlockDateForm"
          />
        </div>
      </div>
      <div class="text-center q-mt-xl" v-if="!isLoading && blocked_schedules == null">
        <q-icon name="drive_eta" size="3em"/>
        <h6 class="text-accent">No Blocked Dates</h6>
        <p>This vehicle does not have any blocked date. <br>Press the <q-icon name="sync_alt"/> Sync button below to update again.</p>
        <br><br>
        <q-btn
          color="primary"
          class="q-ml-md"
          icon="sync_alt"
          label="Sync"
          @click="fetchDates"
        />
      </div>
      <div v-else>
        <q-table :rows="rows" :columns="columns" row-key="name" flat hide-bottom>
          <template v-slot:body-cell-actions="props">
              <q-td key="actions" :props="props" class="">
                <q-btn
                  label="Delete"
                  dense
                  class="q-px-sm q-mx-xs"
                  color="primary"
                  text-color="white"
                  @click="deleteDate(props.row.actions)"
                />
              </q-td>
          </template>
        </q-table>
      </div>
    </div>
    <q-dialog
      v-model="showAddBlockDateForm"
      persistent
    >
      <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section>
          <div class="text-h6">Add Blocked Date</div>
          <p>Add a new blocked date to this vehicle.</p>
        </q-card-section>
        <q-card-section>
          <div class="row">
            <div class="col-12 q-py-sm">
              <q-input
                v-model="reason"
                outlined
                class="cb-input"
                placeholder="Reason"
                dense
                autogrow
                hint="Enter the reason for this blocking (i.e Personal use, maintenance etc.)"
                :rules="[(val) => (val && val.length > 0) || 'This field is required.']"
              ></q-input>
            </div>
            <div class="col-12 col-md-6 q-pr-sm q-py-sm">
              <q-input
                outlined
                dense
                placeholder="Start Date"
                v-model="startdate"
                mask="date"
                :rules="['date']"
                hint="Enter the start date."
                @click="$refs.qDateProxys.show()"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      cover
                      transition-show="scale"
                      transition-hide="scale"
                      ref="qDateProxys"
                    >
                      <q-date
                        v-model="startdate"
                        years-in-month-view
                        :options="optionDates"
                        @input="$refs.qDateProxys.hide()"
                        :default-year-month="moment(new Date()).format('YYYY/MM')"
                        :navigation-min-year-month="moment(new Date()).format('YYYY/MM')"
                      >
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div class="col-12 col-md-6 q-py-sm">
              <q-input
                outlined
                dense
                v-model="starttime"
                placeholder="Start Time"
                mask="time" 
                :rules="['time']"
                @click="$refs.qTimeProxy.show()"
              >
              <template v-slot:append>
                <q-icon name="access_time" class="cursor-pointer">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                    ref="qTimeProxy"
                  >
                    <q-time
                      v-model="starttime"
                      @input="$refs.qTimeProxy.hide()"
                      mask="hh:mm a"
                      format12h
                    >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-time>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            </div>
            <div class="col-12 col-md-6 q-pr-sm q-py-sm">
              <q-input
                outlined
                dense
                placeholder="End Date"
                v-model="enddate"
                mask="date"
                :rules="['date']"
                hint="Enter the end date."
                @click="$refs.qDateProxys.show()"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      cover
                      transition-show="scale"
                      transition-hide="scale"
                      ref="qDateProxys"
                    >
                      <q-date
                        v-model="enddate"
                        years-in-month-view
                        :options="optionDates"
                        @input="$refs.qDateProxys.hide()"
                        :default-year-month="moment(new Date()).format('YYYY/MM')"
                        :navigation-min-year-month="moment(new Date()).format('YYYY/MM')"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div class="col-12 col-md-6 q-py-sm">
              <q-input
                outlined
                dense
                v-model="endtime"
                placeholder="End Time"
                mask="time" 
                :rules="['time']"
                @click="$refs.qTimeProxy.show()"
              >
              <template v-slot:append>
                <q-icon name="access_time" class="cursor-pointer">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                    ref="qTimeProxy"
                  >
                    <q-time
                      v-model="endtime"
                      mask="hh:mm a"
                      format12h
                      @input="$refs.qTimeProxy.hide()"
                    >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-time>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn icon="arrow_left" :disabled="isSubmitting" label="Cancel" color="primary" v-close-popup />
          <q-btn icon="add" label="Add" @click="addBlockDate" :loading="isSubmitting" color="positive" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script setup>
  import { ref, computed } from "vue";
  import { defineProps } from "vue";
  import { useStore } from "vuex";
  import moment from "moment";
  import { notify } from "../../../utils/notification";
  const store = useStore();
  const isLoading = ref(false);
  const isSubmitting = ref(false);
  const rows = ref([]);
  const showAddBlockDateForm = ref(false);
  const props = defineProps({
    id: String,
  });
  const startdate = ref();
  const starttime = ref();
  const endtime = ref();
  const enddate = ref();
  const reason = ref();
  const columns = [
    {
      name: "reason",
      label: "Reason",
      field: (row) => row.reason,
      format: (val) => `${val}`,
      sortable: true,
      align: "center",
    },
    {
      name: "startdate",
      label: "Start Date",
      field: (row) => row.startdate,
      format: (val) => `${val}`,
      sortable: true,
      align: "center",
    },
    {
      name: "enddate",
      label: "End Date",
      field: (row) => row.enddate,
      format: (val) => `${val}`,
      sortable: true,
      align: "center",
    },
    {
      name: "actions",
      label: "Actions",
      align: "center"
    },
  ];
  const blocked_schedules = computed(
    () => store.state.vehicle.vehicleBlockingDates
  );
  function optionDates(date) {
    return (
      date >= moment(new Date()).subtract(1, "hour").format("YYYY/MM/DD") &&
      date <= moment(new Date()).add(1, "years").format("YYYY/MM/DD")
    );
  }
  function fetchDates(){
    if (props.id) {
      isLoading.value = true;
      const payload = {
        id: props.id
      };
      store.dispatch("vehicle/fetchVehicleBlockingDates", payload).then((r) => {
        if (r.status == "success") {
          rows.value = [];
          if (blocked_schedules.value != undefined && blocked_schedules.value != null) {
            blocked_schedules.value.map((booking) => {
              rows.value.push({
                reason: booking.remarks,
                startdate: filterDate(booking.pickup_date),
                enddate: filterDate(booking.estimated_return_date),
                actions: booking.id,
              });
            });
          }
          isLoading.value = false;
        } else {
          isLoading.value = false;
        }
      });
    }
  }
  function addBlockDate() {
    if (reason.value == '' || reason.value == null) {
      notify("negative", "Please add a reason for this blocking.");
      return;
    }
    isSubmitting.value = true;
    const payload = {
      vehicle_id: props.id,
      startDate: startdate.value + ' ' + starttime.value,
      endDate: enddate.value + ' ' + endtime.value,
      reason: reason.value,
    };
    store.dispatch("vehicle/createBlockingDate", payload).then((r) => {
      if (r.status == "success") {
        notify("positive", "You have successfully added a blocked date for this vehicle.");
        fetchDates();
      }
    }).catch((err) => {
        notify("warning", err.message);
    }).finally(() => {
      isSubmitting.value = false;
      showAddBlockDateForm.value = false;
    })
  }
  function filterDate(date) {
    return moment(new Date(date)).format("MMM DD YYYY hh:mm a");
  }
  function deleteDate(id) {
    if (id) {
      store.dispatch("vehicle/deleteBlockingDate", id).then((r) => {
        if (r.status == "success") {
          notify("positive", "The blocked date was successfully removed from this vehicle.");
          fetchDates();
        }
      });
    }
}
fetchDates();
</script>

<style>

</style>