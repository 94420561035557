import axios from "axios";

const qr = {
    actions: {
        transmit(_, qid) {
            return new Promise(() => {
              axios({
                url: `/api/v1/qr-code-receive`,
                method: "POST",
                data: {
                    qid: qid
                }
              })
            });
        },
    },
    namespaced: true,
};
  
export default qr;