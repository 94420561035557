<template>
  <div class="container profileDashboard">
    <ProfileSidebar />
    <section class="">
      <q-banner class="bg-orange-3" v-if="user && user.has_personalized_password == '0'">
      Your account is still using a temporary password. Please go to Settings and Change Password.
    </q-banner>
      <q-form>
        <div class="input-field">
          <div class="flex flexPersonalInfo">
              <div class="profilePictureSection q-px-lg">
              <div
                class="q-pt-md q-pb-xs text-h6 text-black text-weight-medium text-center"
              >
                <h5>{{firstname}} {{lastname}}</h5>
              </div>

              <div class="q-pa-lg text-center">
                <q-avatar style="width: 100px; height: 100px">
                  <q-img :src="profilePhotoImg" />
                </q-avatar>
              </div>
              <!-- <q-btn class="q-ml-md q-mb-md btn-upload">Upload Picture</q-btn> -->
              <q-file
                color="secondary"
                bg-color="white"
                borderless
                v-model="profilePhoto"
                label="Click to change photo"
                dense
                class="q-px-md"
                @update:model-value="updatePhoto()"
              >
                <template v-slot:prepend>
                  <q-icon name="image" />
                </template>
              </q-file>
            </div>
            <!-- <ProfileNotice
              v-if="!user.email_verified_at"
            /> -->
            <!-- <div class="full-width">
              <div
                class="q-pt-md q-pb-xs text-h6 text-black text-weight-medium text-left"
              >
                Cover Photo
              </div>
              <div class="full-width">
                <img :src="coverPhotoImg" class="cover-photo" />
              </div>
              <q-file
                color="secondary"
                bg-color="white"
                borderless
                v-model="coverPhoto"
                label="Click to upload Photo"
                dense
                @update:model-value="updateCoverPhoto()"
              >
                <template v-slot:prepend>
                  <q-icon name="image" />
                </template>
              </q-file>
            </div> -->

            <div class="personalInfoSection">
              <div
                class="q-pt-md q-pb-xs text-h6 text-black text-weight-medium"
              >
                Personal Information
              </div>

              <div class="subtitle1 q-pt-xs q-pb-none">First Name</div>
              <q-input
                v-model="firstname"
                outlined
                class="cb-input"
                placeholder="First Name"
              />

              <div class="subtitle1 q-pt-sm q-pb-none">Last Name</div>
              <q-input
                v-model="lastname"
                outlined
                class="cb-input"
                placeholder="Last Name"
              />

              <div class="subtitle1 q-pt-sm q-pb-none">Address</div>
              <q-input
                v-model="address"
                outlined
                class="cb-input"
                placeholder="Address"
              />

              <div class="subtitle1 q-pt-sm q-pb-none">Date of Birth</div>

              <div class="">
                <q-input
                  outlined
                  v-model="birthDate"
                  class="cb-input"
                  mask="date"
                  placeholder="YYYY/MM/DD"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        cover
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date v-model="birthDate">
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </div>
          </div>
        </div>

        <div class="divider"></div>

        <div class="q-pl-sm">
          <div
            class="q-pt-md q-pb-xs q-mb-sm q-mt-md text-h6 text-black"
          >
            Account Verification
          </div>

          <div class="full-width input-field">
            <div class="row">
              <div class="col col-md-6 col-sm-12 col-xs-12 q-pr-sm q-pb-md">
                <div>
                  <q-label>
                    <span>{{ user.email }} </span>
                    <q-icon
                      name="warning"
                      color="warning"
                      size="20px"
                      v-if="!user.email_verified_at"
                    />
                    <q-icon
                      name="check_circle"
                      color="positive"
                      size="20px"
                      v-else
                    />
                  </q-label>
                </div>
                <!-- <q-btn class="btn-verify" @click="emailVerifyPopup = true"
                  >Verify or change email</q-btn
                > -->
                <!-- <q-btn class="btn-verify" @click="emailVerifyPopup = true;verifyExisting = false"
                  >Update Email</q-btn
                > -->
              </div>
              <div class="col col-md-6 col-sm-12 col-xs-12 q-pb-md">
                <div class="text-subtitle2">
                  <span class="q-mr-sm"> Mobile:</span>

                  <q-label>
                    <span v-if="user.mobile_number != '1' && user.mobile_number">{{
                      user.mobile_number
                    }}</span>
                    <span v-else>N/A</span>
                    <!-- WE WILL JUST COMMENT THIS OUT UNTIL THE IMPLEMENTATION ON SMS IS ALL GOOD -->
                    <!-- <q-icon
                      name="warning"
                      color="warning"
                      size="20px"
                      v-if="!user.mobile_number_verified_at"
                    /> -->
                    <!-- <q-icon
                      name="check_circle"
                      color="positive"
                      size="20px"
                      v-else
                    /> -->
                  </q-label>
                </div>
                <q-btn class="btn-verify" @click="phoneVerifyPopup = true"
                  >Update Mobile Number</q-btn
                >
              </div>
            </div>
          </div>
          <!-- Email Popup -->
          <q-dialog v-model="emailVerifyPopup">
            <q-card>
              <q-card-section class="row items-center q-pb-none">
                <div class="text-h6">Verify Email Address</div>
                <q-space />
                <q-btn icon="close" flat round dense v-close-popup />
              </q-card-section>

              <q-card-section class="q-pt-sm" style="min-width: 300px">
                <p class="text-caption q-mb-md" v-if="!user.email_verified_at">
                  We will send a verification code on this email address:
                </p>
                <p v-else>
                  Your email is already verified.
                  <q-icon name="check_circle" color="positive" size="20px" />
                </p>
                <!-- Verify existing -->
                <div v-if="verifyExisting">
                  <span class="block q-mb-sm"
                    ><q-icon name="email" /> {{ email }}</span
                  >
                  <q-input
                    outlined
                    v-model="emailVerifyCode"
                    placeholder="6 Digit Code"
                    dense
                    v-if="emailVerifying"
                  />
                  <p class="text-caption" v-if="emailVerifying">
                    Click here to <span class="resend-btn">Resend</span> code
                  </p>
                </div>

                <div v-else>
                  <span>Enter your new email address.</span>
                  <q-input
                    outlined
                    v-model="newEmailAddress"
                    placeholder=""
                    dense
                  />
                </div>
              </q-card-section>

              <q-card-actions align="right">
                <div v-if="!emailVerifying">
                  <q-btn
                    flat
                    class="q-pa-md text-capitalize q-mx-sm"
                    label="update email address"
                    v-if="verifyExisting == true"
                    @click="verifyExisting = false"
                  />
                  <q-btn
                    flat
                    class="q-pa-md q-mx-sm text-capitalize"
                    label="Cancel"
                    v-else
                    @click="verifyExisting = true"
                  />
                  <!-- <q-btn
                    label="Send"
                    color="primary"
                    v-if="!user.mobile_number_verified_at"
                    class="text-capitalize"
                    @click="emailVerifying = true"
                  /> -->
                  <q-btn
                    label="Update"
                    color="primary"
                    class="text-capitalize"
                    @click="onSubmit()"
                    v-if="emailVerifying == false && verifyExisting == false"
                  />
                </div>
                <div v-else>
                  <q-btn
                    label="Verify"
                    color="primary"
                    class="text-capitalize"
                    v-close-popup
                  />
                </div>
              </q-card-actions>
            </q-card>
          </q-dialog>

          <!-- Phone Popup -->
          <q-dialog v-model="phoneVerifyPopup">
            <q-card>
              <q-card-section
                class="row items-center q-pb-none"
                style="min-width: 20vw"
              >
                <div class="text-h6">Mobile Number</div>
                <q-space />
                <q-btn icon="close" flat round dense v-close-popup />
              </q-card-section>

              <q-card-section class="q-pt-sm">
                <p class="text-caption q-mb-sm">
                  <!-- We will send a verification code on this mobile number: -->
                </p>
                <!-- Verify existing -->
                <div v-if="verifyExistingPhone">
                  <span class="block q-mb-sm"
                    ><q-icon name="phone" /> {{ phone }}</span
                  >
                  <q-input
                    outlined
                    v-model="mobileVerifyCode"
                    placeholder="6 Digit Code"
                    dense
                  />
                  <p class="text-caption" v-if="phoneVerifying">
                    Click here to <span class="resend-btn">Resend</span> code
                  </p>
                </div>

                <div v-else>
                  <q-input
                    flat
                    v-model="newMobileNum"
                    placeholder=""
                    dense
                    class="q-py-md"
                  />
                </div>
              </q-card-section>

              <q-card-actions align="right">
                <div>
                  <q-btn
                    flat
                    class="q-pa-md text-capitalize q-mx-sm"
                    label="Cancel"
                    v-close-popup
                  />
                  <q-btn
                    label="Update"
                    color="primary"
                    class="text-capitalize"
                    @click="onSubmit()"
                    v-close-popup
                  />
                </div>
              </q-card-actions>
            </q-card>
          </q-dialog>
        </div>

        <div class="q-pl-sm">
          <div
            class="q-pt-md q-pb-xs q-mb-sm q-mt-md text-h6 text-black text-weight-medium"
          >
            Drivers License
          </div>

          <div class="full-width">
            <div class="row justify-between q-mb-md">
              <div class="col col-md-4 col-sm-4 col-xs-12 text-subtitle2">
                License Photo ( front) :
                <q-icon
                  name="check_circle"
                  v-if="user.license_front_image_id"
                  color="positive"
                  size="20px"
                />
                <span v-else>N/A</span>
              </div>

              <div class="col col-md-4 col-sm-4 col-xs-12 text-subtitle2">
                License Photo (Selfie) :
                <q-icon
                  name="check_circle"
                  v-if="user.license_selfie_image_id"
                  color="positive"
                  size="20px"
                />
                <span v-else>N/A</span>
              </div>

              <div class="col col-md-4 col-sm-4 col-xs-12 text-subtitle2">
                License Expiry :
                <span class="text-caption" v-if="user.license_expiration_date">
                  {{ filterDate(user.license_expiration_date) }}
                </span>
                <span class="text-caption" v-else> N/A </span>
              </div>
            </div>

            <div class="flex justify-between">
              <div class="col col-md-4 col-sm-4 col-xs-12 text-subtitle2">
                License Photo ( back ) :
                <q-icon
                  name="check_circle"
                  v-if="user.license_back_image_id"
                  color="positive"
                  size="20px"
                />
                <span v-else>N/A</span>
              </div>

              <div
                class="col col-md-4 col-sm-4 col-xs-12 text-subtitle2 q-pr-md"
              >
                Transmission Declaration:
                <span class="text-caption" v-if="user.allowed_transmission">
                  {{
                    vehicleJson.license_category[user.allowed_transmission]
                      .value
                  }}</span
                >
                <span class="text-caption" v-else> N/A</span>
              </div>

              <div class="col col-md-4 col-sm-4 col-xs-12 text-subtitle2">
                License Number :
                <span class="text-caption" v-if="user.license_number"
                  >{{ user.license_number }}
                </span>
                <span class="text-caption" v-else> N/A </span>
              </div>
            </div>
          </div>
        </div>

        <div class="q-pl-sm q-pt-lg">
          <!-- Drivers License Popup -->
          <q-btn class="btn-verify" @click="driverslicensePopup = true"
            >Update drivers license</q-btn
          >

          <q-dialog v-model="driverslicensePopup" :maximized="$q.screen.lt.md">
            <q-card>
              <q-card-section class="row items-center q-pb-none">
                <div class="text-h6">Drivers License</div>
                <q-space />
                <q-btn icon="close" flat round dense v-close-popup />
              </q-card-section>

              <q-card-section class="q-pt-none">
                <ProfileDrivLicenseDialog :user="user" />
              </q-card-section>
            </q-card>
          </q-dialog>
        </div>

        <div class="q-my-lg float-right">
          <AppBtn :text="'Save Changes'" icon="save" @click="onSubmit()" />
        </div>
      </q-form>
    </section>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import ProfileSidebar from "../components/Profile/ProfileSidebar.vue";
// import ProfileNotice from "../components/Profile/ProfileNotice";
import ProfileDrivLicenseDialog from "../components/Profile/ProfileDrivLicenseDialog.vue";
import { notify } from "../utils/notification";
import AppBtn from "../components/Globals/AppBtn.vue";
import { filterDate } from "../utils/filterData";
import vehicleJSON from "../assets/json/vehicle.json";
import { useQuasar } from "quasar";

export default {
  components: {
    ProfileSidebar,
    ProfileDrivLicenseDialog,
    AppBtn,
  },
  created() {
    this.loadData();
  },
  setup() {
    const $q = useQuasar();
    const store = useStore();
    const user = computed(() => store.state.user.userData);
    const vehicleJson = vehicleJSON;
    const email = ref(user.value.email);
    const phone = ref(user.value.mobile_number);
    const firstname = ref(user.value.first_name);
    const lastname = ref(user.value.last_name);
    const address = ref(user.value.address);
    const birthDate = ref(user.value.date_of_birth);
    const profilePhoto = ref();
    const coverPhoto = ref();
    const newMobileNum = ref();
    const newEmailAddress = ref();
    const profilePhotoImg = computed(() =>
      user.value.profile_image_id
        ? user.value.profile_image_id.file
        : require("@/assets/images/default_profile.jpg")
    );

    function verifyEmail() {
      const payload = {
        id: store.state.user.userData.id,
        data: {
          email: email.value,
        },
      };
      store.dispatch("user/updateUserData", payload);
    }

    function updatePhoto() {
      if (!profilePhoto.value) {
        return;
      }
      getBase64(profilePhoto.value)
        .then((data) => {
          const imgBase64 = data;

          const payload = {
            id: store.state.user.userData.id,
            data: {
              files: [{ file: imgBase64, title: "PROFILE_IMAGE" }],
            },
          };
          store.dispatch("user/updateUserData", payload).then((response) => {
            if (response.status == "success") {
              notify("positive", "Profile Photo successfully updated");
            }
          });
        })
        .catch((e) => {
          console.error(e.message);
        });
    }

    function updateCoverPhoto() {
      if (!coverPhoto.value) {
        return;
      }
      getBase64(coverPhoto.value).then((data) => {
        const imgBase64 = data;

        const payload = {
          id: store.state.user.userData.id,
          data: {
            files: [{ file: imgBase64, title: "PROFILE_COVER" }],
          },
        };
        store.dispatch("user/updateUserData", payload).then((response) => {
          if (response.status == "success") {
            notify("positive", "Cover Photo successfully updated");
          }
        });
      });
    }

    function getBase64(file) {
      if (!file) {
        return;
      }
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    function updatePersonalInfo() {
      const payload = {
        id: store.state.user.userData.id,
        data: {
          first_name: firstname.value,
          last_name: lastname.value,
          address: address.value,
          date_of_birth: birthDate.value,
          email: newEmailAddress.value ?? user.value.email,
          mobile_number: newMobileNum.value ?? user.value.mobile_number,
        },
      };
      store.dispatch("user/updateUserData", payload).then((response) => {
        if (response.status == "success") {
          notify("positive", "Your profile has been successfully updated.");
        }
      });
    }

    function onSubmit() {
      updatePersonalInfo();
    }
    function loadData() {
      store.dispatch("user/fetchUserData", store.state.user.userData.id);
    }
    return {
      user,
      isEmailVerified: user.value.email_verified_at,
      isPhoneVerified: user.value.mobile_number_verified_at,
      isDLVerified: user.value.license_verification_date,
      firstname,
      lastname,
      address,
      profilePhoto,
      coverPhoto,
      email,
      phone,
      birthDate,
      newEmailAddress,
      emailVerifyPopup: ref(false),
      phoneVerifyPopup: ref(false),
      verifyExisting: ref(true),
      emailVerifyCode: ref(),
      emailVerifying: ref(false),
      verifyExistingPhone: ref(false),
      mobileVerifyCode: ref(),
      newMobileNum,
      phoneVerifying: ref(false),
      driverslicensePopup: ref(false),
      profilePhotoImg,
      vehicleJson,
      onSubmit,
      updatePhoto,
      updateCoverPhoto,
      verifyEmail,
      filterDate,
      loadData,
      $q
    };
  },
};
</script>
<style scoped>
.mark-verified {
  color: var(--color-white);
  border-radius: 20px;
  background: #289f28;
}
.btn-upload,
.btn-verify {
  background-color: var(--color-white);
  color: var(--color-neutral);
  font-weight: 600;
  padding: 9px 12px;
  border: 1px solid var(--color-neutral);
  padding-bottom: 10px;
  cursor: pointer;
  text-transform: initial;
}
.btn-verify {
  padding: 0 45px;
  margin-top: 10px;
}
.dateBirth {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dateBirth label {
  width: 32%;
}
.cover-photo {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.personalInfoSection {
  width: 60%;
}
.profilePictureSection {
  width: 40%;
}
</style>
