import axios from "axios";

const operator = {
  state: () => ({
    vehicles: null,
    vehicle: null,
    upcomingBookings: null,
    closedBookings: null,
    booking: null,
    currentActiveBookingId: null
  }),

  getters: {
    //
  },

  actions: {
    getVehicles({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/operators/vehicles`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "vehicles_retrieved") {
              const vehicles = response.data.data;
              commit("setVehicles", vehicles);

              return resolve({status: "success"});
            }
          })
          .catch((err) => {
            commit("setVehicles", null)
            return reject(err);
          });
      });
    },
    getVehicle({ commit }, id) {
        return new Promise((resolve, reject) => {
          axios({
            url: `/api/v1/operators/vehicles/` + id,
            method: "GET",
          })
            .then((response) => {
              if (response.data.status == "vehicle_retrieved") {
                const vehicle = response.data.data;
                commit("setVehicle", vehicle);
  
                return resolve({status: "success"});
              }
            })
            .catch((err) => {
              commit("setVehicle", null)
              return reject(err);
            });
        });
    },
    getVehicleUpcomingBookings({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/operators/vehicles/` + id + `/upcoming-bookings`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "upcoming_bookings_retrieved") {
              const upcomingBookings = response.data.data;
              commit("setUpcomingBookings", upcomingBookings);

              return resolve({status: "success"});
            }
          })
          .catch((err) => {
            commit("setUpcomingBookings", null)

            return reject(err);
          });
      });
    },
    getVehicleClosedBookings({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/operators/vehicles/` + id + `/closed-bookings`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "closed_bookings_retrieved") {
              const upcomingBookings = response.data.data;
              commit("setClosedBookings", upcomingBookings);

              return resolve({status: "success"});
            }
          })
          .catch((err) => {
            commit("setClosedBookings", null)

            return reject(err);
          });
      });
    },
    getBooking({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/operators/bookings/` + id,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "booking_retrieved") {
              const booking = response.data.data;
              commit("setBooking", booking);

              return resolve({status: "success"});
            } else if (response.data.status == "vehicle_yard_managed") {
              return resolve({status: "vehicle_yard_managed"});
            }
          })
          .catch((err) => {
            commit("setBooking", null)
            return reject(err);
          });
      });      
    },
    startBooking({commit}, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/operators/bookings/` + id + `/release`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "booking_started") {
              commit("setBookingActiveId", response.data.data['booking_id']);

              return resolve({status: "success"});
            }
          })
          .catch((err) => {
            commit("setBookingActiveId", null);
  
            return reject(err);
          });
      });
    },
    stopBooking({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/operators/bookings/return`,
          method: "POST",
          data: payload
        })
          .then((response) => {
            if (response.data.status == "booking_stopped") {
              commit("setBookingActiveId", null);
              commit("setVehicleStatus", "Available");

              return resolve({status: "success"});
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    }
  },

  mutations: {
    setVehicles(state, payload) {
      state.vehicles = payload;
    },
    setVehicle(state, payload) {
      state.vehicle = payload;
    },
    setUpcomingBookings(state, payload) {
      state.upcomingBookings = payload;
    },
    setClosedBookings(state, payload) {
      state.closedBookings = payload;
    },
    setBooking(state, payload) {
      state.booking = payload;
    },
    setBookingActiveId(state, payload) {
      state.currentActiveBookingId = payload;
    },
    setActiveBooking(state, payload) {
      state.vehicle.booking = payload;
    },
    setVehicleStatus(state, statusId) {
      state.vehicle.status = statusId;
    },
    removeActiveBooking(state) {
      state.vehicle.booking = null;
    }
  },
  namespaced: true,
};

export default operator;
