<template>
  <div class="flex justify-between  q-mb-lg">
    <div class="flex gap-10">
      <q-select
        v-model="accountProvider"
        outlined
        :options="paymentOptions"
        :option-label="paymentOptions.label"
        :option-value="paymentOptions.value"
        hint="Provider"
        dense
        class="settings-input"
      />
      <q-input v-model="accountNumber" hint="Account Number" dense outlined class="settings-input" />
    </div>
    <q-btn icon="add" color="primary" flat @click="addAccount()">Add</q-btn>
  </div>

  <div class="q-px-lg">
    <span class="text-subtitle1 text-weight-medium">Accounts</span>
    <q-separator class="q-my-sm" />
    <div class="flex justify-between account-list q-mb-sm">
      <span class="text-subtitle2">Provider</span>
      <span class="text-subtitle2">Account</span>
      <span class="text-right text-subtitle2" >Actions</span>
    </div>
    <div class="flex justify-between account-list" v-for="acc in accounts" :key="acc.id">
      <span>{{ paymentOptions[acc.account_provider].label }}</span>
      <span>{{acc.account_number}}</span>
      <span class="text-right">
        <q-btn icon="delete" flat color="primary" @click="deleteAccount(acc.id)"></q-btn>
      </span>
    </div>
  </div>
</template>
<script setup>
import Providers from "../../assets/json/paymentProvider.json";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { notify } from "../../utils/notification";

const store = useStore();
const paymentOptions = Providers.providers;
const  accounts = computed(() => store.state.user.userData.payment_account_info) ;
const accountNumber = ref();
const accountProvider = ref();

function addAccount() {
  const payload = {
    user_id: store.state.user.userData.id,
    account_provider: accountProvider.value.value,
    account_number: accountNumber.value,
  };
  store.dispatch("user/createPaymentAccount", payload).then((r) => {
    if (r.status == "success") {
      store.dispatch("user/fetchUserData",store.state.user.userData.id)
      notify("positive", "Payment Account Added");
    }
  });
}

function deleteAccount(id){
  store.dispatch("user/deletePaymentAccount",id).then((r) => {
    if(r.status == 'success') {
      store.dispatch("user/fetchUserData",store.state.user.userData.id)
      notify("green", "Payment account deleted")
    }
  })
}
store.dispatch("user/fetchUserData",store.state.user.userData.id)
</script>
<style scoped>
.settings-input {
  width: 200px;
}
.account-list span{
  width: 33%;
}
</style>
