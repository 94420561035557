<template>
  <div class="fullscreen-fixed searchWrapper">
    <!-- Dialog -->
    <SearchDialog
      :quick_selection_duration_options="quick_selection_duration_options"
      :seatOptions="seatOptions"
      :fuelOptions="fuelOptions"
      :yardOptions="yardOptions"
      v-if="toolbar == true"
      @hide-modal="hideModal()"
      @load-vehicles="loadVehicles()"
      @minus-filter-count="minusFilterCount()"
    />

    <!-- SearchBox -->
    <div class="searchContainer">
      <div class="shadow-2 desktop-hide">
      <div class="q-pa-sm searchBox ">
        <div class="searchBoxDetails">
          <span class="text-weight-medium">Pickup Branch: </span>
          <span v-if="!location">Anywhere</span>
          <span v-else>{{ location.name }}</span>
        </div>
        <div class="searchBoxDetails">
          <span class="text-weight-medium">Pick Up Date: </span>{{ pickupdate }}
        </div>
        <div class="searchBoxDetails">
          <span class="text-weight-medium">Return Date: </span>{{ returndate }}
        </div>
      </div>
      <div class="flex gap-10 q-mb-sm q-pa-sm">
          <q-btn
            label="Search & Filters"
            color="black"
            class="full-width "
            dense=""
            @click="toolbar = true"
          >
          </q-btn>
        </div></div>
      <div class="search-details desktop-only">
        <SearchSidebarDesktop
          :quick_selection_duration_options="quick_selection_duration_options"
          :seatOptions="seatOptions"
          :fuelOptions="fuelOptions"
          :yardOptions="yardOptions"
          @hide-modal="hideModal()"
          @load-vehicles="loadVehicles()"
          @minus-filter-count="minusFilterCount()"
        />
      </div>
      <div class="results-wrapper">
        <span class="text-weight-bold q-ml-sm">Search Results:</span>
        <LoadingVehicleResultSearch v-if="isLoading" />
        <SearchCarCard
        class="desktop-only"
          v-if="!isLoading"
          @loadMoreVehicles="loadMoreVehicles()"
        />
        <SearchCarCardMobile
        class="mobile-only"
          v-if="!isLoading"
          @loadMoreVehicles="loadMoreVehicles()"
        />

        <div class="full-width flex justify-center items-center q-mt-lg">
          <q-spinner color="primary" size="3em" v-if="isLoading == true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import vehicleJson from "../assets/json/vehicle.json";
import SearchCarCard from "../components/Bookings/SearchCarCard.vue";
import SearchCarCardMobile from '../components/Bookings/SearchCarCardMobile.vue'
import SearchDialog from "../components/Bookings/SearchDialog.vue";
import SearchSidebarDesktop from "../components/Bookings/SearchSidebarDesktop.vue";
import { filterDate } from "../utils/filterData";
import { runSearch } from "../utils/searchQuery";
import LoadingVehicleResultSearch from "../components/LoadingSkeletons/LoadingVehicleResultSearch.vue";

const store = useStore();
const isLoading = ref(true);
const seatOptions = vehicleJson.seat_options;
const fuelOptions = vehicleJson.fuel_options;
const page = ref(1);
const yardOptions = computed(() => store.state.vehicle.yardList);
const quick_selection_duration_options = ref(
  vehicleJson.quick_selection_duration_options
);
const query = computed(() => store.state.vehicle.vehicleSearchQuery);
// const filterTypes = computed(() => query.value.filters.type);
const location = computed(() => query.value.yard);
const pickupdate = computed(() =>
  filterDate(query.value.pickupdate + " " + query.value.pickupTime)
);
const returndate = computed(() =>
  filterDate(query.value.returndate + " " + query.value.returnTime)
);
const toolbar = ref(false);

async function loadVehicles() {
  isLoading.value = true
  const response = await runSearch(false);
  if (response) {
    isLoading.value = false;
  }
}
function getYards() {
  store.dispatch("vehicle/fetchYards");
}

async function loadMoreVehicles() {
  page.value++;
  const response = await runSearch(true, page.value);
  if (response) {
    isLoading.value = false;
  }
}

function hideModal() {
  toolbar.value = false;
}

getYards();
loadVehicles();
</script>

<style scoped>
.fullscreen-fixed {
  width: 100vw;
  height: calc(100vh - 90px);
  max-height: 100vh;
  overflow: hidden;
}
.searchContainer {
  width: 95%;
  max-width: 1200px;
  margin: auto;
  display: flex;
}
.search-details {
  width: 30%;
  margin:0 auto;
  padding: 10px 10px;
  border-radius: 10px;
  height: calc(100vh - 90px);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.results-wrapper {
  overflow-y: scroll;
  height: calc(100vh - 90px);
  width: 70%;
}
.search-details span {
  font-weight: 600;
}
.max-height {
  height: calc(100vh - 90px);
}
.flex-column {
  flex-direction: column;
}
.searchBoxDetails {
  display: flex;
  flex-direction: column;
}

</style>
