<template>
  <div class="container ip-cont" style="max-width: 500px">
    <q-form @submit="sendInquiry">
      <section>
        <h4 class="text-accent"> <q-icon name="phone"/> Contact Us</h4>
        <span class="text-h6">We would love to hear from you!</span>
        <p>Send us a message and we’ll get right back in touch.</p>
        <p>If you would like to become an operator for Carbnb, please indicate it on the message box and we would be glad to guide you through the process.</p>
      </section>
      <div class="full-input">
        <q-input
          v-model="firstname"
          dense
          outlined
          placeholder="First Name"
          class="q-mb-sm"
          :rules="[(val) => (val && val.length > 0) || 'Please enter your first name.']"
        />
        <q-input
          v-model="lastname"
          dense
          outlined
          placeholder="Last Name"
          class="q-mb-sm"
        />
      </div>
      <div class="full-input flex flex-wrap gap-10 justify-between">
        <q-input v-model="email" type="email" dense outlined placeholder="Email Address" />
        <q-input v-model="phone" dense outlined placeholder="Phone Number" />
      </div>
      <div class="full-input">
        <q-input
          v-model="message"
          dense
          outlined
          type="textarea"
          placeholder="Message"
          class="q-mb-sm"
          :error="generalError != ''"
          :error-message="generalError"
          :rules="[(val) => (val && val.length > 0) || 'Please enter your message or concern.']"
        />
        <q-btn 
          color="primary"
          class="full-width"
          icon-right="send"
          :loading="isLoading"
          type="submit"
          label="Send"
        />
      </div>
    </q-form>
    <br><b>You can also contact us directly through this mobile number and email.</b><br><br>

      <q-icon name="phone" class="q-mr-sm"></q-icon
      ><a href="tel:+0285361446">Landline: 02 (8288-7096)</a> <br />
      <q-icon name="phone_iphone" class="q-mr-sm"></q-icon
      ><a href="tel:+639276400108">Mobile Number: +63 927 640 0108</a> <br />
      <q-icon name="email" class="q-mr-sm"></q-icon>
      <a href="mailto:support@carbnbrentals.com">support@carbnbrentals.com</a>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { notify } from "../utils/notification";
import { useRouter } from "vue-router";

const store = useStore();
const email = ref('');
const firstname = ref();
const lastname = ref();
const phone = ref('');
const message = ref();
const isLoading = ref(false)
const router = useRouter();

const generalError = ref('');

function sendInquiry() {
  if (email.value == '' && phone.value == '') {
    generalError.value = 'Either an email address or phone number is required.';
    return;
  }
  generalError.value = '';
  isLoading.value = true

  const payload = {
    name: firstname.value + " " + lastname.value,
    email: email.value,
    mobile_number: phone.value,
    comment: message.value,
  };

  store.dispatch("sendInquiry", payload).then((r) => {
    if (r.status == "success") {
      isLoading.value = false
      email.value = "";
      firstname.value = "";
      lastname.value = "";
      phone.value = "";
      message.value = "";
      notify(
        "positive",
        "Your inquiry has been sent. We will reach out to you soon.",
        "",
        3000
      );
      setTimeout(() => {
        router.push('/');
      }, 3000);
    }
  });
}
</script>
<style scoped>
.full-input {
  width: 100%;
  max-width: 400px;
  margin-bottom: 10px;
}
.full-input.flex .q-input {
  max-width: 48%;
}
</style>
