<template>
  <q-form class="dl-form">
    <div>
      <div class="subtitle1 q-pt-xs q-pb-none">License Number:</div>
      <q-input
        v-model="license_number"
        outlined
        class="cb-input"
        dense
        placeholder="e.g: N03-12-123456"
      />

      <div class="subtitle1 q-pt-sm q-pb-none">Expiration Date:</div>
      <q-input
        outlined
        v-model="expiration_date"
        class="cb-input"
        mask="date"
        dense
        placeholder="YYYY/MM/DD"
      >
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              cover
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date v-model="expiration_date">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>

      <div class="subtitle1 q-pt-sm q-pb-none">
        Driver License Photo (Front Side)
      </div>
      <q-img :src="prevDLFront" v-if="prevDLFront" class="previewImg"></q-img>
      <q-file
        color="secondary"
        bg-color="white"
        outlined
        v-model="front_photo"
        label="Upload Photo"
        dense
        class="full-width q-mb-sm"
      >
        <template v-slot:prepend>
          <q-icon name="image" />
        </template>
      </q-file>
      <div class="subtitle1 q-pt-sm q-pb-none">
        Driver License Photo (Back Side)
      </div>
      <q-img :src="prevDLBack" v-if="prevDLBack" class="previewImg"></q-img>
      <q-file
        color="secondary"
        bg-color="white"
        outlined
        v-model="back_photo"
        label="Upload Photo"
        dense
        class="full-width q-mb-sm"
      >
        <template v-slot:prepend>
          <q-icon name="image" />
        </template>
      </q-file>

      <div class="subtitle1 q-pt-sm q-pb-none">Photo of yourself</div>
      <q-img :src="prevDLSelfie" v-if="prevDLSelfie" class="previewImg"></q-img>
      <q-file
        color="secondary"
        bg-color="white"
        outlined
        v-model="selfie_photo"
        label="Upload Photo"
        dense
        hint="Upload a clear and visible photo of you. Make sure to remove any filter on your face."
        class="full-width q-mb-sm"
      >
        <template v-slot:prepend>
          <q-icon name="image" />
        </template>
      </q-file>

      <div class="subtitle1 q-pt-sm q-pb-none">Transmission Allowed:</div>
      <q-checkbox
        size="sm"
        v-model="transmission"
        val="2"
        label="Manual"
      />
      <q-checkbox
        size="sm"
        v-model="transmission"
        val="1"
        label="Automatic"

      />
    </div>

    <div class="full-width text-right">
      <q-btn label="Submit" color="primary" :loading="isLoading" @click="onSubmit()" />
    </div>
  </q-form>
</template>
<script setup>
import { ref } from "vue";

import { useStore } from "vuex";
import { notify } from "../../utils/notification";
import { defineProps } from "vue";
const props = defineProps(["user"]);
const store = useStore();
const user = store.state.user.userData;
const isLoading = ref(false);
const transmission = ref([]);
if (user.allowed_transmission == "0") {
  transmission.value = ["2", "1"];
} else if (user.allowed_transmission && user.allowed_transmission != "0") {
  transmission.value = [user.allowed_transmission];
}

const front_photo = ref();
const back_photo = ref();
const selfie_photo = ref();
const expiration_date = ref(props.user.license_expiration_date);
const license_number = ref(props.user.license_number);
const prevDLFront = user.license_front_image_id
  ? user.license_front_image_id.file
  : null;
const prevDLBack = user.license_back_image_id
  ? user.license_back_image_id.file
  : null;
const prevDLSelfie = user.license_selfie_image_id
  ? user.license_selfie_image_id.file
  : null;

function onSubmit() {
  
  let transmissionID = null;
  if( transmission.value.length == 0) {
    notify("red", "License Transmission is required")
    return
  }
  isLoading.value = true
  if (transmission.value.length == 2) {
    transmissionID = 0;
  } else {
    transmissionID = transmission.value[0];
  }
  const payload = {
    id: store.state.user.userData.id,
    data: {
      license_number: license_number.value,
      license_expiration_date: expiration_date.value,
      allowed_transmission: transmissionID,
      files: [],
    },
  };

  let f = null,
    b = null,
    s = null;

  if (front_photo.value) {
    f = getBase64(front_photo.value).then((data) => {
      payload.data.files.push({ file: data, title: "LICENSE_FRONT_IMAGE" });
    });
  }

  if (back_photo.value) {
    b = getBase64(back_photo.value).then((data) => {
      payload.data.files.push({ file: data, title: "LICENSE_BACK_IMAGE" });
    });
  }

  if (selfie_photo.value) {
    s = getBase64(selfie_photo.value).then((data) => {
      payload.data.files.push({ file: data, title: "LICENSE_SELFIE_IMAGE" });
    });
  }
  Promise.allSettled([f, b, s]).then(() => {
    store.dispatch("user/updateUserData", payload).then((response) => {
      if (response.status == "success") {
        isLoading.value = false
        notify("positive", "Your profile has been updated successfully.");
        setTimeout(() => {
          location.reload();
        }, 2000);
      }
    });
  });
}
function getBase64(file) {
  if (!file) {
    return;
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // reader.onloadend = (e) => resolve(imageToDataUri(e, 400, 400))
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
</script>
<style scoped>
.dl-form {
  min-width: 500px;
}
.previewImg {
  width: 50%;
  max-height: 150px;
  margin-bottom: 10px;
}
</style>
