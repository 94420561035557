<template>
    <div>
        <q-btn
            color="primary"
            icon-right="help_outlite"
            label="Requirements"
            class="btn-actions q-mt-md"
            @click="open"
        />
        <q-dialog v-model="dialog" :position="position">
            <q-card>
                <q-card-section class="row items-center no-wrap">
                    <img
                        :src="require('@/assets/images/apply-as-operator/carbnb-operator-requirements.jpg')"
                        alt="Carbnb Operator Application Benefits"
                        class="red-strip-bg full-width"
                    />
                </q-card-section>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
  setup () {
    const dialog = ref(false)
    const position = 'bottom';

    return {
      dialog,
      position,

      open () {
        dialog.value = true
      }
    }
  }
}
</script>
<style>
    .req-btn {
        min-width: 250px;
        border-radius: 15px;
        height: 50px;
    }
</style>