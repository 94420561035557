<template>
  <div>
    <q-card flat class="q-ma-none q-pa-none" style="max-width: 100%">
      <q-item class="q-ma-none q-pa-none">
        <q-item-section >
          <q-skeleton height="350px" width="100%" class="q-mb-sm" />
          <q-skeleton height="150px" width="100%" class="q-mb-sm" />
          <q-skeleton height="50px" width="100%" class="q-mb-sm" />
        </q-item-section>
      </q-item>
    </q-card>
  </div>
</template>
