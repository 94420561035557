/**
 * Defines the invoice types.
 */
export default {
    // When the invoice is for the main booking reservation
    booking: 1,
    // When the invoice is for the extension that has more than 12 hours
    fullExtension: 2,
    // When the invoice is for the extension that has less than 12 hours
    partialExtension: 3,
}