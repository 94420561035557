<template>
  <div class="q-pa-sm">
    <q-table
      title=""
      :rows="bookings"
      :columns="columns"
      row-key="row"
      :pagination="paginationTable"
      :loading="isLoading"
      v-if="bookings"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="code" :props="props">
            <router-link :to="'/booking/' + props.row.id">
              <q-badge color="primary" :label="props.row.booking_code" class="q-pa-sm" />
            </router-link>
          </q-td>
          <q-td key="pickupdate" :props="props">
            {{ filterDate(props.row.pickup_date,"MMM DD YYYY hh:mm a") }}
          </q-td>
          <q-td key="returndate" :props="props">
            {{ filterDate(props.row.estimated_return_date, "MMM DD YYYY hh:mm a") }}
          </q-td>
          <q-td key="status" :props="props">
            <BookingStatusBadge :status="props.row.status" />
          </q-td>
          <q-td>
            <q-btn color="primary" :to="'/booking/' + props.row.id">View</q-btn>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <span v-else-if="isEmpty && !isLoading">No current bookings made. To start renting, go to Book Now.</span>
  </div>
</template>

<script setup>
import moment from "moment";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import BookingStatusBadge from "../Globals/BookingStatusBadge.vue";
import { filterDate } from "../../utils/filterData";

const store = useStore();
const isLoading = ref(true);
const isEmpty = ref(false);
const bookings = computed(() => store.state.booking.bookingsByUser);
const booking_status = [
  "DISABLED",
  "FOR APPROVAL",
  "FOR PAYMENT",
  "FOR RELEASE",
  "ACTIVE",
  "HOLD",
  "CLOSED",
  "CANCELLED",
];
const columns = [
  {
    name: "code",
    required: true,
    label: "Booking Code",
    align: "left",
    field: (row) => row.booking_code,
    format: (val) => `${val}`,
    sortable: true,
  },
  {
    name: "pickupdate",
    align: "center",
    label: "Pickup date",
    field: (row) =>
      moment(new Date(row.pickup_date)).format("YYYY-MM-DD hh:mm A"),
    sortable: true,
  },
  {
    name: "returndate",
    align: "center",
    label: "Return date",
    field: (row) =>
      moment(new Date(row.estimated_return_date)).format(
        "YYYY-MM-DD hh:mm A"
      ),
    sortable: true,
  },
  {
    name: "status",
    align: "center",
    label: "Status",
    field: (row) => booking_status[row.status],
    sortable: true,
  },
  {
    name: "action",
    align: "center",
    label: "",
    sortable: false
  }
];
// const pagination = computed(() => store.state.booking.pagination);
// const paginationTable =  {
//   page: pagination.value.currentPage,
//   rowsPerPage: 10,
//   rowsNumber: pagination.value.overallCount
// };

const paginationTable = {
  page: 1,
  rowsPerPage: 10,
};
function loadUserBookings() {
  store
    .dispatch("booking/fetchBookingsFromCustomer", store.state.user.userData.id)
    .then((r) => {
      if (r.status == "not found") {
        isEmpty.value = true;
      }
      isLoading.value = false;
    });
}
// function filterDate(date) {
//   return moment(new Date(date)).format("MMM DD YYYY hh:MM A");
// }
loadUserBookings();
</script>
<style scoped>
.bookingsCard {
  display: flex;
  margin: 5px;
  margin-bottom: 40px;
  position: relative;
  border-right: 5px solid white;
}
.bookingsCard:hover {
  border-right: 5px solid red;
}
.photoholder {
  width: 35%;
  padding: 0;
  overflow: hidden;
}
.photoholder img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.booking-description {
  width: 65%;
}
.booking-description .title {
  font-weight: 600;

  font-size: 20px;
}
.bookingsCard .text-h6 span {
  color: var(--color-primary);
}
.bookingDate {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  justify-content: start;
  gap: 20px;
  padding: 15px 0;
}
.bookingDate span {
  color: var(--color-primary);
}
.bookingTags {
  display: flex;
  font-size: 12px;
  width: 100%;
  justify-content: start;
  gap: 20px;
  padding: 5px 15px;
}
.bookingTags > div {
  width: 30%;
}
.contact-link {
  font-weight: 400;
  font-size: 14px;
}
.contact-link:hover {
  color: var(--color-primary);
  text-decoration: underline;
}
.booking-status {
  border: 1px solid;
  border-radius: 20px;
  padding: 5px 8px;
  font-size: 12px;
}
.booking-status.for-approval {
  border-color: var(--q-warning);
}
.booking-status.confirmed {
  border-color: var(--q-positive);
}
</style>
