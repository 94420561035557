import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    notify: {}
  },
  extras: [
    'material-icons',
    'mdi-v5',
    'ionicons-v4',
    'eva-icons',
    'fontawesome-v5',
    'themify',
    'line-awesome',
    'bootstrap-icons',
    'fontawesome-v6',
    'roboto-font'
  ],
  plugins: {
    Notify,
    Loading,
    Dialog
  }

}