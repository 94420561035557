import script from "./BookingApprovalItem.vue?vue&type=script&setup=true&lang=js"
export * from "./BookingApprovalItem.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QExpansionItem,QItemSection,QCard,QCardSection,QItem,QItemLabel,QIcon,QBtn});
