import { createRouter, createWebHistory } from 'vue-router' // For website
//import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import Verification from '../views/Verification.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import Settings from '../views/Settings.vue'
import Dashboard from '../views/Dashboard.vue'
import Approvals from '../views/Approvals.vue'
import Policy from '../views/Policy.vue'
// import User from '../views/User.vue'
import MyVehicles from '../views/Operator/MyVehicles.vue'
import MyVehicle from '../views/Operator/MyVehicle.vue'
import Bookings from '../views/Bookings.vue'
import Booking from '../views/Booking.vue'
// import Favorites from '../views/Favorites.vue'
import Vehicle from '../views/Vehicle.vue'
import Search from '../views/Search.vue'
// import Messages from '../views/Messages.vue'
import CreateVehicle from '../views/Operator/AddVehicle.vue'
import ConfirmPayment from '../views/ConfirmPayment.vue'
import ManageBooking from '../views/ManageBooking.vue'
import AboutUs from '../views/AboutUs.vue'
import Contact from '../views/Contact.vue'
import ErrorPayment from '../views/404.vue'
import NotFound from '../views/NotFound.vue'
import ApplyAsOperator from '../views/ApplyAsOperator'
import Payment from '../views/Payment/PaymentApp.vue'

function requireLoggedIn(to, from, next) {
  if (store.state.isLoggedIn) {
    next();
  } 
  else {
    next('/login');
  }
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/our-policy',
    name: 'Policy',
    component: Policy
  },
  {
    path: '/verification',
    name: 'Verification',
    component: Verification
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/my-vehicles',
    name: 'MyVehicles',
    component: MyVehicles,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/my-vehicles/:id',
    name: 'MyVehicle',
    component: MyVehicle,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/bookings',
    name: 'Bookings',
    component: Bookings,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/manage-booking/:id',
    name: 'ManageBooking',
    component: ManageBooking,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/booking/:id',
    name: 'Booking',
    component: Booking,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/booking/:id/payment',
    name: 'Payment',
    component: Payment,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/ew-confirm',
    name: 'ConfirmPayment',
    component: ConfirmPayment,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/approvals',
    name: 'Approvals',
    component: Approvals,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/vehicle/:id',
    name: 'Vehicle',
    component: Vehicle
  },
  {
    path: '/vehicle/create',
    name: 'CreateVehicle',
    component: CreateVehicle,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/contact',
    name: 'Contactus',
    component: Contact
  },
  {
    path: '/apply-as-operator',
    name: 'ApplyAsOperator',
    component: ApplyAsOperator
  },
  {
    path: '/error',
    name: 'ErrorPayment',
    component: ErrorPayment,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //history: createWebHashHistory(),
  mode: 'hash',
  routes,
  scrollBehavior () {
    return { top:0 }
  }
})

router.beforeEach((to) => {
  // Hide Footer on Search Page
  const currentRoute = to.name;
  store.state.displayFooter =  currentRoute == 'Search' || currentRoute == 'Messages' ? false : true
})


export default router