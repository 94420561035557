<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <img
                        :src="require('@/assets/images/carbnb-logo.webp')"
                        alt="Carbnb affordable car rental in the Philippines"
                        class="red-strip-bg full-width"
                        style="max-width: 300px"
                    />    
                </div>
                <div class="col-md-6 col-12 q-pa-md">
                    <h4 class="text-accent">Our Vision</h4>
                    <p class="text-grey-7">Elevate and broaden our reach across the nation as a pioneering peer-to-peer
                        car rental platform to drive digital innovations and seamlessly connect explorers with the freedom of self-drive adventures. 
                    </p>
                </div>
                <div class="col-md-6 col-12 q-pa-md">
                    <h4 class="text-accent">Our Mission</h4>
                    <p class="text-grey-7">To revolutionize travel experience by providing a reliable peer-to-peer
                        car rental platform, driven by digital-first approach and connectivity. Fostering customer satisfaction and engagement.
                    </p>
                </div>
                <div class="col-md-6 col-12 q-pa-md">
                    <h4 class="text-accent">Our Services</h4>
                    <h6 class="text-grey-7"><q-icon name="group" class="text-accent"/> Professional Staff & Operator</h6>
                    <p>We assure our staffs are friendly, accomodating, highly trained and knowledgeable in the car industry.
                        Providing a smooth and hassle-free transaction to our beloved clients.
                    </p>
                    <h6 class="text-grey-7"><q-icon name="directions_car" class="text-accent"/> Car Availability</h6>
                    <p>
                        Carbnb offers a wide range of car varieties, from Hatchback, Sedan, MPV, SUV, Pick-Up and Van. Available in manual or
                        automatic transmission.
                    </p>
                    <h6 class="text-grey-7"><q-icon name="health_and_safety" class="text-accent"/> Legalities and Safety Measures</h6>
                    <p>We guarantee that our vehicles are well maintained and regularly checked for safe travel, complete with legal documents, all safety tools need and are LTO registered.</p>
                    <h6 class="text-grey-7"><q-icon name="sell" class="text-accent"/> Competitive and Affordable Rates</h6>
                    <p>We offer self-drive car rental at affordable rates with unlimited mileage.</p>
                </div> 
                <div class="col-md-6 col-12 q-pa-md">
                    <img
                        :src="require('@/assets/images/about/carbnb-affordable-car-rental-philippines.webp')"
                        alt="Carbnb affordable car rental in the Philippines"
                        class="red-strip-bg full-width"
                    />                    
                </div>              
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>