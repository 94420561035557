<template>
  <q-drawer
    v-model="leftDrawerOpen"
    :width="350"
    :breakpoint="400"
    overlay
    class="text-black"
    no-swipe-open
    no-swipe-close
    no-swipe-backdrop
  >
    <q-scroll-area style="height: calc(100% - 150px)">
      <div class="q-pa-md">
        <q-btn dense round icon="arrow_back" size="16px" class="bg-accent text-white" @click="toggleLeftDrawer()" />
      </div>
      <q-list>
        <!-- <q-item clickable @click="toggleLeftDrawer()" v-if="user" :to="'/user/' + user.id">
          <q-item-section class="q-pl-md text-subtitle1"> Profile </q-item-section>
        </q-item> -->
        
        <q-item clickable  @click="toggleLeftDrawer()" to="/about-us" >
          <q-item-section class="q-pl-md text-subtitle2"><span><q-icon name="lightbulb" size="24px" /> About Us</span></q-item-section>
        </q-item>
        <q-item clickable  @click="toggleLeftDrawer()" to="/search" >
          <q-item-section class="q-pl-md text-subtitle2"><span><q-icon name="directions_car" size="24px" /> Book Now</span> </q-item-section>
        </q-item>
        <q-item clickable  @click="toggleLeftDrawer()" to="/apply-as-operator" >
          <q-item-section class="q-pl-md text-subtitle2"><span><q-icon name="edit_note" size="24px" /> Apply as Operator</span></q-item-section>
        </q-item>
        <q-item clickable  @click="toggleLeftDrawer()" to="/contact" >
          <q-item-section class="q-pl-md text-subtitle2"><span> <q-icon name="call" size="24px" /> Contact Us</span></q-item-section>
        </q-item>
        <q-item clickable v-ripple @click="toggleLeftDrawer()" to="/our-policy" >
          <q-item-section class="q-pl-md text-subtitle2"><span><q-icon name="article" size="24px" /> Privacy Policy</span></q-item-section>
        </q-item>
        <q-item v-if="user" clickable @click="toggleLeftDrawer()" to="/bookings">
          <q-item-section class="q-pl-md text-subtitle2"><span><q-icon name="library_books" size="24px" /> My Bookings</span> </q-item-section>
        </q-item>

        <q-item v-if="user" clickable @click="toggleLeftDrawer()" to="/my-vehicles">
          <q-item-section class="q-pl-md text-subtitle2"><span><q-icon name="directions_car" size="24px" /> My Vehicles</span> </q-item-section>
        </q-item>
        
        <q-item v-if="user" clickable @click="toggleLeftDrawer()" to="/dashboard">
          <q-item-section class="q-pl-md text-subtitle2"><span><q-icon name="account_circle" size="24px" /> Dashboard</span></q-item-section>
        </q-item>


        <q-item v-if="user" clickable @click="toggleLeftDrawer()" to="/settings">
          <q-item-section class="q-pl-md text-subtitle2"> <span><q-icon name="settings" size="24px" /> Settings</span> </q-item-section>
        </q-item>
        <q-item clickable  @click="toggleLeftDrawer()" to="/login" v-if="!user" >
          <q-item-section class="q-pl-md text-subtitle2"><span><q-icon name="login" size="24px" /> Sign In </span> </q-item-section>
        </q-item>
        
        
        <q-item clickable  @click="toggleLeftDrawer()" to="/signup" v-if="!user">
          <q-item-section class="q-pl-md text-subtitle2"><span><q-icon name="add" size="24px" /> Create Account </span> </q-item-section>
        </q-item>
        <q-item clickable  @click="logout(), toggleLeftDrawer()" v-else >
          <q-item-section class="q-pl-md text-subtitle2"><span><q-icon name="logout" size="24px" /> Sign Out </span></q-item-section>
        </q-item>

      </q-list>
    </q-scroll-area>
  </q-drawer>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const leftDrawerOpen = computed(() => store.state.leftDrawerOpen);
const router = useRouter();
const user = computed(() => store.state.user.userData);

function logout() {
  store.dispatch("logout").then((response) => {
    store.commit("user/logout");
    store.commit("booking/logout");
    if (response == "logged_out") {
      router.push("/login");
    }
  });
}

function toggleLeftDrawer() {
  store.commit("setToggleDrawer")
}
</script>

<style scoped>
.q-icon {
  color: #343a40;
}
</style>
