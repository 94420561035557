import axios from "axios";

const user = {
  state: () => ({
    userData: null,
    userStats: null,
    userRentalHistory: null
  }),

  getters: {
    getFullName: (state) => {
      return state.userData.first_name + " " + state.userData.last_name;
    },
    getLastName: (state) => {
      return state.userData.last_name;
    }
  },

  actions: {
    fetchUserData({ commit }, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/users/${payload}`,
          method: "GET",
        }).then((response) => {
          if (response.data.status == "success") {
            commit("setUserData", response.data.data.user);
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    fetchUserStats({ commit }, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/users/${payload}/stats`,
          method: "GET",
        }).then((response) => {
          if (response.data.status == "success") {
            commit("setUserStats", response.data.data);
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    fetchUserRentalHistory({ commit }, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/users/operator/${payload}/bookings/history`,
          method: "GET",
        }).then((response) => {
          if (response.data.status == "success") {
            commit("setUserRentalHistory", response.data.data.bookings);
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    updateUserData({ dispatch }, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/users/${payload.id}`,
          method: "PUT",
          data: payload.data,
        }).then((response) => {
          if (response.data.status == "success") {
            dispatch("fetchUserData",response.data.data.id);
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    requestUpdatePassword({ dispatch }, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/users/${payload}/sendpasswordreset`,
          method: "GET"
        }).then((response) => {
          if (response.data.status == "success") {
            dispatch("fetchUserData",response.data.data.id);
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    updatePassword(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/changepassword`,
          method: "POST",
          data: payload,
        }).then((response) => {
          return resolve(response);
        }).catch((err) => {
          return reject(err);
        })
      });
    },
    forgotPasswordRequest(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/forgotpassword`,
          method: "POST",
          data: payload,
        }).then((response) => {
          return resolve(response);
        }).catch((error) => {
          return reject(error);
        })
      });
    },
    forgotPasswordUpdate(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/forgotpassword/confirm`,
          method: "POST",
          data: payload,
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      });
    },
    createPaymentAccount(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1//users/payment-account`,
          method: "POST",
          data: payload,
        }).then((response) => {
          if (response.data.status == "success") {
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    updatePaymentAccount(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1//users/payment-account/${payload.id}`,
          method: "PUT",
          data: payload,
        }).then((response) => {
          if (response.data.status == "success") {
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    deletePaymentAccount(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1//users/payment-account/${payload}`,
          method: "DELETE",
        }).then((response) => {
          if (response.data.status == "success") {
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    checkApplication({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/${payload}/apply-as-operator`,
          method: "GET",
        }).then((response) => {
          if (response.data.status) {
            if (response.data.status == 'already_an_operator') {

              // If already an operator, check the current user type
              // and morph it to type 2.
              commit('setUserType', 2);

              return resolve({
                status: response.data.status,
                vehicle_application_id: null
              });
            } else if (response.data.status == 'mobile_number_required') {
              return resolve({
                status: response.data.status,
                vehicle_application_id: null
              });
            } else {
              return resolve({
                status: response.data.status,
                vehicle_application_id: response.data.data.vehicle_application_id
              });
            }
          }
        }).catch(() => {
          return reject({
            status: null,
            vehicle_application_id: null
          });
        });
      });
    },
    
  },

  mutations: {
    setUserData(state, payload) {
      state.userData = payload;
    },
    setUserStats(state, payload) {
      state.userStats = payload;
    },
    setUserRentalHistory(state, payload) {
      state.userRentalHistory = payload;
    },
    logout(state) {
      state.userData = null;
      state.userStats = null;
      state.userRentalHistory = null;
    },
    setUserType(state, payload) {
      state.userData.type = payload;
    }
  },
  namespaced: true,
};

export default user;
