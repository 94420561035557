import script from "./MyVehicle.vue?vue&type=script&setup=true&lang=js"
export * from "./MyVehicle.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection,QIcon,QBadge});
