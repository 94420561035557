<template>
  <div class="container profileDashboard">
    <ProfileSidebar />
    <section>
      <h5>Add Vehicle</h5>
      <p>Please fill out the form below to add another vehicle for rent.</p>
      <AddVehicleForm v-if="user.type==2" :forAddVehicle="true"/>
      <p v-else class="full-width text-center">You are not yet a verified operator. Please apply <router-link to="/apply-as-operator">here</router-link> and submit your first vehicle application.</p>
    </section>
  </div>
</template>

<script setup>
import AddVehicleForm from "@/components/Car/VehicleForm.vue";
import ProfileSidebar from "../../components/Profile/ProfileSidebar.vue";
import { useStore } from "vuex";

const store = useStore();
const user = store.state.user.userData;
</script>
<style scoped>
a {
  color: var(--color-primary);
}
a:hover {
  text-decoration:underline;
}
</style>
