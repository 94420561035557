import moment from "moment";
import { useStore } from "vuex";

/**
 * Initializes the search query default data if the system did not found any values.
 */
export function initializeSearchQuery() {
    // Initialize Vuex
    const store = useStore();

    // Determine if the search query is empty.
    const isSearchQueryEmpty = store.getters.isSearchQueryEmpty;

    // Get the search query variable from the session storage.
    const query = sessionStorage.getItem("searchQuery");

    // If both the query and search query on Vuex is good.
    if (query && !isSearchQueryEmpty) {
        console.log('The session storage and Vuex are consistent.');
        return;
    }

    console.error('Either the session storage or Vuex is inconsistent. The system will try to repair it.');

    // If either query and vuex is empty, initialize it.
    // Compute base date and add 3 hours.
    const baseDate = moment(new Date()).add(3, "hours")
        .format("YYYY-MM-DD HH:MM:SS");
    
    // Clear the location
    store.state.vehicle.vehicleSearchQuery.location = "";

    // Derive the pick up date.
    const pickupDate = moment(baseDate, "YYYY-MM-DD")
        .format("YYYY-MM-DD");

    // Derive the return date.
    const returnDate = moment(baseDate, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");

    // Derive the time
    const time = moment(new Date(), "HH:MM:SS")
        .add(3, "hours")
        .format("hh:mm a");

    // Assign the date and time
    store.state.vehicle.vehicleSearchQuery.pickupdate = pickupDate;
    store.state.vehicle.vehicleSearchQuery.returndate = returnDate;
    store.state.vehicle.vehicleSearchQuery.pickupTime = time;
    store.state.vehicle.vehicleSearchQuery.returnTime = time;
    store.state.vehicle.vehicleSearchQuery.duration = {
        label: '1 day',
        value: 1
    };
    // Save a copy to the session storage.
    const data = {
        pickupdate: store.state.vehicle.vehicleSearchQuery.pickupdate,
        pickupTime: store.state.vehicle.vehicleSearchQuery.pickupTime,
        returnTime: store.state.vehicle.vehicleSearchQuery.returnTime,
        returndate: store.state.vehicle.vehicleSearchQuery.returndate,
        duration: store.state.vehicle.vehicleSearchQuery.duration, 
        filters: {
            brand: null,
            transmission: null,
            type: [],
            others: null,
            seat: null,
            with_driver: null,
            fuel_type: null,
            model: null,
        }       
    };

    sessionStorage.setItem("searchQuery", JSON.stringify(data));
    console.log('The session storage and Vuex repair are successful.')
}