<template>

    <q-card>
      <q-toolbar class="bg-accent text-white">
        <h6 class="q-ml-none text-white q-my-xs">Search & Filters</h6>
      </q-toolbar>

      <q-card-section>
        <div class="floating-searchbox">
          <div class="col-12 col-md">
            <div class="row q-my-sm">
              <div class="col-12">
                <!-- Location -->
                <span class="text-weight-medium">Pickup Branch</span>
                <q-select
                  outlined
                  @update:model-value="(val) => updateYard(val)"
                  v-model="yardModal"
                  :options="yardsList"
                  :option-label="yardsList.label"
                  :option-value="yardsList.id"
                  clearable
                  dense
                  label="Select Branch"
                  class="mobileFullWidth"
                >
                  <template v-slot:prepend>
                    <q-icon name="place" />
                  </template>
                </q-select>
                <small
                  >To search available vehicles anywhere. You can clear this
                  field instead.</small
                >
              </div>
            </div>
            <!-- Pickup Date -->
            <span class="text-weight-medium">Pickup Date and Time</span>
            <div class="flex q-mb-sm">
              <div class="full-width q-mb-sm">
                <q-input outlined v-model="pickupdateModal" dense>
                  <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        cover
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          @update:model-value="(val) => updatePickupDate(val)"
                          v-model="pickupdateModal"
                          :options="optionDates"
                          years-in-month-view
                          :default-year-month="
                            moment(new Date()).format('YYYY/MM')
                          "
                          :navigation-min-year-month="
                            moment(new Date()).format('YYYY/MM')
                          "
                          mask="YYYY-MM-DD"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="full-width q-mt-md">
                <q-input outlined v-model="pickupTimeModal" dense>
                  <template v-slot:prepend>
                    <q-icon name="access_time" class="cursor-pointer">
                      <q-popup-proxy
                        cover
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-time
                          @update:model-value="(val) => updatePickupTime(val)"
                          v-model="pickupTimeModal"
                          mask="hh:mm a"
                          format12h
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-time>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </div>
            <div class="full-width q-mt-md">
              <!-- Return Date -->
              <span class="text-weight-medium">Duration</span>
              <q-select
                dense
                outlined
                clearable
                class="full-width mobileFullWidth"
                v-model="quick_selection_duration"
                :options="props.quick_selection_duration_options"
                @update:model-value="(val) => getReturnDateTime(val)"
              />
            </div>
          </div>
          <span class="text-weight-medium ">Return Date: {{ returndate }}</span>
          <h6 class="text-caption4 text-uppercase q-my-sm">Filters</h6>
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 text-weight-medium">
              VEHICLE:
              <br />
              <div class="col">
                <div class="filter-v-type">
                  <q-checkbox
                    size="sm"
                    v-model="vehicle_type"
                    class="vehicle-cbox"
                    :val="2"
                    label="SEDAN (5 seater)"
                  />
                  <q-checkbox
                    size="sm"
                    v-model="vehicle_type"
                    class="vehicle-cbox"
                    :val="6"
                    label="SUV (8 seater)"
                  />
                  <q-checkbox
                    size="sm"
                    v-model="vehicle_type"
                    class="vehicle-cbox"
                    :val="3"
                    label="MPV (7 seater)"
                  />
                  <q-checkbox
                    size="sm"
                    v-model="vehicle_type"
                    class="vehicle-cbox"
                    :val="4"
                    label="PICKUP (5 seater)"
                  />
                  <q-checkbox
                    size="sm"
                    v-model="vehicle_type"
                    class="vehicle-cbox"
                    :val="5"
                    label="VAN (12 seater)"
                  />
                  <q-checkbox
                    size="sm"
                    v-model="vehicle_type"
                    class="vehicle-cbox"
                    :val="1"
                    label="HATCHBACK (4 seater)"
                  />

                  <q-checkbox
                    size="sm"
                    v-model="vehicle_type"
                    class="vehicle-cbox"
                    :val="7"
                    label="L300 (18 seater)"
                  />
                </div>
              </div>
            </div>

            <div
              class="col-md-6 q-pa-md col-sm-7 col-xs-12 text-weight-medium hidden"
            >
              SEATS:
              <div class="col q-pt-sm">
                <q-select
                  dense
                  outlined
                  v-model="seat"
                  :options="props.seatOptions"
                />
              </div>
            </div>
            <div
              class="col-md-6 q-pa-md col-sm-7 col-xs-12 text-weight-medium hidden"
            >
              Fuel Type:
              <div class="col q-pt-sm">
                <q-select
                  dense
                  outlined
                  v-model="fuel"
                  :options="props.fuelOptions"
                />
              </div>
            </div>

            <div class="col-md-5 col-sm-6 col-xs-12 text-weight-medium">
              TRANSMISSION:
              <div class="col q-pt-sm">
                <div class="q-gutter-sm">
                  <q-checkbox
                    size="sm"
                    v-model="transmission"
                    val="Manual"
                    label="Manual"
                    class="q-mt-none"
                  />
                  <q-checkbox
                    size="sm"
                    v-model="transmission"
                    val="Automatic"
                    label="Automatic"
                    class="q-mt-none"
                  />
                </div>
              </div>
            </div>

            <!-- <div class="col-md-5 col-sm-6 col-xs-12 text-weight-medium hidden">
              OTHERS:
              <div class="col q-pt-sm">
                <div class="q-gutter-sm">
                  <q-checkbox
                    size="sm"
                    v-model="with_driver"
                    val="sm"
                    label="with Driver"
                  />
                </div>
              </div>
            </div> -->
          </div>
          <div class="full-width q-my-sm">
            <!-- <q-btn flat label="Clear Filters" @click="clearFilters()" /> -->
            <q-btn
              color="primary"
              class="full-width"
              label="Save & Apply Filters"
              @click="submitFilters"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>
</template>
<script setup>
import { ref, defineEmits, defineProps, computed } from "vue";
import { useStore } from "vuex";
import carBrandsData from "../../assets/json/carBrands.json";
import moment from "moment";
import { notify } from "../../utils/notification";
import { filterDate } from "../../utils/filterData";
import { getReturnDateTime } from "../../utils/searchQuery";

const options = carBrandsData.reduce((acc, opt) => {
  acc.push(opt);
  return acc;
}, []);
const props = defineProps({
  quick_selection_duration_options: { type: Array },
  seatOptions: { type: Array },
  fuelOptions: { type: Array },
  yardOptions: { type: Object },
});

const store = useStore();
const query = computed(() => store.state.vehicle.vehicleSearchQuery);
const vehicle_type = ref(query.value.filters.type ?? []);
const quick_selection_duration = ref(query.value.duration);
// const carBrands = ref(options);
const brand = ref(options[query.value.filters.brand - 1]);
const seat = ref();
const fuel = ref();
const returndate = computed(() =>
  filterDate(query.value.returndate + " " + query.value.returnTime)
);

const pickupdateModal = ref(
  moment(query.value.pickupdate, "YYYY-MM-DD").format("YYYY-MM-DD")
);
const pickupTimeModal = ref(
  moment(query.value.pickupTime, "hh:mm:a").format("hh:mm a")
);
const yardModal = ref(query.value.yard);
const transmission = ref([]);
if (query.value.filters.transmission == "2") {
  transmission.value = ["Manual"];
} else if (
  query.value.filters.transmission &&
  query.value.filters.transmission == "1"
) {
  transmission.value = ["Automatic"];
} else if (
  query.value.filters.transmission &&
  query.value.filters.transmission == "0"
) {
  transmission.value = ["Manual", "Automatic"];
} else {
  transmission.value = [];
}
const yardsList = ref(props.yardOptions);
const emit = defineEmits(["hideModal", "loadVehicles"]);


function updatePickupDate(date) {
  if (date) {
    store.commit("vehicle/setSearchPickupDate", date);
    if (quick_selection_duration.value) {
      getReturnDateTime(quick_selection_duration.value);
    }
  }
}
function optionDates(date) {
  return (
    date >= moment(new Date()).subtract(1, "hour").format("YYYY/MM/DD") &&
    date <= moment(new Date()).add(1, "years").format("YYYY/MM/DD")
  );
}
function updateYard(yard) {
  let payload = {
    name: yard ?? null,
    id: yard.id ?? null,
  };

  store.commit("vehicle/setSearchYardData", payload);
}
function updatePickupTime(time) {
  if (time) {
    time = moment(time, "hh:mm a").format("hh:mm a");
    store.commit("vehicle/setSearchPickupTime", time);
    if (quick_selection_duration.value) {
      getReturnDateTime(quick_selection_duration.value);
    }
  }
}
// function clearFilters() {
//   yardModal.value = null;
//   pickupdateModal.value = null;
//   pickupdateModal.value = null;
//   returndateModal.value = null;
//   brand.value = null;
//   vehicle_type.value = [];
//   with_driver.value = null;
//   fuel.value = null;
//   seat.value = null;
//   transmission.value = [];
// }

// function filterFn(val, update) {
//   update(() => {
//     const needle = val.toLowerCase();
//     carBrands.value = options.filter(
//       (val) => val.label.toLowerCase().indexOf(needle) > -1
//     );
//   });
// }

// function setModel(val) {
//   carBrands.value.map((carbrand) => {
//     if (carbrand.label == val) {
//       brand.value = carbrand;
//     }
//   });
// }

function yardArry() {
  yardsList.value = [];
  props.yardOptions.map((yard) => {
    yard["label"] = yard.name;
    yard["value"] = yard.id;
    yardsList.value.push(yard);
  });
  store.commit("vehicle/setYardList", yardsList.value);
}
yardArry();

function submitFilters() {
  if (!pickupdateModal.value) {
    notify("red", "Please re-enter your desired pick up date and time.");
    return;
  }
  if (!pickupTimeModal.value) {
    notify("red", "Please enter your desired pick up time.");
    return;
  }
  if (!quick_selection_duration.value) {
    notify(
      "red",
      "Please set your Rental Duration on how many days you want to rent a vehicle."
    );
    return;
  }

  var transmission_val = null;
  if (transmission.value.length == 2) {
    transmission_val = 0;
  } else if (transmission.value[0] == "Manual") {
    transmission_val = 2;
  } else if (transmission.value[0] == "Automatic") {
    transmission_val = 1;
  } else {
    transmission_val = null;
  }

  const data = {
    brand: brand.value ? brand.value.value : null,
    type: vehicle_type.value ?? [],
    transmission: transmission_val,
    seat: seat.value ? seat.value.value : null,
    fuel_type: fuel.value ? fuel.value.value : null,
  };
  emit("hideModal");
  // sessionStorage.setItem("searchQuery", JSON.stringify(data));
  store.commit("vehicle/setSearchQueryFilters", data);
  emit("loadVehicles");
  return;
}
</script>
<style scoped>
.filter-v-type {
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-wrap: wrap;
}
.vehicle-cbox {
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  margin-top: 0;
}
</style>
