<template>
  <section>
  <div class="car-header-title q-mx-md mobileNoMargin ">
    <div class="q-pb-md text-h5 text-bold desktop-hide">
      {{ vehicle.vehicle_name }}
    </div>
    <div class="desktop-hide text-center full-width">{{ vehicle.name }}</div>
    <div class="car-header-subicons desktop-hide">
      <div class="text-subtitle1 flex items-center">
        <q-icon name="fa-solid fa-user q-pr-sm" />
        {{ vehicle.number_of_seats }} seats
      </div>
      <div class="text-subtitle1 text-capitalize flex items-center">
        <q-icon name="fa-solid fa-car q-pr-sm " />{{ vehicle.type }}
      </div>
      <div class="text-subtitle1 flex items-center">
        <q-icon name="fas fa-cogs m-2 q-pr-sm" />{{ vehicle.transmission }}
      </div>
    </div>
  </div>
  <q-separator spaced />
  <q-card
    class="q-my-md mobileNoMargin"
    flat
    v-if="props.booking_details && !props.isView"
  >
    <q-card-section>
      <div class="flex">
        <div class="full-width q-my-sm">
          <div class="text-subtitle2 q-my-sm">Pickup Date</div>
          <q-input outlined 
            v-model="pickupdateModal" 
            dense @click="$refs.qDateProxys.show()"
            :disable="isCheckingAvailability"
          >
            <template v-slot:prepend>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  cover
                  transition-show="scale"
                  transition-hide="scale"
                  ref="qDateProxys"
                >
                  <q-date
                    @update:model-value="(val) => updatePickupDate(val)"
                    v-model="pickupdateModal"
                    :options="optionDates"
                    years-in-month-view
                    :default-year-month="
                      moment(new Date()).format('YYYY/MM')
                    "
                    :navigation-min-year-month="
                      moment(new Date()).format('YYYY/MM')
                    "
                    mask="YYYY-MM-DD"
                    @input="$refs.qDateProxys.hide()"
                  >
                    <div class="row items-center justify-end">
                      <q-btn
                        v-close-popup
                        label="Close"
                        color="primary"
                        flat
                      />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>          
        </div>
        <div class="full-width q-my-sm">
          <div class="text-subtitle2 q-my-sm">Pickup Time</div>
          <q-input
            outlined
            v-model="pickupTimeModal"
            dense
            @click="$refs.qTimeProxys.show()"
            :disable="isCheckingAvailability"
          >
            <template v-slot:prepend>
              <q-icon name="access_time" class="cursor-pointer">
                <q-popup-proxy
                  cover
                  transition-show="scale"
                  transition-hide="scale"
                  ref="qTimeProxys"
                >
                  <q-time
                    @update:model-value="(val) => updatePickupTime(val)"
                    v-model="pickupTimeModal"
                    mask="hh:mm a"
                    format12h
                    @input="$refs.qTimeProxys.hide()"
                  >
                    <div class="row items-center justify-end">
                      <q-btn
                        v-close-popup
                        label="Close"
                        color="primary"
                        flat
                      />
                    </div>
                  </q-time>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
        <div class="full-width q-my-sm">
          <div class="text-subtitle2 q-my-sm">Duration</div>
          <div class="flex q-pb-sm">
            <div class="full-width">
              <q-select
                dense
                outlined
                clearable
                class="full-width mobileFullWidth"
                v-model="quick_selection_duration"
                :options="quick_selection_duration_options"
                @update:model-value="(val) => updateDuration(val)"
                :disable="isCheckingAvailability"
              />
            </div>
          </div>          
        </div>
      </div>
    </q-card-section>
    <q-card-section class="flex justify-between q-py-sm q-px-md">
      <q-banner class="text-white q-mb-lg bg-red" v-if="isAvailable == false">
        <template v-slot:avatar>
          <q-icon name="info" color="white" />
        </template>
        <span class="text-caption">{{ availabilityMessage }}</span>
        <template v-slot:action>
        <q-btn flat color="white" icon="arrow_back" to="/search" label=" Change Vehicle" />
      </template>
      </q-banner>
      <div class="q-px-sm text-subtitle2 text-accent">Pick Up Date</div>
      <div class="text-subtitle2">
        {{ pickupdate }}
      </div>
    </q-card-section>

    <q-card-section class="flex justify-between q-py-sm q-px-md q-pb-md">
      <div class="q-px-sm text-subtitle2 text-accent">Return Date</div>
      <div class="text-subtitle2">
        {{ returndate }}
      </div>
    </q-card-section>
    <q-card-section>
      <p>Please double check your pick up date and return date before clicking Book Now.
        <br><br>The dates and price will automatically adjust when you update your pickup date, time or duration.</p>
    </q-card-section>
  </q-card>
  <q-card class="q-my-md" flat v-if="props.booking_details && !props.isView">
    <q-card-section class="flex justify-between">
      <div class="q-pl-sm text-subtitle2 text-accent">
        <span> Reservation Fee </span>
      </div>

      <div class="text-subtitle2 text-right">
        <span class="text-caption">{{ price.reservationFee }} </span><br />
      </div>
    </q-card-section>
    <q-card-section class="flex justify-between q-py-sm q-px-md">
      <div class="q-pl-sm text-subtitle2 text-accent">
        <span> Remaining Balance</span>
      </div>

      <div class="text-subtitle2 text-right">
        <span class="text-caption">{{ price.operatorsFee }} </span><br />
      </div>
    </q-card-section>
    <q-separator inset />

    <q-card-section class="flex justify-between q-py-sm q-px-md">
      <div class="q-pl-sm text-subtitle2 text-accent">Total Price:</div>

      <div class="text-subtitle2 text-right">{{ price.finalPrice }}</div>
    </q-card-section>
    <q-card-section>
      <q-btn
        class="btn-primary"
        label="Book Now"
        @click="showDestination = true"
        :loading="(isOnFinal && isLoading) || isCheckingAvailability"
        icon="check_circle_outline"
        :disable="!isAvailable"
      />
      <q-btn
        class="btn-primary"
        label="Select Another Vehicle"
        to="/search"
        icon="arrow_back"
        :disable="!isAvailable"
      />
    </q-card-section>
  </q-card>
  <q-dialog
    v-model="showDestination" 
    class="destinationModal" 
    :persistent="isOnFinal"
    :maximized="$q.screen.lt.md"
  >
    <q-card v-if="!isOnFinal">
      <q-card-section>
        <div class="text-h6">Your Booking Summary</div>
        <br>
        <p>The vehicle will be reserved to you on the date of your choice for 1 hour until you pay for the reservation fee.</p>
      </q-card-section>

      <q-card-section class="flex justify-between q-py-none q-px-md">
        <div class="q-px-sm text-subtitle2">Vehicle:</div>
        <div class="text-subtitle2">
          {{ vehicle.brand_name }} {{ vehicle.model_name }}
      {{ vehicle.year }}
        </div>
      </q-card-section>

      <q-card-section class="flex justify-between q-py-none q-px-md q-pb-sm">
        <div class="q-px-sm text-subtitle2">Type:</div>
        <div class="text-subtitle2">
          {{ vehicle.type }}
        </div>
      </q-card-section>

      <q-card-section class="flex justify-between q-py-none q-px-md q-pb-sm">
        <div class="q-px-sm text-subtitle2">Pickup Date:</div>
        <div class="text-subtitle2">
          {{ pickupdate }}
        </div>
      </q-card-section>

      <q-card-section class="flex justify-between q-py-none q-px-md q-pb-sm">
        <div class="q-px-sm text-subtitle2">Return Date:</div>
        <div class="text-subtitle2">
          {{ returndate }}
        </div>
      </q-card-section>
      <q-card-section class="flex justify-between q-py-none q-px-md q-pb-sm">
        <div class="q-px-sm text-subtitle2">Total Rental Price:</div>
        <div class="text-subtitle2 text-green">
          {{ price.finalPrice }}
        </div>
      </q-card-section>
      <q-card-section class="text-center">
        Reservation Fee to Pay
        <p class="text-h5 text-green">{{ price.reservationFee }}</p>
        Remaining Balance Upon Pickup
        <p class="text-h6">{{ price.operatorsFee }}</p>
      </q-card-section>
      <q-card-section class="q-pt-none q-mt-md">
        <q-input
          v-model="destination"
          outlined
          dense
          placeholder="Where you will take this vehicle?"
          :error="isDestinationError"
          error-message="Please enter your destination."
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          label="Close"
          flat
          icon="clear"
          @click="showDestination = false"
          v-close-popup
        />
        <q-btn
          label="Book Now"
          color="primary"
          icon="check_circle_outline"
          @click="createBooking()"
          :disable="!isAvailable"
        />
      </q-card-actions>
    </q-card>
    <q-card v-if="isOnFinal" class="column full-width justify-center items-center content-center">
      <q-card-section class="text-center">
        <q-spinner
          color="red"
          size="3em"
          :thickness="4"
          class="q-mb-lg"
        />
        <p class="text-h6 text-bold">Processing Booking..</p>
        <p>Please wait while we process your booking.</p>
      </q-card-section>
    </q-card>
  </q-dialog>
  <div class="car-map">
    <div class="q-pb-md text-subtitle2 text-accent">
      Pickup Address
    </div>
    <div class="q-pb-md q-pl-sm text-subtitle1">
      <q-icon name="location_on" color="primary" size="18px" />
      {{ vehicle.pickup_address }}
    </div>
    <iframe
      :src="
        'https://maps.google.com/maps?q=' +
        vehicle.location_latitude +
        ',' +
        vehicle.location_longhitude +
        '&hl=es;z=14&amp;output=embed'
      "
      width="100%"
      height="400px"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
  </section>
</template>
<script setup>
import moment from "moment";
import { computePrice } from "../../utils/pricing";
import { useStore } from "vuex";
import { filterDate } from "../../utils/filterData";
import { defineProps, computed, ref } from "vue";
import { notify } from "../../utils/notification";
import { useRouter } from "vue-router";
import { useQuasar } from "quasar";
import { getReturnDateTime } from "@/utils/searchQuery";
import vehicleJson from "@/assets/json/vehicle.json";

const store = useStore();
const router = useRouter();
const user = store.state.user.userData;
const isLoading = ref(false);
const isCheckingAvailability = ref(true);
const isAvailable = ref(true);
const props = defineProps(["booking_details", "vehicle", "isView"]);
const vehicle = computed(() => store.state.vehicle.vehicleData);
const showDestination = ref(false);
const destination = ref();
const isDestinationError = ref(false);
const isOnFinal = ref(false);
const $q = useQuasar();
const availabilityMessage = ref('This vehicle is no longer available on your selected date ranges. Please set another date or select another vehicle.');
const query = computed(() => store.state.vehicle.vehicleSearchQuery);
const price = computed(() =>
  computePrice(
    vehicle.value.type,
    props.booking_details.pickupdate,
    props.booking_details.pickupTime,
    props.booking_details.returndate,
    props.booking_details.returnTime
  )
);
const quick_selection_duration = ref(query.value.duration);
const quick_selection_duration_options = ref(
  vehicleJson.quick_selection_duration_options
);
const pickupDateTime = query.value.pickupdate + " " + query.value.pickupTime;
const returnDateTime = query.value.returndate + " " + query.value.returnTime;

const pickupdate = ref(filterDate(pickupDateTime));
const returndate = ref(filterDate(returnDateTime));

const pickupdateModal = ref(
  moment(query.value.pickupdate, "YYYY-MM-DD").format("YYYY-MM-DD")
);
const pickupTimeModal = ref(
  moment(query.value.pickupTime, "hh:mm:a").format("hh:mm a")
);

function updatePickupDate(date) {
  if (date) {
    store.commit("vehicle/setSearchPickupDate", date);
    if (quick_selection_duration.value) {
      getReturnDateTime(quick_selection_duration.value);
    }
    pickupdate.value = filterDate(query.value.pickupdate + " " + query.value.pickupTime)
    returndate.value = filterDate(query.value.returndate + " " + query.value.returnTime)
    checkAvailability();
  }
}

function updatePickupTime(time) {
  if (time) {
    time = moment(time, "hh:mm a").format("hh:mm a");
    store.commit("vehicle/setSearchPickupTime", time);
    if (quick_selection_duration.value) {
      getReturnDateTime(quick_selection_duration.value);
    }
    pickupdate.value = filterDate(query.value.pickupdate + " " + query.value.pickupTime)
    returndate.value = filterDate(query.value.returndate + " " + query.value.returnTime)
    checkAvailability();
  }
}

function updateDuration(val) {
  if (val) {
    getReturnDateTime(val);
    pickupdate.value = filterDate(query.value.pickupdate + " " + query.value.pickupTime)
    returndate.value = filterDate(query.value.returndate + " " + query.value.returnTime)
    checkAvailability();
  }
}

function createBooking() {
  // Encourage users to create their account or sign in
  if (!user && !store.state.isLoggedIn) {
    notify("warning", "Please create an account before booking or sign in your existing account if you already have one.", "", 6000);
    isLoading.value = false;
    router.push("/signup");

    return;
  }

  // Do not proceed if the destination is empty
  if (destination.value == '' || destination.value == undefined) {
    isDestinationError.value = true;
    return;
  }

  // Make the book now button on loading
  isLoading.value = true;

  // Show the Processing Booking dialog
  isOnFinal.value = true;

  // Prepare the booking payload
  const payload = {
    user_id: store.state.user.userData.id,
    vehicle_id: vehicle.value.id,
    destination: destination.value,
    pickup_date: filterDate(pickupDateTime, "YYYY-MM-DD HH:mm"),
    estimated_return_date: filterDate(returnDateTime, "YYYY-MM-DD HH:mm"),
    location: vehicle.value.address,
  };

  store.dispatch("booking/createBooking", payload)
    .then((response) => {
      if (response.status == "success") {
        notify(
          "positive",
          "Your booking application has been accepted and ready for reservation fee payment."
        );
        router.push({
          name: "Booking",
          params: { id: response.id },
        });
      }
    })
    .catch((err) => {
      if (err.response.status === 400) {
        isLoading.value = false;
        isOnFinal.value = false;
        showDestination.value = false;
        let message = '';
        switch (err.response.data.status) {
          case 'vehicle_not_available':
            message = 'It seems that this vehicle is no longer available on your selected dates. Please select another vehicle.';
            break;
          case 'vehicle_unavailable':
            message = 'This vehicle is no longer available for bookings. Please select another vehicle.';
            break;
        }
        $q.dialog({
          title: 'Vehicle Unavailable',
          message: message
        }).onOk(() => {
          router.push('/search');
        }).onDismiss(() => {
          router.push('/search');
        });
      }
      console.log(err);
    });
}

/**
 * Calls the API to check the vehicles availability by passing the pickup date and return date.
 */
function checkAvailability() {
  store.dispatch('vehicle/checkAvailability', vehicle.value.id).then((res) => {
    if (res.status == 'available') {
      isAvailable.value = true;
    } else if (res.status == 'not_available') {
      isAvailable.value = false;
      availabilityMessage.value = 'This vehicle is no longer available on your selected date ranges. Please set another date or select another vehicle.';
    } else {
      isAvailable.value = false;
      availabilityMessage.value = 'This vehicle is temporarily unavailable at the moment.';
    }
  }).catch((err) => {
    console.error(err);
  }).finally(() => {
    isCheckingAvailability.value = false;
  });
}

checkAvailability();
</script>
<style scoped>
.right-content .title::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: red;
  left: 0;
  bottom: 0;
}
.bottom-note {
  background-color: rgb(241, 239, 239);
  padding: 20px;
  margin: 10px;
}
.title[data-v-4880e8ec] {
  position: relative;
  margin: 20px 15px 10px;
  padding: 10px 0 0;
  width: max-content;
}
.destinationModal {
  width: 100%;
  max-width: 300px;
}
.car-header-subicons {
  display: flex;
  align-items: start;
  gap: 40px;
  justify-content: center;
}
</style>
