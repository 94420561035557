<template>
  <div class="q-pa-md q-gutter-sm">
    <q-dialog
      v-model="isShown"
      persistent
      :maximized="true"
    >
      <q-card class="column bg-white text-black full-height full-width justify-center items-center content-center">
        <q-card-section class="text-center">
            <div class="q-py-md">
                <q-img
                    src="@/assets/images/carbnb-logo-transparent.webp"
                    style="max-width: 200px"
                />
            </div>
            <p class="text-subtitle2">An update version <span class="text-accent">{{ currentVersion }}</span> is available for download.<br>Please update your app to continue.</p>
            <p>{{errorMessage}}</p>
        </q-card-section>
        <q-card-footer class="text-center">
            <a v-if="isAndroid" :href="playStoreLink">
                <q-img
                    src="@/assets/images/badge-play-store.png"
                    style="min-width: 150px"
                />
            </a>
            <a v-else :href="appStoreLink">
                <q-img
                    src="@/assets/images/badge-app-store.jpg"
                    style="min-width: 150px"
                />
            </a>
        </q-card-footer>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
export default {
   created() {
    let platform = navigator?.userAgent?.platform || navigator?.platform || 'unknown';
    if (platform != 'unknown') {
        if (platform === 'iPad' || platform === 'iPhone' || platform === 'iPod') {
            this.isAndroid = false;
        }
    } else {
        const userAgent = navigator.userAgent;
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            this.isAndroid = false;
        }
    }
   },
   props: {
    shown: {
        type: Boolean,
        default: false
    },
   },
   data() {
    return {
        isAndroid: true
    }
   },
   computed: {
    isShown() {
        return this.shown;
    },
    playStoreLink() {
        return localStorage.getItem('play_store');
    },
    appStoreLink() {
        return localStorage.getItem('app_store');
    },
    currentVersion() {
        return localStorage.getItem('required_version');
    }
   }
}
</script>