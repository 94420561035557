<template>
  <section class="footer">
    <div class="footer-flex">
      <div class="footer-col">
        <img
          src="@/assets/images/carbnb-logo-transparent-white.webp"
          alt="Carbnb Logo"
        />
        <span><q-icon name="email" /> <a href="mailto:support@carbnbrentals.com" class="text-white text-caption">support@carbnbrentals.com</a></span>
        <span><q-icon name="phone" /> <a href="tel:+0285361446" class="text-white text-caption">Landline: 02 (8288-7096)</a></span>
        <span><q-icon name="phone_iphone" /> <a href="tel:+639276400108" class="text-white text-caption">Mobile Number: +63 927 640 0108</a></span>
      </div>
      <div class="footer-col">
        <label><q-icon name="location_on"/> Main Office</label>
        <q-separator class="q-mb-sm q-mr-md" color="white" />
        <span class="text-white text-caption"> 
        11F Insular Life Building,
        <br />6781 Ayala Avenue 1209, Makati City
        </span>
      </div>
      <div class="footer-col">
        <label><q-icon name="location_on"/> Carbnb Taguig City Yard</label>
        <q-separator class="q-mb-sm q-mr-md" color="white" />
        <span class="text-white text-caption">101 E. Aguinaldo Ave., AFPOVAI<br>Taguig City, Metro Manila
        </span>
      </div>
      <div class="footer-col">
        <label><q-icon name="location_on"/> Carbnb Quezon City Yard</label>
        <q-separator class="q-mb-sm q-mr-md" color="white" />
        <span class="text-white text-caption">52 Samonte, Novaliches<br>Quezon City, Metro Manila
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  background: var(--color-secondary);
  padding: 50px;
  color: var(--color-white);
}
.footer .footer-flex {
  display: flex;
  gap: 5px;
  width: 90%;
  max-width: 1200px;
  justify-content: center;
  margin: auto;
}
.footer .footer-col {
  width: 33%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
  gap: 10px;
  max-width: 300px;
}
.footer .footer-col span {
  width: max-content;
  color: rgb(204, 204, 204);
}
.footer .footer-col span i {
  margin: 0 10px 0 0;
}
.footer .footer-col img {
  width: 100%;
  max-width: 200px;
}
.footer-col label {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
}
.footer-q-links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: max-content;
  grid-gap: 10px 20px;
}
.footer-q-links a {
  color: rgb(204, 204, 204);
}
.footer-q-links a:hover {
  color: var(--color-white);
}
.footer .social-container {
  display: flex;
  gap: 20px;
}
.footer .social-container a {
  color: var(--color-white);
  font-size: 20px;
}
</style>
