<template>
  <div class="container profileDashboard">
    <section class="full-width text-center">
      <div class="absolute-center">
        <div class="flex column gap-20 items-center">
          <span class="text-h2 text-bold">
            <q-icon color="red" name="error" /> Ooops!</span
          >
          <span
            >It seems your booking payment was failed to authorized. Please try
            again.</span
          >
        </div>
      </div>
    </section>
  </div>
</template>
<script setup></script>
<style scoped>
@media screen and (max-width: 991px) {
  .container.profileDashboard {
    display: flex !important;
  }
}
</style>
