<template>
  <section>
    <div class="container">
        <div class="text-center">
            <h5 class="text-accent">Our Features and Participations</h5>
            <p>Check out our notable features and participations.</p>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 q-pa-md">
                <q-card flat style="min-height: 700px">
                    <q-img 
                        :src="require('@/assets/images/features/carbnb-startup-showcase-pdi-2023.jpg')"
                        alt="Carbnb featured in Philippine Digital Innovation 2023 by Chartered Discipline Events"
                        class="red-strip-bg full-width"
                    >
                    </q-img>
                    <q-card-section>
                    <h6 class="text-accent">Philippine Digital Innovation 2023</h6>
                    By <b>Chartered Discipline Events</b>
                    <br>
                    <small>The rapid development of digital technologies has revolutionized almost every aspect of modern life, enhancing global competition, trade, public services, and communication. Recognizing the need to address the transformative effects of digitali..,</small>
                    </q-card-section>
                    <q-card-actions align="center">
                        <a href="https://www.bworldonline.com/spotlight/2023/06/22/529887/philippine-digital-innovation-2023-and-its-partners/" target="_blank">Read More</a>
                    </q-card-actions>
                </q-card>
            </div>
            <div class="col-12 col-md-4 q-pa-md">
                <q-card flat style="min-height: 700px">
                    <q-img 
                        :src="require('@/assets/images/features/carbnb-startup-showcase-qbo.png')"
                        alt="Carbnb featured in Philippine Digital Innovation 2023 by Chartered Discipline Events"
                        class="red-strip-bg"
                    >
                    </q-img>

                    <q-card-section>
                    <h6 class="text-accent">Top 100 Startups</h6>
                    By <b>QBO</b>
                    <br>
                    <small>Catch Top 100 Startup Showcase by QBO and get to know the hottest high potential and fast-growing Filipino startups to watch in 2022 from across industries—HealthTech, AI, FinTech, AgriTech, EdTech, CleanTech, B2B Software, and Big Data, among others! </small>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-12 col-md-4 q-pa-md">
                <q-card flat style="min-height: 700px">
                    <q-img 
                        :src="require('@/assets/images/features/carbnb-business-mirror.png')"
                        alt="How Carbnb is revolutionizing how we think about cars: Car sharing"
                        class="red-strip-bg full-width"
                    >
                    </q-img>

                    <q-card-section>
                    <h6 class="text-accent">How Carbnb is revolutionizing how we think about cars.</h6>
                    By <b>Business Mirror</b>
                    <br>
                    <small>What if there were a more environmentally friendly and cost-effective alternative to traditional car ownership in the Philippines? CarBnB is a car-sharing platform that allows car owners to rent out their vehicles to others for short periods of time. This novel approach to car ownership is altering Filipinos’ perceptions of automobiles, making them more affordable and environmental..</small>
                    </q-card-section>
                    <q-card-actions align="center">
                        <a href="https://businessmirror.com.ph/2023/03/12/how-carbnb-is-revolutionizing-how-we-think-about-cars-car-sharing/" target="_blank">Read More</a>
                    </q-card-actions>
                </q-card>
            </div>                        
        </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>