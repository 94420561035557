<template>
  <div class="container profileDashboard">
    <ProfileSidebar />

    <section>
      <h5>Rent Approvals</h5>
      <q-separator class="q-my-md" />
      <div v-if="forApprovals">
        <BookingApprovalItem  v-for="(booking, index) in forApprovalOnly"
          :key="index"
          :booking="booking" />
        
      </div>
      <span v-else>No current bookings need approval.</span>

      <h6>Active Rentals:</h6>
      <BookingApprovalItem  v-for="(booking, index) in approveRentals"
          :key="index"
          :booking="booking" />
    </section>
  </div>
</template>
<script setup>
import ProfileSidebar from "../components/Profile/ProfileSidebar.vue";
import BookingApprovalItem from "../components/Bookings/BookingApprovalItem.vue";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
const forApprovals = computed(() => store.state.booking.bookingsByOperator);

const forApprovalOnly = forApprovals.value.filter(item => item.status == 1)
const approveRentals = forApprovals.value.filter(item => item.status == 2 || item.status == 3 || item.status == 4)


function loadApprovals() {
  store.dispatch("booking/fetchBookingsFromOperator", store.state.user.userData.id);
}
loadApprovals();
</script>

