<template>
  <div class="maintenance-view text-center">
    <q-img
      src="@/assets/images/carbnb-logo-transparent.webp"
      class="logo"
      width="200px"
    />
    <div class="absolute-center">
      <div class="flex column gap-20 items-center">
        <span class="text-h3 text-black text-bold title">
          <q-icon color="red" name="error" /> Hi there!</span
        >
        <span
          >We currently doing maintenance on our self-booking website. However, you can still book a vehicle by going to our Facebook page.</span
        >
      </div>
    </div>
  </div>
</template>
<style scoped>
.maintenance-view {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 999999999999999;
  background-color: white;
}
@media screen and (max-width: 480px) {
  .title {
    width: max-content;
  }
}
</style>
