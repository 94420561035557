<template>
  <q-header>
    <nav>
      <div class="flex items-center">
        <router-link to="/">
          <q-img
            src="@/assets/images/carbnb-logo-transparent.webp"
            class="logo"
          />
        </router-link>
        <ul class="nav-links">
          <li>
            <q-btn
              color="black"
              label="Book Now"
              icon="directions_car"
              to="/search"
              flat
              size="md"
            />
          </li>
          <li>
            <q-btn
              color="black"
              label="Apply as Operator"
              icon="edit_note"
              to="/apply-as-operator"
              flat
              size="md"
            />
          </li>
          <li>
            <q-btn
              color="black"
              label="Contact Us"
              icon="call"
              to="/contact"
              flat
              size="md"
            />
          </li>
          <li>
            <q-btn 
              color="black"
              label="Others"
              icon="menu"
              flat
              size="md"
            >
              <q-menu>
                <q-list style="min-width: 100px">
                  <q-item clickable to="/our-policy" v-close-popup>
                    <q-item-section>Privacy Policy</q-item-section>
                  </q-item>
                  <q-item clickable to="/about-us" v-close-popup>
                    <q-item-section>About Us</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </li>
        </ul>
      </div>

      <div class="sign-button" v-if="isLoggedin == false">
        <li><router-link to="/login" @click="updateActiveLink('')"><q-icon name="login" /> Sign In</router-link></li>
        <li><router-link to="/signup" @click="updateActiveLink('')"><q-icon name="add" /> Create Account</router-link></li>
      </div>
      <div class="nav-profile" v-else>
        <li class="flex items-center">
          <!-- <router-link :to="'/user/' + user.id"> -->
          <q-badge color="blue" v-if="user.type == 2">
            Operator
          </q-badge>
          <span class="text-black q-ml-sm text-weight-medium">{{
            user.first_name ?? "Unknown User"
          }}</span>
          <q-avatar size="33px" class="q-mx-sm">
            <q-img
              :src="
                user.profile_image_id
                  ? user.profile_image_id.file
                  : require('@/assets/images/default_profile.jpg')
              "
            />
          </q-avatar>
          <!-- </router-link> -->
        </li>
        <li>
          <q-icon name="menu" size="30px" color="black" class="cursor-pointer">
            <q-menu>
              <q-list style="min-width: 200px">
                <!-- <q-item clickable v-close-popup :to="'/user/' + user.id">
                  <q-item-section>Profile</q-item-section>
                </q-item> -->
                <q-separator />
                <q-item clickable v-close-popup to="/bookings"  @click="updateActiveLink('')">
                  <q-item-section>Bookings</q-item-section>
                </q-item>
                <q-item clickable v-close-popup to="/dashboard" @click="updateActiveLink('')">
                  <q-item-section>Dashboard</q-item-section>
                </q-item>
                <q-item clickable v-close-popup to="/settings" @click="updateActiveLink('')">
                  <q-item-section>Settings</q-item-section>
                </q-item>
                <q-item clickable v-close-popup to="/contact" @click="updateActiveLink('')">
                  <q-item-section>Contact Us</q-item-section>
                </q-item>
                <q-separator />
                <q-item clickable @click="logout(); updateActiveLink('')" >
                  <q-item-section>Sign Out</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-icon>
        </li>
      </div>
    </nav>
  </q-header>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

function logout() {
  store.dispatch("logout").then((response) => {
    store.commit("user/logout");
    store.commit("booking/logout");
    if (response == "logged_out") {
      router.push("/login");
    }
  });
}

const isLoggedin = computed(() => store.state.isLoggedIn);
const user = computed(() => store.state.user.userData);
const activeLink = ref('Home');

function updateActiveLink(link) {
  activeLink.value  = link ?? '';
}
</script>

<style scoped>
.header-nav li,
a {
  font-weight: 500;
  font-size: 16px;
  color: var(--color-secondary);
  text-decoration: none;
}

.logo {
  cursor: pointer;
  height: 43px;
  width: 120px;
}
.q-menu a {
  font-weight: inherit;
  font-size: inherit;
}

header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 3%;
  border-bottom: 0.1rem solid #ddd;
  background: var(--color-white) !important;
}

.sign-button,
.nav-links {
  list-style: none;
}

.sign-button li,
.nav-profile li {
  display: inline-block;
  padding: 0px 15px;
}

.nav-links li {
  display: inline-block;
  padding: 0 20px;
}

.sign-button a:hover,
.nav-links li a:hover {
  color: var(--color-primary);
}
</style>
