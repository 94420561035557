<template lang="">
  <HomeSearch class="desktop-only"/>
  <section class="hp-intro">
    <q-carousel
      animated
      v-model="slide"
      arrows
      navigation
      infinite
      autoplay
      class="home-banner-slidedr"
      swipeable
    >
      <q-carousel-slide :name="1" class="bg-size-cover" :img-src="require('@/assets/images/banners/banner4.jpg')" />
      <q-carousel-slide :name="2" class="bg-size-cover" :img-src="require('@/assets/images/banners/banner5.jpg')" />
    </q-carousel>
  </section>
  <HomeSearchMobile class="desktop-hide" />
</template>
<script setup>
import { ref } from 'vue'
import HomeSearch from "../../components/Home/HomeSearch.vue";
import HomeSearchMobile from "../../components/Home/HomeSearchMobile.vue";
const slide = ref(1)

</script>
<style scoped>
section.hp-intro {
    height: auto;
}
  .hp-intro img.red-strip-bg{
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
  }
  .hp-intro .floating-slogan {
    font-weight: var(--font-bold);
    font-size: 69px;
    text-transform: uppercase;
    color: var(--color-secondary);
    position: absolute;
    top: 63px;
    left: 36px;
    line-height: 1.1;
  }
  .hp-intro .floating-slogan span {
    display: block;
    background: var(--color-primary);
    color: var(--color-white);
    font-size: 29px;
    padding: 10px 35px 10px 20px;
    width: max-content;
    border-radius: 0 30px 30px 0;
  }
  .hp-intro img.intro-car-featured {
    position: absolute;
    width: 64%;
    top: 67px;
    right: 0;
  }
  .home-banner-slidedr{
    height: 700px;
  }
</style>