import script from "./ConfirmPayment.vue?vue&type=script&setup=true&lang=js"
export * from "./ConfirmPayment.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpinner,QBtn});
