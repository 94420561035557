import moment from "moment/moment";
import vehicle from "../assets/json/vehicle.json";
/**
 * @param {string} type
 * @param {int} startdate
 * @param {int} enddate
 */
export function computePrice(
  type,
  startdate,
  startTime,
  enddate,
  endTime,
  fullFormat = false
) {
  if (!startdate) {
    console.log("Cant find start date");
    return;
  }
  if (!enddate) {
    console.log("Cant find end date");
    return;
  }
  if (!type) {
    console.log("Cant vehicle type.");
    return;
  }
  if (fullFormat) {
    startdate = moment(startdate).format("YYYY-MM-DD");
    enddate = moment(enddate).format("YYYY-MM-DD");
    startTime = moment(startdate).format("hh:mm a");
    endTime = moment(enddate).format("hh:mm a");
  }

  const selected_type = vehicle.vehicle_pricing[type];

  // Get the duration and return an object of days and module
  const duration = getDuration(startdate, startTime, enddate, endTime);

  // Get the base price
  var base_price = duration.days < 1
    ? selected_type.half
    : selected_type.full;

  // Get the operator insurance share. The insurance share is the
  // deduction from their operator fee that will go to the insurance
  // program
  var operatorShare = selected_type.op_share;

  // After getting the base price and the insurance we will pass it
  // to the computeOperatorsFee.
  const operatorsFee = computeOperatorsFee(
    base_price,
    duration,
    operatorShare
  );

  // Get the insurance because we will add this to the operator_fee + 599 + insurance.
  var insurance = selected_type.insurance;

  // Get the carwash fee.
  const carwashFee = selected_type.carwash_fee;

  // Compute the subtotal
  const subtotalPrice = computeSubtotalPrice(
    base_price,
    duration,
    operatorShare,
    insurance
  );

  const reservationFee = computeReservationFee(subtotalPrice, operatorsFee, carwashFee);
  const finalPrice = computeFinalPrice(reservationFee, operatorsFee);

  const insuranceAndOtherCharges = computeInsuranceAndOtherCharges(
    base_price,
    duration,
    finalPrice,
    carwashFee
  );

  // console.log("=========================================");
  // console.log("Operators Fee: " + operatorsFee);
  // console.log("Subtotal Fee: " + subtotalPrice);
  // console.log("Reservation Fee: " + reservationFee);
  // console.log("Total Fee: " + finalPrice);
  // console.log("Operator Share: " + operatorShare);
  // console.log("Duration: " + JSON.stringify(duration));
  // console.log("Charges: " + insuranceAndOtherCharges);
  // console.log("=========================================");
  // console.log(carwashFee);
  const durationHours = getDurationHours(
    startdate,
    startTime,
    enddate,
    endTime
  );
  return {
    duration: durationHours,
    operatorsFee: formatMoney(operatorsFee.toFixed(2)),
    reservationFee: formatMoney(reservationFee),
    finalPrice: formatMoney(finalPrice.toFixed(2)),
    insuranceAndOtherCharges: formatMoney(insuranceAndOtherCharges)
  };
}

export function getDuration(startdate, startTime, enddate, endTime) {
  enddate = enddate.replaceAll("-","/")
  startdate = startdate.replaceAll("-","/")
  var dur = moment(enddate + " " + endTime).diff(
    moment(startdate + " " + startTime),
    "hours"
  );
  const duration = {
    days: Math.floor(dur / 24),
    modulo: dur % 24,
  };
  if (duration.modulo > 12) {
    duration.days++;
  }
  // console.log(duration) 
  return duration;
}

export function getDurationHours(startdate, startTime, enddate, endTime) {
  return moment(enddate + " " + endTime).diff(
    moment(startdate + " " + startTime),
    "hours"
  );
}

/**
 * Get the operator fee plus 599 plus insurance multiplied by
 * how many days and add the 5% to it.
 *
 * @param { Number } price 
 * @param { Number } duration 
 * @param { Number } operatorShare 
 * @param { Number } insurance 
 * @returns { Number }
 */
export function computeSubtotalPrice(price, duration, operatorShare, insurance) {
  const multiplier = duration.days != 0 ? duration.days : 1;
  const subTotal = price + (insurance - operatorShare);

  return addPercentage(subTotal * multiplier).toFixed(2);
}

/**
 * Adds a 5 percent to the overall price.
 *
 * @param { Number } price 
 * @returns 
 */
export function addPercentage(price) {
  var val = price * 0.05;
  return price + val;
}

/**
 * Gets the operator fee for the computation.
 *
 * @param { Number } price 
 * @param { Number } duration
 * @param { Number } operatorShare
 * @returns Number
 */
export function computeOperatorsFee(price, duration, operatorShare) {
  const multiplier = duration.days != 0 ? duration.days : 1;
  // console.log(price - operatorShare);
  return ((price - operatorShare) - 599) * multiplier;
}

/**
 * Compute the reservation fee.
 *
 * @param { Number } subtotal 
 * @param { Number } operatorsFee
 * @param { Number } carwashFee
 * @returns 
 */
export function computeReservationFee(subtotal, operatorsFee, carwashFee) {
  const x = subtotal - operatorsFee;

  return addPercentage(x + carwashFee).toFixed(2);
}

/**
 * Compute the final price.
 *
 * @param { Number } reservationFee 
 * @param { Number } operatorsFee 
 * @returns 
 */
export function computeFinalPrice(reservationFee, operatorsFee) {
  return parseFloat(reservationFee) + parseFloat(operatorsFee);
}

/**
 * Gets the insurance and other charges.
 *
 * @param { Number } basePrice 
 * @param { Number } duration 
 * @param { Number } totalPrice 
 */
export function computeInsuranceAndOtherCharges(basePrice, duration, totalPrice) {
  const rentalFee = basePrice * (duration.days != 0 ? duration.days : 1);
  const insuranceCharges = totalPrice - rentalFee;

  return parseFloat(insuranceCharges).toFixed(2);
}

/**
 * @param {int} price
 */
export function formatMoney(price) {
  return "PHP " + price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}