<template>
  <div class="sidebar desktop-only">
    <section class="side-column q-pa-md full-width">
      <div class="profile">
        <q-avatar class="q-pl-sm" style="width: 40px; height: 40px">
          <q-img
            :src="user.profile_image_id ? user.profile_image_id.file : require('@/assets/images/default_profile.jpg')"
          />
        </q-avatar>
        <div class="q-pl-lg text-h6 text-black">{{ fullname }}</div>
        <q-icon
          class="q-pl-md q-pr-sm text-h6"
          name="fa-solid fa-circle-check"
        /></div>
      <div class="text-center">
        <q-badge color="blue" v-if="user.type == 2">
        Operator
        </q-badge>
      </div>
      <div class="divider"></div>
      <div
        class="q-pt-sm q-pb-sm q-pl-md text-subtitle1 text-uppercase text-weight-bold"
      >
        rentals
      </div>

      <div class="q-pt-sm q-pb-sm q-pl-md btn-tab">
        <router-link to="/bookings" class="flex items-center">
          <q-icon name="fa-solid fa-book" />
          <span class="q-px-md text-subtitle1 text-weight-medium text-black"
            >Bookings</span
          ><q-badge color="red" rounded v-if="bookingCount">{{ bookingCount.length }}</q-badge></router-link>
      </div>
       <div class="q-pt-sm q-pb-sm q-pl-md btn-tab" v-if="user.type == 2">
        <router-link to="/my-vehicles">
          <q-icon name="fa-solid fa-car-rear" />
          <span class="q-pl-md text-subtitle1 text-weight-medium text-black"
            >My Vehicles</span
          ></router-link>
      </div>

      <div
        class="q-pt-sm q-pb-sm q-pl-md text-subtitle1 text-uppercase text-weight-bold"
      >
        account
      </div>

      <div class="q-pt-sm q-pb-sm q-pl-md btn-tab">
        <router-link to="/dashboard">
          <q-icon name="fa-solid fa-user" />
          <span class="q-pl-md text-subtitle1 text-weight-medium text-black"
            >Dashboard</span
          ></router-link>
      </div>

      <div class="q-pt-sm q-pb-sm q-pl-md btn-tab">
        <router-link to="/settings">
          <q-icon name="fa-solid fa-gear" />
          <span class="q-pl-md text-subtitle1 text-weight-medium text-black"
            >Settings</span
          ></router-link>
      </div>
    </section>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    return {
      fullname: computed(() => store.getters["user/getFullName"]),
      user: computed(() => store.state.user.userData),
      bookingCount: computed(() => store.state.booking.bookingsByUser),
      ApprovalCount: computed(() => store.state.booking.bookingsByOperator.filter(item => item.status == 1))
    };
  },
};
</script>
<style scoped>
.sidebar {
  width: 25%;
}
.side-column .profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.side-column .profile i {
  color: #289f28;
}
.side-column .divider {
  background: rgba(0, 0, 0, 0.2);
  width: auto;
  height: 1px;
  margin: 8px;
}
.btn-tab i {
  color: rgb(203, 46, 14);
  font-size: 1rem;
}
.btn-tab {
  display: block;
  color: var(--color-white);
  font-size: 16px;
}
.btn-tab:hover {
  background-color: #ddd;
}
</style>
