import moment from 'moment'
/**
 * @param {string} date
 * @param {string} format
 */
export function filterDate(date, format = "MMM DD YYYY hh:mm a"){
    date = date.replaceAll("-","/")
    return moment(new Date(date), format).format(format)
}

/**
 * @param {string} time
 * @param {string} format
 */
export function filterTime(time,format){
    return moment(time,format).format(format)
}
