import script from "./ControlPanelUpcomingBookings.vue?vue&type=script&setup=true&lang=js"
export * from "./ControlPanelUpcomingBookings.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';import Ripple from 'quasar/src/directives/Ripple.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpinner,QIcon,QBtn,QList,QItemLabel,QSeparator,QItem,QItemSection,QAvatar,QDialog,QCard,QCardSection,QSpace,QBanner});qInstall(script, 'directives', {Ripple,ClosePopup});
