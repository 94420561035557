/**
 * Defines the payment responses.
 */
export default {
    // Response when the card needs authentication (OTP, Auth, 3D)
    authenticationRequired: 'awaiting_next_action',
    // General response for success
    succeeded: 'succeeded',
    // General payment failed - card transaction
    paymentFailed: 'awaiting_payment_method',
    // Processing - card transaction
    processing: 'processing',
    // Pending - e-wallet when in need of authentication
    pending: 'pending',
    // When a payment source is created
    paymentSourceCreated: 'payment_source_created',
    // When a payment intent is created
    paymentIntentCreated: 'payment_intent_created',
    // When e-wallet source is authenticated and ready for deduction
    chargeable: 'chargeable',
    // When payment success - e-wallet
    paid: 'paid',
    // When the booking is already paid
    alreadyPaid: 'already_paid'
}