<template>
  <q-layout view="hhh LpR lfr">
    <metainfo>
      <template v-slot:title="{ content }">{{ content }} | Carbnb</template>
    </metainfo>
    <MaintenanceView v-if="maintenanceMode" />
    <Drawer
      :show="leftDrawerOpen"
      class="safe-area"
      @controlDrawer="toggleLeftDrawer"
      v-if="!maintenanceMode"
    />

    <q-header
      v-if="!maintenanceMode"
      elevated
      class="bg-white text-white desktop-hide fixed-top safe-area"
    >
      <q-toolbar>
        <q-btn class="text-primary" dense flat round icon="menu" @click="toggleLeftDrawer" />

        <q-toolbar-title>
          <router-link to="/">
            <q-img
              src="@/assets/images/carbnb-logo-transparent.webp"
              class="logo"
          /></router-link>
        </q-toolbar-title>
      </q-toolbar>
    </q-header>

    <q-page-container v-if="!maintenanceMode">
      <Header :isLoggedin="isLoggedin" class="desktop-only" />
      <ModalUpdateRequired :shown="showUpdateRequiredDialog"/>
      <router-view :key="$route.path" />
      <Footer v-if="this.$store.state.displayFooter" />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import Header from "@/layout/Header.vue";
import Footer from "@/layout/Footer.vue";
import axios from "axios";
import { notify } from "@/utils/notification";
import Drawer from "./layout/Drawer.vue";
import { useStore } from "vuex";
import MaintenanceView from "./components/Globals/MaintenanceView.vue";
import { initializeSearchQuery } from "@/utils/initializeSearchQuery";
import ModalUpdateRequired from './components/ModalUpdateRequired.vue';

export default {
  name: "LayoutDefault",
  components: {
    Header,
    Drawer,
    Footer,
    MaintenanceView,
    ModalUpdateRequired
  },

  setup() {
    const leftDrawerOpen = ref(false);
    const store = useStore();
    const maintenanceMode = false;
  
    return {
      leftDrawerOpen,
      maintenanceMode: maintenanceMode,
      isLoggedin: ref(false),
      toggleLeftDrawer() {
        store.commit("setToggleDrawer");
      },
    };
  },

  data() {
    return {
      showUpdateRequiredDialog: false
    }
  },

  methods: {
    getLocation() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          console.log(position.coords.latitude, position.coords.longitude);
        });
      } else {
        console.log("Please allow to use your location.");
      }
    },
  },

  // Initialize a search query recorrection every app creation.
  beforeCreate() {
    initializeSearchQuery(); 
  },

  /**
   * Main entry point of the application.
   */
  created() {
    // Clean up the required version keys and links on app create.
    localStorage.removeItem('required_version');
    localStorage.removeItem('app_store');
    localStorage.removeItem('play_store');

    let system = this;
    axios.interceptors.request.use(
      function (config) {
        const token = localStorage.getItem("user_loggedin_token");
        const user_id = localStorage.getItem("user_loggedin_id");
        const Auth = `Bearer ${user_id} ${token}`;
        config.headers.Authorization = Auth;

        // Include the platform on each request.
        config.headers['X-AppPlatform'] = process.env.VUE_APP_PLATFORM;

        // Include the version on each request.
        if (process.env.VUE_APP_PLATFORM == 'mobile') {
          config.headers['X-AppVer'] = btoa(process.env.VUE_APP_VERSION);
        }

        return config;
      },
      function (error) {
        console.error(
          "The Axios HTTP request has failed. Please see the logs for more details."
        );
        console.error(error);
        return Promise.reject(error);
      }
    );
  
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status == 400) {
          if (error.response.data.status == 'update_required') {
            console.error('You are using an outdated version of the app. The system requires you to update to use its services.');
            system.$store.commit("logout");
            system.$store.commit("user/logout");
            localStorage.setItem("required_version", error.response.data.data.required_version);
            localStorage.setItem("app_store", error.response.data.data.app_store);
            localStorage.setItem("play_store", error.response.data.data.play_store);
            system.showUpdateRequiredDialog = true;
          }
        }
        if (error.response.status == 403) {
          system.$store.commit("logout");
          system.$store.commit("user/logout");
          system.$router.push("/login");
  
          notify("warning", "Please sign in your account again to access this page.");
        }

        if (
          error.response.status == 500
        ) {
          if (
            error.response.data.data.message != "not found" &&
            error.response.data.data.message != "vehicle not found"
          ) {
            notify("red", error.response.data.data.message, "", 6000);
            return { status: error.response.data.status };
          }
        }
        return Promise.reject(error);
      }
    );
  },
};
</script>
<style scoped>
.logo {
  cursor: pointer;
  height: 43px;
  width: 120px;
}
</style>
