<template>
  <div class="full-width">
    <q-tabs
        v-model="tab"
        class="text-white bg-primary shadow-1"
        outside-arrows
        inline-label
        mobile-arrows
        :align="center"
        :breakpoint="991"
        shrink
        :active-color="'white'"
      >
        <q-tab name="Vehicle_Details" icon="list" label="Details" />
        <q-tab name="Active_Booking" icon="drive_eta" label="Active Booking" v-if="booking != null"/>
        <q-tab name="Upcoming_Bookings" icon="today" label="Upcoming Bookings" />
        <q-tab name="Closed_Bookings" icon="event_available" label="Closed Bookings" />
        <q-tab name="Blocked_Schedules" icon="calendar_month" label="Blocked Schedules" />
      </q-tabs>
      <div class="tab-content shadow-1 q-pa-sm">
        <ControlPanelActiveBooking v-on:bookingStop="activateClosedBookingsTab" v-if="tab == 'Active_Booking' && booking != null" :id="route.params.id"/>
        <ControlPanelUpcomingBookings v-on:bookingStart="activateActiveTab" v-if="tab == 'Upcoming_Bookings'" :id="route.params.id"/>
        <ControlPanelBlockedSchedules v-if="tab == 'Blocked_Schedules'" :id="route.params.id" />
        <ControlPanelVehicleDetails v-if="tab == 'Vehicle_Details'" :id="route.params.id" />
        <ControlPanelClosedBookings v-if="tab == 'Closed_Bookings'" :id="route.params.id" />
      </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useQuasar } from 'quasar'

import ControlPanelUpcomingBookings from '@/components/Operator/ControlPanel/ControlPanelUpcomingBookings.vue'
import ControlPanelBlockedSchedules from '@/components/Operator/ControlPanel/ControlPanelBlockedSchedules.vue'
import ControlPanelVehicleDetails from '@/components/Operator/ControlPanel/ControlPanelVehicleDetails.vue'
import ControlPanelActiveBooking from '@/components/Operator/ControlPanel/ControlPanelActiveBooking.vue'
import ControlPanelClosedBookings from '@/components/Operator/ControlPanel/ControlPanelClosedBookings.vue'

const store = useStore();
const route = useRoute();
const tab = ref('Vehicle_Details');
const $q = useQuasar();
const booking = computed(() => store.state.operator.vehicle.booking);

function activateActiveTab() { 
  $q.loading.show();
  store.dispatch("operator/getVehicle", route.params.id).finally(() => {
    booking.value = store.state.operator.vehicle.booking;
    tab.value = 'Active_Booking';
    $q.dialog({
      title: 'Booking Started',
      message: 'You have successfully started the booking. The booking is now moved to your Active Booking tab.'
    }).onOk(() => {
      // console.log('OK')
    }).onCancel(() => {
      // console.log('Cancel')
    }).onDismiss(() => {
      // console.log('I am triggered on both OK and Cancel')
    });
    $q.loading.hide();
  });
}
function activateClosedBookingsTab() {
  $q.loading.show();
  store.dispatch("operator/getVehicleClosedBookings", route.params.id).finally(() => {
    $q.loading.hide();
    tab.value = 'Closed_Bookings';
    store.commit("operator/removeActiveBooking");
  });
}
</script>