<template>
  <div style="height: 100vh">
    <div v-if="isLoading" class="text-center">
      <q-spinner
        color="primary"
        size="3em"
        class="full-width q-mt-xl"
      />
      <p class="q-mt-lg">Loading previous bookings..</p>
    </div>
    <div class="text-center q-mt-xl" v-if="!isLoading && closedBookings == null">
      <q-icon name="drive_eta" size="3em"/>
      <h6 class="text-accent">No Closed Bookings</h6>
      <p>This vehicle does not have any completed bookings yet. <br>Press the <q-icon name="sync_alt"/> Sync button below to update again.</p>
      <br><br>
      <q-btn
        color="primary"
        class="q-ml-md"
        icon="sync_alt"
        label="Sync"
        @click="loadUpcomingBookings"
      />
    </div>
    <div v-if="closedBookings != null">
    <h6>Closed Bookings</h6>
    <p>This page shows the past bookings of your vehicle.</p>
      <q-list bordered padding>
        <q-item>
            <q-item-section avatar>
            <q-icon color="primary" name="library_books" />
            </q-item-section>

            <q-item-section>
                <span class="text-accent">{{ closedBookings.length }}</span>
                <q-item-label>Total Closed Bookings</q-item-label>
            </q-item-section>
        </q-item>
        <q-item>
            <q-item-section avatar>
            <q-icon color="primary" name="payments" />
            </q-item-section>

            <q-item-section>
                <span class="text-positive">PHP {{ vehicle.total_earnings }}</span>
                <q-item-label>Total Earnings</q-item-label>
            </q-item-section>
        </q-item>
        <q-separator/>
          <q-item v-for="booking in closedBookings" v-bind:key="booking.id" v-ripple clickable @click="loadSelectedBooking(booking.id)">
            <q-item-section top avatar>
              <q-avatar rounded>
                <img :src="booking.client_photo">
              </q-avatar>
            </q-item-section>

            <q-item-section>
              <q-item-label>{{ booking.client_name }}</q-item-label>
              <q-item-label caption><q-icon name="location_on"/>{{ booking.destination }}</q-item-label>
              <q-item-label caption><b>{{ booking.pickup_date }}</b> until <b>{{ booking.estimated_return_date }}</b></q-item-label>
            </q-item-section>

            <q-item-section side top>
              <q-item-label caption>{{ booking.points }}</q-item-label>
            </q-item-section>
          </q-item>
      </q-list>
    </div>
    <q-dialog v-model="showSelectedBooking" persistent :maximized="$q.screen.lt.md">
      <q-card class="full-width">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">About Booking</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
          <div v-if="isLoadingBooking" class="text-center">
            <q-spinner
              color="primary"
              size="3em"
              class="q-mt-xl"
            />
            <p class="q-mt-lg">Retrieving booking details. Please wait..</p>
          </div>
          <div>
            <h6>Client Details</h6>
            <q-item>
              <q-item-section top avatar>
                <q-avatar rounded size="5em">
                  <img :src="selectedBooking.client_photo">
                </q-avatar>
              </q-item-section>

              <q-item-section>
                <q-item-label><h6 class="text-accent" style="margin: 0">{{ selectedBooking.customer_name}}</h6></q-item-label>
                <q-item-label caption><q-icon name="email"/> {{ selectedBooking.customer_email ? selectedBooking.customer_email : 'No email address.'  }}</q-item-label>
                <q-item-label caption><q-icon name="smartphone"/> {{ selectedBooking.customer_mobile_number ? selectedBooking.customer_mobile_number : 'No mobile number.'}}</q-item-label>
              </q-item-section>
            </q-item>
            <div>
              <h6>Booking Details</h6>
              <q-item>
                <q-item-section avatar>
                  <q-icon color="primary" name="location_on" />
                </q-item-section>

                <q-item-section>
                    <span class="text-accent">{{ selectedBooking.destination }}</span>
                    <q-item-label>Destination</q-item-label>
                    <q-item-label caption>The client's declared destination.</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section avatar>
                  <q-icon color="primary" name="today" />
                </q-item-section>

                <q-item-section>
                    <span class="text-accent">{{ selectedBooking.pickup_date }}</span>
                    <q-item-label>Pick Up Date</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section avatar>
                  <q-icon color="primary" name="event" />
                </q-item-section>

                <q-item-section>
                    <span class="text-accent">{{ selectedBooking.estimated_return_date }}</span>
                    <q-item-label>Return Date</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section avatar>
                  <q-icon color="primary" name="event_note" />
                </q-item-section>

                <q-item-section>
                    <span class="text-accent">{{ selectedBooking.points }}</span>
                    <q-item-label>Rental Length</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section avatar>
                  <q-icon color="primary" name="paid" />
                </q-item-section>

                <q-item-section>
                    <span class="text-positive">PHP {{ selectedBooking.operator_fee }} </span>
                    <q-item-label>Received Operator Fee</q-item-label>
                </q-item-section>
              </q-item>            
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
  import { ref, computed } from "vue";
  import { useStore } from "vuex";
  import { useRoute } from "vue-router";
  import { useQuasar } from "quasar";

  const $q = useQuasar();
  const store = useStore();
  const route = useRoute();

  const showSelectedBooking = ref(false);
  const isLoading = ref(true);
  const isLoadingBooking = ref(false);
  const closedBookings = computed(() => store.state.operator.closedBookings);
  const selectedBooking = computed(() => store.state.operator.booking);
  const vehicle = computed(() => store.state.operator.vehicle);

  function loadClosedBookings() {
    isLoading.value = true;
    store.dispatch("operator/getVehicleClosedBookings", route.params.id).then((r) => {
      if (r.status == "success") {
        isLoading.value = false;
      }
    });
  }

  function loadSelectedBooking(id) {
    isLoadingBooking.value = true;
    isLoading.value = false;
    store.dispatch("operator/getBooking", id).then((r) => {
      if (r.status == "success") {
        isLoading.value = false;
        isLoadingBooking.value = false;
        showSelectedBooking.value = true;
      }
    });
  }

  loadClosedBookings();
</script>