import Notify from 'quasar/src/plugins/Notify.js';
/**
 * @param {string} message
 * @param {string} color
 * @param {string} icon
 * @param {int} timeout
 */
export function notify(color, message, icon, timeout = 5000){
    Notify.create({
        color: color,
        message: message.replaceAll("_"," "),
        icon: icon,
        timeout: timeout
    })
}