<template>
  <div class="container profileDashboard">
    <section class="full-width" v-if="showPaymentIndex">
      <h5 class="text-accent">Let's settle your reservation fee!</h5>
      <p style="line-height: 1.75">Please check your booking details before proceeding for payment.<br>If everything is correct, select your payment method below.</p>
      <div class="flex items-center mobileFlexReverse">
        <div class="half-width left-boredered mobileFullWidth payment">
          <div class="flex q-px-md">
            <div class="payment-type" v-ripple @click="selectPaymentMethod('card')">
              <img :src="require(`@/assets/images/payment/visa.png`)" alt="" />
              <img
                :src="require(`@/assets/images/payment/master-card.webp`)"
                alt=""
              />
            </div>
            <div class="payment-type" v-ripple @click="selectPaymentMethod('gcash')">
              <img
                :src="require(`@/assets/images/payment/gcash-logo.png`)"
                alt=""
              />
            </div>
            <q-space />
            <span 
                class="text-bold q-mx-none q-mt-lg full-width">
                {{ paymentMethodTitle }}
            </span>
            <p>{{ paymentMethodDescription }}</p>

            <q-btn
                class="btn-primary"
                label="Proceed"
                icon-right="keyboard_arrow_right"
                @click="payNow"
                :loading="isLoading"
                :disabled="!hasSelectedPaymentMethod"
            />
            <q-btn
                class="btn-primary"
                label="Go Back"
                icon="keyboard_arrow_left"
                @click="closePaymentApp"
            />
          </div>
        </div>
        <div class="half-width q-pa-md mobileFullWidth payment">
          <span class="text-bold q-mx-none q-mt-lg"
            >Booking Details</span
          >
          <div class="booking-info">
            <span class="text-accent">Booking Code:</span>
            {{ booking.booking_code }}
          </div>
          <div class="booking-info">
            <span class="text-accent">Pickup Date:</span>
            {{ invoice.pickup_date }}
          </div>
          <div class="booking-info">
            <span class="text-accent">Return Date:</span>
            {{ invoice.estimated_return_date }}
          </div>
          <div class="booking-info">
            <span class="text-accent">Pickup Address:</span>
            <span class="text-right">{{ booking.address }}</span>
          </div>
          <div class="booking-info">
            <span class="text-accent">Vehicle:</span>
            {{ invoice.vehicle_brand}} {{ invoice.vehicle_name }} {{ invoice.vehicle_year }}
          </div>
          <span class="text-bold q-mx-none q-mt-lg"
            >Price Breakdown</span
          >
          <div class="booking-info">
            <span class="text-accent">Rate Per Day:</span>
            PHP {{ invoice.rate_per_day}}.00 x {{ invoice.duration }} day(s)
          </div>
          <div class="booking-info">
            <span class="text-accent">Insurance and other charges: </span>
            + PHP {{ invoice.insurance_and_charges }}
          </div>
          <span class="text-bold q-mx-none q-mt-lg"
            >Payment Breakdown</span
          >
          <div class="booking-info">
            <span class="text-accent">Reservation To Pay: </span>
            PHP {{ invoice.reservation_fee }}
          </div>
        <div class="booking-info">
            <span class="text-accent">Remaining Balance Upon Pickup:</span>
            PHP {{ invoice.operator_fee }}
          </div>
          <q-separator />
          <div class="booking-info">
            <span class="text-accent">Total Rental Fee</span>
            PHP {{ invoice.total_rental_fee }}
          </div>
        </div>
      </div>
    </section>
    <q-dialog
      v-model="showFinalProgressBar"
      persistent
    >
      <q-card>
        <q-card-section class="text-center">
          <q-spinner
            color="red"
            size="3em"
            :thickness="4"
            class="q-mb-lg"
          />
          <p class="text-h6 text-bold">Processing Payment..</p>
          <p>Please wait while we process your payment.</p>
          <small class="text-gray">Do not close this page.</small>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="showGCashAuthWindow"
      persistent
      :maximized="$q.screen.lt.md"
    >
      <q-card style="width: 600px; height: 700px; padding: 0">
        <q-card-actions align="right" class="bg-white text-primary">
          <q-btn flat v-close-popup round dense icon="close" />
        </q-card-actions>
        <q-card-section style="padding: 0">
          <iframe width="100%" height="600px" :src="gcashCheckoutUrl" style="border: 0"></iframe>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="showCardInputWindow"
      persistent
      :maximized="$q.screen.lt.md"
    >
      <q-card>
        <q-card-section>
          <PaymentCardInput @success="showSuccessDialog = true"/>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="showSuccessDialog"
      persistent
      :maximized="$q.screen.lt.md"
    >
      <q-card>
        <q-card-section>
          <PaymentSuccess/>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { computed, ref } from "vue";
import { useQuasar } from "quasar";

import paymentMethods from '../../utils/payment/paymentMethods';
import paymentResponses from "../../utils/payment/paymentResponses";
import PaymentCardInput from '@/components/Payment/PaymentCardInput';
import PaymentSuccess from '@/components/Payment/PaymentSuccess';

const $q = useQuasar();
const router = useRouter();
const store = useStore();
const route = useRoute();

const isLoading = ref(false)
const invoice = computed(() => store.state.payment.invoice);
const booking = computed(() => store.state.booking.bookingData);

const hasSelectedPaymentMethod = ref(false);
const paymentMethodTitle = ref('Select Payment Method');
const paymentMethodDescription = ref('Click your preferred payment method.');

const showGCashAuthWindow = ref(false);
const showCardInputWindow = ref(false);
const showPaymentIndex = ref(true);
const showSuccessDialog = ref(false);
const paymentMethod = ref('gcash');
const gcashCheckoutUrl = ref('');

const showFinalProgressBar = ref(false);

window.addEventListener('message', function capturer(ev) {
  if (ev.data === 'Gcash-auth-ok') {
    showGCashAuthWindow.value = false;
    showFinalProgressBar.value = true;
    gcashCheckoutUrl.value = '';
    checkSource();
    window.removeEventListener('message', capturer)
    return;
  }
  if (ev.data === '3DS-authentication-complete') {
    showCardInputWindow.value = false;
    showFinalProgressBar.value = true;

    // Perform final API call to process the booking.
    store.dispatch('payment/continue').then((response) => {
      switch (response.status) {
        case 'success':
          showSuccessDialog.value = true;
          break;
        case 'already_paid':
          $q.dialog({
            title: 'Payment Received',
            message: 'The payment for this booking has already been received.'
          }).onOk(() => {
            returnToBooking();
          })
          break;
      }
    }).catch(() => {
      $q.dialog({
        title: 'There was a mistake..',
        message: 'There has been an issue while trying to process your payment. Please select a different card or choose another payment method.'
      }).onOk(() => {
        returnToBooking();
      })
    }).finally(() => {
      showFinalProgressBar.value = false;
      window.removeEventListener('message', capturer)
    })
  }
});
// If there is no loaded invoice.
if (invoice.value == undefined) {
  router.push('/bookings');
}

// If the invoice and booking does not match.
if (invoice.value.booking_id != booking.value.id) {
  router.push('/bookings');
}

/**
 * Toggles the selected payment method based on the clicked
 * method of the user.
 */
function selectPaymentMethod(method) {
  if (method == 'gcash') {
      paymentMethod.value = paymentMethods.gcash;
      paymentMethodTitle.value = 'Pay with GCash';
      paymentMethodDescription.value = 'You choose to pay with GCash, click the Proceed button.';
  } else {
      paymentMethod.value = paymentMethods.card;
      paymentMethodTitle.value = 'Pay with Debit/Credit Card';
      paymentMethodDescription.value = 'You choose to pay with card, click the Proceed button.';
  }
  
  // Enables the proceed button
  hasSelectedPaymentMethod.value = true;
}

/**
 * Simply returns the user to their booking view.
 */
function closePaymentApp() {
  router.push({
    name: 'Booking',
    params: {
      booking_id: booking.value.id
    }
  });
}

/**
 * Executes payment process depending on the selected payment method of the user.
 */
function payNow() {
  // Make the proceed button loading.
  isLoading.value = true;
  // Perform a call to get the checkout URL
  store.dispatch('payment/initializePayment', paymentMethod.value)
  .then((response) => {
    if (response.status == paymentResponses.alreadyPaid) {
      $q.dialog({
        title: 'Payment Received',
        message: 'The payment for this booking has already been received.'
      }).onOk(() => {
        returnToBooking();
      })
      return;
    }
  
    // If the client is paying thru GCash
    if (paymentMethod.value == paymentMethods.gcash) {

      // If the payment method for Gcash is pending show the authorization screen.
      if (response.status === paymentResponses.pending) {
        // Set the iframe checkout url
        gcashCheckoutUrl.value = response.checkoutUrl;

        // Show the auth dialog (iframe)
        showGCashAuthWindow.value = true;
      }
    } else if (paymentMethod.value == paymentMethods.card) {
      showCardInputWindow.value = true;
    }
  }).catch(() => {
    //
  }).finally(() => {
    isLoading.value = false;
  })

}

/**
 * The payment app will check the source's status.
 */
function checkSource() {
  store.dispatch('payment/checkSource').then((response) => {
    if (response.status === paymentResponses.chargeable) {
      transact();
    }
    if (response.status === paymentResponses.pending) {
      setTimeout(() => {
        checkSource();
      }, 3000);
      return;
    }
    if (response.status === paymentResponses.paid || response.status === paymentResponses.alreadyPaid) {
      showSuccessDialog.value = true;
      showFinalProgressBar.value = false;
    }
  }).catch(() => {
    showFinalProgressBar.value = false;
    $q.dialog({
      title: 'Authorization Failed',
      message: 'The authorization process has failed. Please try again later.'
    }).onOk(() => {
      returnToBooking();
    }).onCancel(() => {
      returnToBooking();
    }).onDismiss(() => {
      returnToBooking();
    })
  });
}

/**
 * Triggers a fund transfer from a chargeable source (gcash).
 */
function transact()
{
  store.dispatch('payment/transact').then(() => {
    showFinalProgressBar.value = false;
    showSuccessDialog.value = true;
  }).catch(() => {
    $q.dialog({
      title: 'Cannot Proceed to Payment',
      message: 'There was an error while trying to process your payment. Please try again later.'
    }).onOk(() => {
      returnToBooking();
    }).onCancel(() => {
      returnToBooking();
    }).onDismiss(() => {
      returnToBooking();
    });
  });
}

/**
 * Returns the customer to their booking.
 */
function returnToBooking()
{
  router.push({
    name: 'Booking',
    params: {
      id: route.params.id
    }
  })
}

</script>
<style scoped>
.payment-type {
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  margin: 5px;
  padding: 0 15px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.disabled {
  cursor: none;
  pointer-events: none;
}
.payment-type.disabled:hover {
  transform: scale(1);
}
.payment-type:hover {
  transform: scale(1.02);
}
.payment-type img {
  max-width: 80px;
}
.left-boredered {
  border-right: 1px solid #e6d9d9;
}
.booking-info {
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
</style>