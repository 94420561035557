<template>
  <div>
    <q-card flat bordered class="q-ma-none q-pa-none" style="max-width: 100%" v-for="i in 5" :key="i">
      <q-item >
        <q-item-section >
          <div class="flex justify-between">
            <q-skeleton height="180px" width="30%" />
            <q-skeleton height="180px" width="68%" />
          </div>
        </q-item-section>
      </q-item>
    </q-card>
  </div>
</template>
