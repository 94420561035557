<template>
  <div class="container ip-cont">
    <q-form class="cb-form" @submit="resendRequest" v-if="state == 'email_require'">
      <div class="q-px-xl q-pb-xl mobileLessPadding">
        <div>
          <h5 class="full-width text-accent"><q-icon name="email"/> Email Verification</h5>
          <div class="q-pt-xs q-pb-md text-subtitle2 text-weight-regular">
            <p  class="q-my-none">
              Enter the email address of your account to receive a verification code.
            </p>
            <q-input
              v-model="email"
              outlined
              class="cb-input q-py-md"
              placeholder="Email Address"
              :disable="isLoading"
              :rules="[(val) => (val && val.length > 0) || 'Email address is required.']"
            />
          </div>
        </div>
        <q-btn
          class="btn-primary"
          type="submit"
          icon-right="send"
          label="Send"
          :loading="isLoading"
        />
      </div>
    </q-form>
    <q-form class="cb-form" @submit="sendVerificationCode" v-if="state == 'code_sent'">
      <div class="q-px-xl q-pb-xl mobileLessPadding">
        <div>
          <h5 class="full-width text-accent"><q-icon name="keyboard"/> Enter Code</h5>
          <div class="q-pt-xs q-pb-md text-subtitle2 text-weight-regular">
            <p  class="q-my-none">
              Enter the 6-digit code that was sent on <b>{{ email }}</b>.<br><br>
              If you do not receive an email after 5 minutes or more, please click Resend Code.
            </p>
            <q-input
              v-model="code"
              outlined
              class="cb-input q-py-md"
              placeholder="6-digit Code"
              maxlength="6"
              :disable="isLoading"
              :rules="[(val) => (val && val.length > 0) || 'Please enter the 6-digit code found on your email.']"
            />
          </div>
        </div>
        <q-btn
          class="btn-primary"
          type="submit"
          icon-right="send"
          label="Submit Code"
          :loading="isLoading"
        />
        <q-btn
          class="btn-primary"
          icon="restore"
          label="Change Email"
          :disable="isLoading"
          @click="changeEmail"
        />
          <div
            class="q-pt-sm text-center text-subtitle2 text-weight-regular"
          >
            Did not receive any code?
            <span v-if="countDown > 0">({{ countDown }})</span>
            <q-btn
              dense
              flat
              :disable="countDown > 0 ?? true"
              @click="resendRequest()"
              color="primary"
              class="q-py-none"
              >Resend Code</q-btn
            >
          </div>
      </div>
    </q-form>
    <q-form class="cb-form" @submit="finalizeVerification" v-if="state == 'input_password'">
      <div class="q-px-xl q-pb-xl mobileLessPadding">
        <div>
          <h5 class="full-width text-accent"><q-icon name="lock"/> Set Password</h5>
          <div class="q-pt-xs q-pb-md text-subtitle2 text-weight-regular">
            <p  class="q-my-none">
              Please create a password for your account.<br><br>
              For maximum security, we advise the following:
              <ol>
                <li>The password should have at least 8 characters.</li>
                <li>Use combinations of letters and numbers.</li>
                <li>Add special characters and at least 1 upper case.</li>
              </ol>
            </p>
            <q-input
              v-model="password"
              type="password"
              outlined
              class="cb-input q-py-md"
              placeholder="New Password"
              :disable="isLoading"
              :rules="[(val) => (val && val.length > 0) || 'Enter a password for your account.']"
            />
            <q-input
              v-model="cpassword"
              type="password"
              outlined
              class="cb-input q-py-md"
              placeholder="Repeat Password"
              :disable="isLoading"
              :rules="[(val) => (val && val.length > 0) || 'Please repeat the password you entered.']"
              :error="pworderr != ''"
              :error-message="pworderr"
            />
          </div>
        </div>
        <q-btn
          class="btn-primary"
          type="submit"
          icon="save"
          label="Save Password"
          :loading="isLoading"
        />
      </div>      
    </q-form>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { notify } from "@/utils/notification";
import { useRouter } from "vue-router";

const email = ref('');
const password = ref();
const cpassword = ref();
const pworderr = ref('');
const code = ref();
const state = ref('email_require');
const store = useStore();
const router = useRouter();
const isLoading = ref(false);

var countDown = ref(0);

// Check if an active email to verified is stored.
if (localStorage.getItem('verification_email') != null) {
  email.value = localStorage.getItem('verification_email');
  state.value = 'code_sent';
} else {
  state.value = 'email_require';
}

/**
 * When the customer needs to input their email address again.
 */
function resendRequest() {
  isLoading.value = true;
  store.dispatch("resendVerification", {
    email: email.value
  }).then((response) => {
    switch (response.data.status) {
      case 'already_verified':
        notify("positive", "This email address is already verified and in-use. If you own this email address, please try signing in.");
        router.push('/login');
        break;
      case 'interval_required':
        notify("primary", response.data.data.message);
        countDown.value = response.data.data.interval * 60;
        activateCountdownTimer();
        state.value = 'code_sent';
        break;
      case 'verification_email_sent':
        notify("positive", "We have sent a 6-digit code to your email. Please check your inbox and enter it here.");
        localStorage.setItem('verification_email', email.value);
        state.value = 'code_sent';
        countDown.value = 300;
        activateCountdownTimer();
        break;
    }
  }).catch((error) => {
    if (error.response !== undefined) {
      switch (error.response.status) {
        case 404:
          notify("negative", "The email address that you entered was not found. If you own this email address, please create an account instead.");
          break;
        default:
          notify("negative", "There has been an error while trying to send verification to that email address. Please try again later.");
          break;
      }
    } else {
      console.error(error);
    }
  }).finally(() => {
    isLoading.value = false;
  });
}

/**
 * Pre-sends the verification code to the server.
 */
function sendVerificationCode() {
  isLoading.value = true;
  store.dispatch("verify", {
    email: email.value,
    verification_key: code.value,
    mode: 'pre-verify'
  }).then((response) => {
    switch (response.data.status) {
      case 'already_verified':
        notify("positive", "This email address is already verified and in-use. If you own this email address, please try signing in.");
        router.push('/login');
        break;
      default:
        state.value = 'input_password';
        break;
    }
  }).catch((error) => {
    if (error.response !== undefined) {
      switch (error.response.status) {
        case 400:
          if (error.response.data.status === 'code_expired') {
            notify("negative", "The code has already expired. Please try sending a new code.");
            state.value = 'email_require';
          } else if (error.response.data.status === 'incorrect_code') {
            notify("negative", "The code that you entered is incorrect. Please try again.");
          }
          break;
        case 404:
          notify("negative", "The email address was not found. Please try again or create an account using it.");
          state.value = 'email_require';
          break;
        default:
          notify("negative", "There has been an error while trying to send verification to that email address. Please try again later.");
          break;
      }
    }
  }).finally(() => {
    isLoading.value = false;
  })
}

/**
 * Sends the verification code to the server and the selected password of
 * the customer.
 */
function finalizeVerification() {
  if (password.value.length < 8) {
    pworderr.value = 'Password must be at least 8 characters long.';
    return;
  }

  if (password.value != cpassword.value) {
    pworderr.value = 'The new password and repeat password must match. Please try again.';
    return;
  }

  isLoading.value = true;
  pworderr.value = '';
  store.dispatch("verify", {
    email: email.value,
    verification_key: code.value,
    password: password.value
  }).then((response) => {
    switch (response.data.status) {
      case 'already_verified':
        notify("positive", "This email address is already verified and in-use. If you own this email address, please try signing in.");
        router.push('/login');
        break;
      case 'success':
        notify("positive", "You have successfully finished setting up your account.");
        store.dispatch("login", {
          sign_in_type: "email",
          sign_in_identifier: email.value,
          sign_in_password: password.value,
        }).then((response) => {
          if (response.status == "success") {
            store.commit("user/setUserData", response.userData);

            setTimeout(() => {
              router.push("/dashboard");
            }, 2000);
          }
        });
        break;
      default:
        state.value = 'input_password';
        break;
    }
  }).catch((error) => {
    if (error.response !== undefined) {
      switch (error.response.status) {
        case 400:
          if (error.response.data.status === 'code_expired') {
            notify("negative", "The code has already expired. Please try sending a new code.");
            state.value = 'email_require';
          } else if (error.response.data.status === 'incorrect_code') {
            notify("negative", "The code that you entered is incorrect. Please try again.");
          } else if (error.response.data.status === 'invalid_password_length') {
            pworderr.value = 'Password must be at least 8 characters long.';
          }
          break;
        case 404:
          notify("negative", "The email address was not found. Please try again or create an account using it.");
          state.value = 'email_require';
          break;
        default:
          notify("negative", "There has been an error while trying to send verification to that email address. Please try again later.");
          break;
      }
    }
  }).finally(() => {
    isLoading.value = false;
  })  
}

/**
 * Activates the countdown timer.
 */
function activateCountdownTimer() {
    if (countDown.value > 0) {
      setTimeout(() => {
        countDown.value = countDown.value - 1;
        activateCountdownTimer();
      }, 1000);
  }
}

/**
 * If the user initiates change of email address.
 */
function changeEmail() {
  localStorage.removeItem('verification_email');
  email.value = '';
  state.value = 'email_require';
}

</script>
<style scoped>
.cb-form {
  max-width: 600px;
  min-height: 400px;
}
</style>