<template>
  <section class="hp-search">
    <div class="section-content">
      <h3 class="section-title mobile-only text-accent">Book Now</h3>
      <div class="search-cont">
        <div class="search-city">
          <div class="qs-input">
            <span class="qs-header">
              <q-icon name="location_on" color="primary" size="16" />
              Pickup Branch
            </span>
            <q-select
              filled
              v-model="yard"
              clearable
              @update:model-value="(val) => updateYard(val)"
              :options="yardOptions"
              :option-label="yardOptions.name"
              :option-value="yardOptions.id"
              class="qs-input-field"
              ref="searchCity"
              label="Select Branch"
              dense
            />
          </div>
        </div>
        <div class="search-pickup">
          <div class="qs-input">
            <span class="qs-header">
              <q-icon name="events" color="primary" size="16" />
              Pick up Date
            </span>
            <q-input
              filled
              dense
              v-model="pickupdate"
              mask="date"
              placeholder="Add date"
              :rules="['date']"
              class="qs-input-field qs-short"
            >
              <template v-slot:prepend>
                <q-icon name="events" color="primary" size="13px" />
                <q-popup-proxy
                  cover
                  transition-show="scale"
                  transition-hide="scale"
                  ref="searchPickup"
                >
                  <q-date
                    v-model="pickupdate"
                    years-in-month-view
                    :options="optionDates"
                    @update:model-value="(val) => updatePickupDate(val)"
                    :default-year-month="moment(new Date()).format('YYYY/MM')"
                    :navigation-min-year-month="
                      moment(new Date()).format('YYYY/MM')
                    "
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </template>
            </q-input>
          </div>
        </div>
        <div class="search-pickup">
          <div class="qs-input">
            <span class="qs-header">
              <q-icon name="events" color="primary" size="16" />
              Pick up Time
            </span>
            <q-input
              filled
              dense
              v-model="pickupTime"
              placeholder="Set Time"
              class="qs-input-field qs-short"
            >
              <template v-slot:prepend>
                <q-icon name="access_time" class="cursor-pointer">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-time
                      v-model="pickupTime"
                      @update:model-value="(val) => updatePickupTime(val)"
                      mask="hh:mm a"
                      format12h
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-time> </q-popup-proxy
                ></q-icon>
              </template>
            </q-input>
          </div>
        </div>
        <div class="search-return">
          <div class="qs-input">
            <span class="qs-header">
              <q-icon name="events" color="primary" size="16" />
              Duration
            </span>
            <q-select
              dense
              filled
              class="qs-input-field qs-short"
              v-model="quick_selection_duration"
              :label="query.returndate"
              :options="quick_selection_duration_options"
              @update:model-value="(val) => getReturnDateTime(val)"
            />
          </div>
        </div>
        <q-btn
          color="primary"
          icon="search"
          class="hp-search-btn"
          label="Search"
          @click="submitSearch()"
        />
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref, computed } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import vehicleJson from "../../assets/json/vehicle.json";
import { notify } from "../../utils/notification";
import { getReturnDateTime } from "../../utils/searchQuery";

const store = useStore();
const yardOptions = computed(() => store.state.vehicle.yardList);
const query = store.state.vehicle.vehicleSearchQuery;
const router = useRouter();
const yard = ref(query.yard);
const pickupdate = ref(query.pickupdate);
const pickupTime = ref(query.pickupTime);
const quick_selection_duration = ref(query.duration);
const quick_selection_duration_options = ref(
  vehicleJson.quick_selection_duration_options
);

function optionDates(date) {
  return (
    date >= moment(new Date()).subtract(1, "hour").format("YYYY/MM/DD") &&
    date <= moment(new Date()).add(1, "years").format("YYYY/MM/DD")
  );
}
function updateYard(yard) {
  let payload = {
    name: yard ?? null,
    id: yard.id ?? null,
  };

  store.commit("vehicle/setSearchYardData", payload);
}

function submitSearch() {
  if (!quick_selection_duration.value) {
    notify("red", "Please set the duration of your rental.");
    return;
  }
  router.push({ name: "Search" });
}
function updatePickupDate(date) {
  if (date) {
    store.commit("vehicle/setSearchPickupDate", date);
    if (quick_selection_duration.value) {
      getReturnDateTime(quick_selection_duration.value);
    }
  }
}
function updatePickupTime(time) {
  if (time) {
    time = moment(time, "hh:mm a").format("hh:mm a");
    store.commit("vehicle/setSearchPickupTime", time);
    if (quick_selection_duration.value) {
      getReturnDateTime(quick_selection_duration.value);
    }
  }
}
</script>
<style scoped>
section.hp-search {
  position: absolute;
    top: 85px;
    width: 100%;
}
.hp-search .search-cont {
  width: 85%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  margin-bottom: 20px;
  background: white;
}
.search-city,
.search-return,
.search-pickup {
  width: 100%;
  /* box-shadow: var(--box-shadow); */
  border-radius: 10px;
  padding: 15px;
  padding-bottom: 10px;
}
.hp-search .search-city {
  max-width: 400px;
}
.hp-search .search-pickup {
  max-width: 250px;
}
.hp-search .search-return {
  max-width: 250px;
}
.hp-search .hp-search-btn {
  min-width: 250px;
  border-radius: 15px;
  height: 50px;
}
span.qs-header {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  color: black;
}
.qs-input-field {
  max-width: 250px;
  margin: auto;
}
.qs-input-field.qs-short {
  width: 150px;
  padding-bottom: 0;
}
</style>
