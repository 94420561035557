<template>
  <div class="q-pa-md">
    <q-carousel
      v-if="$q.screen.gt.md"
      v-model="slide"
      transition-prev="slide-right"
      transition-next="slide-left"
      swipeable
      animated
      control-color="amber"
      navigation
      padding
      arrows
      class="bg-white rounded-borders full-heigth"
      height="100%"
    >
      <q-carousel-slide :name="1" class="column no-wrap">
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap">
          <q-img class="rounded-borders col-6 full-height" :src="require('@/assets/images/testimonials/carbnb-testimonial-toni-fowler.jpg')" />
          <q-img class="rounded-borders col-6 full-height" :src="require('@/assets/images/testimonials/carbnb-testimonial-tito-vince.jpg')" />
        </div>
      </q-carousel-slide>
      <q-carousel-slide :name="2" class="column no-wrap">
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap">
          <q-img class="rounded-borders col-6 full-height" :src="require('@/assets/images/testimonials/carbnb-testimonial-geo-ong.jpg')" />
          <q-img class="rounded-borders col-6 full-height" :src="require('@/assets/images/testimonials/carbnb-testimonial-tracy-perez.jpg')" />
        </div>
      </q-carousel-slide>
      <q-carousel-slide :name="3" class="column no-wrap">
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap">
            <q-img class="rounded-borders col-6 full-height" :src="require('@/assets/images/testimonials/carbnb-testimonial-maria-gigante.jpg')" />
            <q-img class="rounded-borders col-6 full-height" :src="require('@/assets/images/testimonials/carbnb-testimonial-tracy-perez.jpg')" />
        </div>
      </q-carousel-slide>
    </q-carousel>
    <div class="row" v-else>
        <div class="col-md-6 col-12 q-pa-sm">
            <q-img class="full-height" :src="require('@/assets/images/testimonials/carbnb-testimonial-toni-fowler.jpg')" />
        </div>
        <div class="col-md-6 col-12 q-pa-sm">
            <q-img class="full-height" :src="require('@/assets/images/testimonials/carbnb-testimonial-tito-vince.jpg')" />
        </div>
        <div class="col-md-6 col-12 q-pa-sm">
            <q-img class="full-height" :src="require('@/assets/images/testimonials/carbnb-testimonial-geo-ong.jpg')" />
        </div>
        <div class="col-md-6 col-12 q-pa-sm">
            <q-img class="full-height" :src="require('@/assets/images/testimonials/carbnb-testimonial-tracy-perez.jpg')" />
        </div>
        <div class="col-md-6 col-12 q-pa-sm">
            <q-img class="full-height" :src="require('@/assets/images/testimonials/carbnb-testimonial-maria-gigante.jpg')" />
        </div>    
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useQuasar } from 'quasar';

const slide = ref(1);
const $q = useQuasar();

</script>