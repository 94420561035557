<template>
  <div v-if="vehicles">
    <div v-for="(vehicle, index) in vehicles" :key="index">
      <router-link :to="'/vehicle/' + vehicle.slug">
        <q-card class="q-ma-sm" style="max-height: 50%">
          <div class="flex items-start">
            <div
              class="searchCardImgHolder"
              :style="{
                backgroundImage:
                  'url(' +
                  (vehicle.gallery.length < 1
                    ? require(`@/assets/images/${vehicle.vehicle_type}.webp`)
                    : vehicle.gallery[0].file) +
                  ')',
              }"
            ></div>
            <div class="searchCardDetails">
              <div
                class="text-subtitle2 text-bold text-black q-pl-xs q-pb-xs text-uppercase"
              >
                {{ vehicle.vehicle_brand }} {{ vehicle.vehicle_name }}
                {{ vehicle.year }}
              </div>

              <div
                class="flex gap-10 justify-between q-pr-md preview q-pl-xs text-caption carCardFz12"
              >
                <div class="flex gap-5 items-center">
                  <q-icon name="fa-solid fa-car" /> {{ vehicle.vehicle_type }}
                </div>
                <div class="flex gap-5 items-center">
                  <q-icon name="fas fa-chair" />
                  {{ vehicle.number_of_seats }} Seater
                </div>
                <div class="flex gap-5 items-center">
                  <q-icon name="fas fa-cogs" />
                  {{
                    vehicleData.transmission_options[vehicle.transmission_id]
                      .label
                  }}
                </div>
              </div>

              <div class="q-pt-sm">
                <div class="divider"></div>
              </div>

              <div class="q-pt-sm q-pl-xs text-caption">
                <q-icon name="fa-solid fa-location-dot q-pr-xs text-primary" />
                {{ vehicle.pickup_address }}
              </div>
              <div class="q-pt-sm q-pl-xs text-caption">
                <q-chip v-if="vehicle.is_carbnb_managed" color="accent" text-color="white" icon="verified">
                  Carbnb Yard
                  <q-tooltip class="text-body2 bg-accent"><q-icon name="verified"/> You will pick up this vehicle from an official Carbnb Yard and managed by our staffs.</q-tooltip>
                </q-chip>
                <q-chip v-else color="teal" text-color="white" icon="check_circle">
                  Outside Operator
                  <q-tooltip class="text-body2 bg-teal"><q-icon name="check_circle"/> You will pick up this vehicle from our verified and trusted outside operator.</q-tooltip>
                </q-chip>
              </div>
            </div>
            <div
              class="searchCardPricing"
              style="border-left: 1px solid rgba(0, 0, 0, 0.237)"
            >
              <div class="row">
                <div
                  class="col-8 text-subtitle2 text-bold text-black q-pl-xs q-pb-xs"
                >
                  Price:
                </div>
              </div>
              <div class="q-pl-xs text-caption">
                {{ getBasePriceHalf(vehicle.vehicle_type) }} per 12 hrs
              </div>
              <div class="q-pl-xs text-caption">
                {{ getBasePriceFull(vehicle.vehicle_type) }} per 24 hrs
              </div>
              <br />
              <q-separator class="q-mt-lg" />
                <small class="text-positive">+ {{ getPrice(vehicle.vehicle_type).insuranceAndOtherCharges }}</small><br>
                <small>Insurance and charges</small>
              <div
                class="q-mt-sm text-caption flex justify-between items-center"
              >
                Total:
                <div>
                  <span class="text-subtitle2 text-bold text-primary">
                    {{ getPrice(vehicle.vehicle_type).finalPrice }}</span
                  >
                </div>
              </div>
              <div class="q-pt-sm text-center">
                <q-btn class="btn-primary" icon="check_circle_outline" label="Select" />
              </div>
            </div>
          </div> </q-card
      ></router-link>
    </div>
    <div class="full-width flex items-center justify-center q-pb-md">
      <q-btn flat label="Load More" color="primary" @click="loadMore()" />
    </div>
  </div>
  <div v-else class="q-mt-lg flex items-center justify-center gap-10 column">
    <q-icon name="search_off" size="50px" color="primary" />
    <span class="text-subtitle1 text-primary text-bold"
      >No Vehicles Available</span
    >
    <div class="text-center text-subtitle2">
    <p>There are no vehicles available on your selected dates. <br>Please select different set of dates.</p>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computePrice, formatMoney } from "../../utils/pricing";
import vehicleData from "../../assets/json/vehicle.json";
import { computed } from "vue";
import { defineEmits } from "vue";
// import VLazyImage from "v-lazy-image";
const store = useStore();
const vehicles = computed(() => store.state.vehicle.vehicleResults);
const searchQuery = computed(() => store.state.vehicle.vehicleSearchQuery);
const emit = defineEmits(["loadMoreVehicles"]);

function loadMore() {
  emit("loadMoreVehicles");
}

function getPrice(type) {
  return computePrice(
    type,
    searchQuery.value.pickupdate,
    searchQuery.value.pickupTime,
    searchQuery.value.returndate,
    searchQuery.value.returnTime
  );
}

function getBasePriceHalf(type) {
  return formatMoney(vehicleData.vehicle_pricing[type].half);
}

function getBasePriceFull(type) {
  return formatMoney(vehicleData.vehicle_pricing[type].full);
}
</script>

<style scoped>
.divider {
  background: rgba(0, 0, 0, 0.237);
  width: 95%;
  padding: 1px;
}
.gap-5 {
  gap: 5px;
}
.searchCardImgHolder {
  width: 32%;
  min-width: 200px;
  height: 213px;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.searchCardPricing {
  width: 25%;
  padding: 5px;
}
.searchCardDetails {
  width: 43%;
  padding: 10px 5px;
}
</style>
