<template >
  <q-badge
  :color="booking_status[props.status].color"
  :label="booking_status[props.status].status"
  />
</template>
<script setup>
import { defineProps } from 'vue';

const props = defineProps(['status'])
const booking_status = [
  {
    color: 'grey',
    status: "DISABLED"
  },
  {
    color: 'orange',
    status: "FOR APPROVAL"
  },
  {
    color: 'blue',
    status: "FOR PAYMENT"
  },
  {
    color: 'purple',
    status: "FOR RELEASE"
  },
  {
    color: 'green',
    status: "ACTIVE"
  },
  {
    color: 'orange',
    status: "HOLD"
  },
  {
    color: 'blue',
    status: "CLOSED"
  },
  {
    color: 'red',
    status: "CANCELLED"
  },
  {
    color: 'gray',
    status: "EXPIRED"
  }
]
</script>
