import script from "./ControlPanelBlockedSchedules.vue?vue&type=script&setup=true&lang=js"
export * from "./ControlPanelBlockedSchedules.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QTime from 'quasar/src/components/time/QTime.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpinner,QBtn,QIcon,QTable,QTd,QDialog,QCard,QCardSection,QInput,QPopupProxy,QDate,QTime,QCardActions});qInstall(script, 'directives', {ClosePopup});
