<template>
  <div v-if="vehicles" class="searchCarCardMobile">
    <div v-for="(vehicle, index) in vehicles" :key="index">
      <router-link :to="'/vehicle/' + vehicle.slug">
        <q-card class="q-ma-sm" style="max-height: 50%">
          <div class="flex items-start q-pa-sm">
            <div
              class="searchCardImgHolder"
              :style="{
                backgroundImage:
                  'url(' +
                  (vehicle.gallery.length < 1
                    ? require(`@/assets/images/${vehicle.vehicle_type}.webp`)
                    : vehicle.gallery[0].file) +
                  ')',
              }"
            >
              <!-- <img
                :src="
                  vehicle.gallery.length < 1
                    ? require(`@/assets/images/${vehicle.vehicle_type}.webp`)
                    : vehicle.gallery[0].file
                "
                style="width: 100%; height: 100%"
              /> -->
            </div>
            <div class="searchCardDetails">
              <div
                class="text-subtitle2 text-bold text-black q-pl-xs q-pb-xs text-uppercase"
              >
                {{ vehicle.vehicle_brand }} {{ vehicle.vehicle_name }}
                {{ vehicle.year }}
              </div>

              <div
                class="flex gap-10 justify-between preview text-caption carCardFz12 q-pa-sm carPricingMobileSm"
              >
                <div class="flex gap-5 items-center">
                  <q-icon name="fa-solid fa-car" /> {{ vehicle.vehicle_type }}
                </div>
                <div class="flex gap-5 items-center">
                  <q-icon name="fas fa-chair" />
                  {{ vehicle.number_of_seats }} Seater
                </div>
                <div class="flex gap-5 items-center">
                  <q-icon name="fas fa-cogs" />
                  {{
                    vehicleData.transmission_options[vehicle.transmission_id]
                      .label
                  }}
                </div>
              </div>
              <div
                class="flex gap-10 justify-between preview text-caption carCardFz12 q-pa-xs carPricingMobileXs"
              >
                <div class="flex gap-5 items-center">
                  <q-icon name="fa-solid fa-car" /> {{ vehicle.vehicle_type }}
                </div>
                <div class="flex gap-5 items-center">
                  <q-icon name="fas fa-chair" />
                  {{ vehicle.number_of_seats }} Seater
                </div>
                <div class="flex gap-5 items-center">
                  <q-icon name="fas fa-cogs" />
                  {{
                    vehicleData.transmission_options[vehicle.transmission_id]
                      .label
                  }}
                </div>
              </div>
              <q-separator spaced class="q-my-xs" />
              <div class="text-subtitle2 q-pl-sm">
                <q-icon name="fa-solid fa-location-dot q-pr-xs text-primary text-subtitle2" />
                {{ vehicle.pickup_address }}
              </div>

              <div class="q-pt-sm q-pl-xs text-caption">
                <q-chip class="text-caption" v-if="vehicle.is_carbnb_managed" color="accent" text-color="white" icon="verified">
                  Carbnb Yard
                  <q-tooltip class="bg-accent"><q-icon name="verified"/> You will pick up this vehicle from an official Carbnb Yard and managed by our staffs.</q-tooltip>
                </q-chip>
                <q-chip class="text-caption" v-else color="teal" text-color="white" icon="check_circle">
                  Outside Operator
                  <q-tooltip class="bg-teal"><q-icon name="check_circle"/> You will pick up this vehicle from our verified and trusted outside operator.</q-tooltip>
                </q-chip>
              </div>

              <div class="q-my-xs">
              <div class="q-pl-xs text-subtitle1">
                <b>{{ getBasePriceHalf(vehicle.vehicle_type) }}</b> for 12 hrs
              </div>
              <div class="q-pl-xs text-subtitle1">
                <b>{{ getBasePriceFull(vehicle.vehicle_type) }}</b> for 24 hrs
              </div>
              <small class="text-positive">+ {{ getPrice(vehicle.vehicle_type).insuranceAndOtherCharges }}</small> <small>for insurance and other charges</small><br>
              <div
                class="flex full-width items-center justify-between carCardTotalPrice q-mt-sm q-pl-sm"
              >
                  Total:
                  <span class="text-subtitle2 text-bold text-black"
                    >{{ getPrice(vehicle.vehicle_type).finalPrice }}
                  </span>
                    </div>
              </div>
            </div>
          </div>

          <div class="full-width q-px-md q-pb-sm">
            <q-btn class="btn-primary" label="View More" />
          </div> </q-card
      ></router-link>
    </div>
    <div class="full-width flex items-center justify-center q-pb-md">
      <q-btn flat label="Load More" color="primary" @click="loadMore()" />
    </div>
  </div>
  <div v-else class="q-mt-lg flex items-center justify-center gap-10 column">
    <q-icon name="search_off" size="50px" color="primary" />
 <span class="text-subtitle2 text-primary text-bold"
      >No Vehicles Available</span
    >
    <div class="text-center text-subtitle2">
    <p>There are no vehicles found on your selected dates. <br>Please select different set of dates.</p>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computePrice, formatMoney } from "../../utils/pricing";
import vehicleData from "../../assets/json/vehicle.json";
import { computed } from "vue";
import { defineEmits } from "vue";
const store = useStore();
const vehicles = computed(() => store.state.vehicle.vehicleResults);
const searchQuery = computed(() => store.state.vehicle.vehicleSearchQuery);
const emit = defineEmits(["loadMoreVehicles"]);

function loadMore() {
  emit("loadMoreVehicles");
}

function getPrice(type) {
  return computePrice(
    type,
    searchQuery.value.pickupdate,
    searchQuery.value.pickupTime,
    searchQuery.value.returndate,
    searchQuery.value.returnTime
  );
}
function getBasePriceHalf(type) {
  return formatMoney(vehicleData.vehicle_pricing[type].half);
}

function getBasePriceFull(type) {
  return formatMoney(vehicleData.vehicle_pricing[type].full);
}
</script>

<style scoped>
.divider {
  background: rgba(0, 0, 0, 0.237);
  width: 95%;
  padding: 1px;
}
.gap-5 {
  gap: 5px;
}
.searchCardImgHolder {
  width: 100%;
  height: 313px;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.searchCardPricing {
  width: 100%;
  padding: 5px;
}
.searchCardDetails {
  width: 100%;
  padding: 10px 10px;
}
</style>
