<template>
  <div class="container profileDashboard confirmPayment">
    <section class="full-width text-center">
      <div class="absolute-center">
        <div class="flex column gap-20 items-center" v-if="approvePayment">
          <h6>Please wait while we finalize your payment...</h6>
          <q-spinner color="primary" class="block" size="3em" />
        </div>
        <div class="flex column gap-20 items-center" v-else>
          <h6>Click Pay Now to proceed with your payment.</h6>
          <q-btn label="Pay Now" color="primary" @click="toPay()" />
        </div>
      </div>
    </section>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { notify } from "../utils/notification";
import { ref } from 'vue'

const store = useStore();
const router = useRouter()
const approvePayment = ref(false)

function toPay() {
  approvePayment.value = true
  const payload = store.state.booking.bookingPaymentDetails;
  store.dispatch("booking/getPaymentSource", payload).then((response) => {
    if(response.status == 'chargeable') {
      store.dispatch("booking/transactPayment", payload.invoice).then((res) => {
        if (res.status == 'paid') {
          notify("positive", "Your payment is successful.")
          router.push({name:"Booking", params: {id:payload.booking_id}})
        }
      })
    }
  })
}

</script>
<style lang=""></style>
