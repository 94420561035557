<template>
  <section>
  <div v-if="!isLoading">
    <div class="banner-header">
      <CarBanner />
    </div>
    <div class="car-details-wrapper">
      <div class="left-content">
        <!-- Description -->
        <!-- <CarDescriptions :vehicle="vehicle" /> -->
        <!-- Other Info -->
        <CarInfo :vehicle="vehicle" />
        <br><br>
        <div class="booking-notice q-pl-xl q-pr-md mobileLessPadding1">
          <span class="text-bold text-h6">Requirement - Bring 2 Primary IDs</span>
          <ul>
            <li>Please be reminded to bring your two primary IDs (including your driver's license) with you when you pick up the car.</li>
            <li>The other primary ID presented will be handed to the assigned Carbnb employee and will be released as soon as you return the vehicle for security purposes.</li>
          </ul>
          <span class="text-bold text-h6"><q-chip color="red" text-color="white" icon="info" label="Important"/> Long Travel Advisories</span>
          <br>
          <p>Please be advised that we require a minimum of 2 days rental on long-distance travels. Plan accordingly for a comfortable journey!</p>
          <span class="text-bold text-h6">A driver, friend or relative is picking up the vehicle?</span>
          <br><br>
          <p>If there are other individuals like a driver, friend or relative that will pick up the vehicle for you, please be advised that we will require:</p>
          <ol>
            <li>A duly signed <b>Authorization Letter</b> stating that the client who booked the vehicle is authorizing you to pick up the vehicle on their behalf.</li>
            <li>Driver's license <b>and</b> 1 valid ID (Primary ID) of the person who will pick up the vehicle.</li>
            <li>Two (2) primary valid IDs of the client that is named after the booking.</li>
            <li>The Carbnb team will contact the primary client on the day of the pick up of the vehicle for verification and security purposes.</li>
          </ol>
          <span class="text-bold text-h6">Car Wash</span>
          <p class="q-my-md">
            <span class="text-weight-medium">One time cleaning fee</span> of PHP 250.00 for Sedans and Hatchbacks and PHP 350.00 for MPV, SUV, Pickup and Vans is to be paid upon pickup of vehicle <b>if the vehicle will be picked up from a Carbnb yard</b>. 
          </p>
          <span class="text-bold text-h6">Booking Policy</span>
          <p class="q-my-md">
            <span class="text-weight-medium">Booking Updates & Extension:</span>
            Upon booking or renting a car. Any form of updates and rent
            extension you wish to be made should be forwarded to our admin. You
            may send a direct message using our contact us page or reach us to
            any of our contact details below:
          </p>
          <b>For immediate support or extensions you can call or text  our 24/7 Customer Service Team here:</b><br><br>
          <q-icon name="email" /> support@carbnbrentals.com<br>
          <q-icon name="phone" /> Landline: 02 (8288-7096)<br>
          <q-icon name="phone_iphone" /> Mobile Number: +63 927 640 0108<br>
          <br />
          <span class="text-bold text-h6">Important Reminders</span>
          <ul>
            <li>Strictly no cancellation or refund. We can only reschedule your booking.</li>
            <li>Strictly no reservation fee, no booking.</li>
            <li>All bookings are subject to insurance fee and other charges.</li>
            <li>No cash bond/security deposition is needed.</li>
            <li>12 hours rent is strictly within Davao, Cebu and Metro Manila only.</li>
            <li><b>Unli Mileage</b>: 24 hours or more is unli mileage but subject to terms and agreements when taking the vehicle on an interisland travels.</li>
            <li><b>Standard Overtime:</b> Maximum of 3 hours only for <b>PHP 250.00</b> per hour, but still depends if there is no other succeeding booking. If there are more than 3 hours of overtime or late, the client must pay the equivalent of a day rental or more.</li>
          </ul>
          <p class="q-my-md">
            <span class="text-weight-medium">Booking Payment:</span> Please be
            reminded that you are only paying the reservation fee online and
            need to pay the <b>remaining balance upon pickup</b> upon
            picking up the vehicle.
          </p>
          <br />
        <br>
        </div>
        <!-- Ratings -->
        <!-- <CarOwnerRatings /> -->
        <!-- Calendar -->
        <!-- <CarCalendar :vehicle="vehicle" /> -->
      </div>
      <div class="right-content">
        <CarSideBar :vehicle="vehicle" :booking_details="booking_details" :isView="isView" />
      </div>
    </div>
  </div>
  <div v-else class="car-details-wrapper">
    <div class="row full-width">
      <div class="col-12 q-px-sm">
            <q-skeleton height="150px" class="full-width" />
      </div>
      <div class="col-12 col-md-6 q-px-sm">
          <q-skeleton height="50px" class="full-width q-ma-sm" />
          <q-skeleton height="50px" class="full-width q-ma-sm" />
          <q-skeleton height="50px" class="full-width q-ma-sm" />
          <q-skeleton height="50px" class="full-width q-ma-sm" />
          <q-skeleton height="50px" class="full-width q-ma-sm" />
          <q-skeleton height="50px" class="full-width q-ma-sm" />
      </div>
      <div class="col-12 col-md-6 q-px-sm">
          <q-skeleton class="full-width q-ma-sm" style="height: 60vh" />
      </div>
    </div>
  </div>
  </section>
</template>

<script setup>
import CarBanner from "../components/Car/CarBanner.vue";
import CarSideBar from "../components/Car/CarSideBar.vue";
import CarInfo from "../components/Car/CarInfo.vue";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

const store = useStore();
const route = useRoute();
const router = useRouter();
const isView = route.query.view
const isLoading = ref(false);
const booking_details = store.state.vehicle.vehicleSearchQuery;
const vehicle = computed(() => store.state.vehicle.vehicleData);

function fetchVehicle() {
  isLoading.value = true;
  store.dispatch("vehicle/fetchSingleVehicle", route.params.id).catch((err) => {
    if (err.response !== undefined) {
      if (err.response.statusCode == 404) {
        router.push({name: 'NotFound'});
      }
    } else {
      console.error(err)
    }
  })
  .finally(() => {
    isLoading.value = false;
  });
}

fetchVehicle();
</script>
<style scoped>

.car-details-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 30px;
  max-width: 1200px;
  margin: auto;
}
.car-details-wrapper .left-content {
  width: 60%;
}
.car-details-wrapper .right-content {
  width: 40%;
  padding: 20px;
}
a {
  font-weight: 300;
  font-size: 14px;
}
a:hover {
  text-decoration: underline;
}
</style>
