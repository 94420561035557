import script from "./ManageBooking.vue?vue&type=script&setup=true&lang=js"
export * from "./ManageBooking.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSeparator,QDialog,QCard,QCardSection,QCardActions,QBtn});qInstall(script, 'directives', {ClosePopup});
