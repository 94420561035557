<template>
    <section>
        <div class="row q-pa-md" v-if="!show3DS">
          <div class="col-12 q-pa-xs">
              <h5 style="margin: 0" class="q-pb-sm">Your Card Details <q-img src="/graphics/payment-card.png" for="payment-card" style="width: 75px; height: auto" /></h5>
              <p>Please enter your card details to continue paying for <b>PHP {{ reservationFee }}</b> reservation fee.</p>
          </div>
          <div class="col-12 q-pa-xs">
            <q-input 
              outlined 
              v-model="cardDetails.number" 
              label="Enter Card Number" 
              :dense="dense" 
              mask="################"
              fill-mask=""
              :error="inputFields.cardNumber.hasError"
              :error-message="inputFields.cardNumber.errorText"
              :disable="!allowInputs" 
            />
          </div>
          <div class="col-md-4 col-12 q-pa-xs">
            <q-input 
              outlined 
              v-model="cardDetails.securityCode" 
              label="Security Code (CVC)"
              :dense="dense" 
              mask="###"
              fill-mask=" "
              :error="inputFields.cvc.hasError"
              :error-message="inputFields.cvc.errorText"
              :disable="!allowInputs"                   
            />
          </div>
          <div class="col-md-4 col-6 q-pa-xs">
            <q-input 
              outlined 
              v-model="cardDetails.expMonth" 
              label="Expiration Month" 
              :dense="dense" 
              mask="##"
              fill-mask=" "
              :error="inputFields.expMonth.hasError"
              :error-message="inputFields.expMonth.errorText"
              :disable="!allowInputs"
            />
          </div>
          <div class="col-md-4 col-6 q-pa-xs">
            <q-input 
              outlined 
              v-model="cardDetails.expYear" 
              label="Expiration Year" 
              :dense="dense" 
              mask="##"
              fill-mask=" "
              :error="inputFields.expYear.hasError"
              :error-message="inputFields.expYear.errorText"
              :disable="!allowInputs" 
            />
          </div>
          <div class="col-12 q-pa-xs">
            <q-banner class="bg-white text-accent q-mt-md" v-if="showGenericError">
              <template v-slot:avatar>
                <q-icon name="payment" color="accent" />
              </template>
              <span class="text-caption">{{ genericErrorMessage }}</span>
              <template v-slot:action>
                <q-btn flat color="accent" label="Confirm" @click="showGenericError = !showGenericError" />
              </template>
            </q-banner>    
          </div>
          <div class="col-12 q-pa-xs text-right q-mt-xl">
              <q-btn
                  color="red"
                  label="Cancel"
                  icon="close"
                  v-close-popup
                  :disabled="isSubmittingCard"
              />
              <q-btn
                  color="positive"
                  label="Submit"
                  icon="credit_card"
                  class="q-ml-xs"
                  :loading="isSubmittingCard"
                  @click="submitCard"
              />
          </div>
        </div>
        <div v-if="show3DS" style="width: 600px">
          <iframe
            width="100%"
            height="650px"
            :src="card3DSurl"
            style="border: 0">
          </iframe>
        </div>
    </section>
</template>

<script setup>
    import { ref, computed, defineEmits } from "vue";
    import { useStore } from "vuex";
    import paymentResponses from "../../utils/payment/paymentResponses";
    const emit = defineEmits(['success']);
    const store = useStore();
    const isSubmittingCard = ref(false);
    const allowInputs = ref(true);
    const showGenericError = ref(false);
    const genericErrorMessage = ref('');
    const show3DS = ref(false);
    const card3DSurl = ref('https://example.com');
    const cardDetails = ref({
        number: '',
        expMonth: '',
        expYear: '',
        securityCode: ''
    });
    const inputFields = ref({
        cardNumber: {
            pointer: 'details.card_number',
            attribute: 'Card Number',
            hasError: false,
            errorText: ''
        },
        expMonth: {
            pointer: 'details.exp_month',
            attribute: 'Expiration Month',
            hasError: false,
            errorText: ''
        },
        expYear: {
            pointer: 'details.exp_year',
            attribute: 'Expiration Year',
            hasError: false,
            errorText: ''
        },
        cvc: {
            pointer: 'details.cvc',
            attribute: 'CVC',
            hasError: false,
            errorText: ''
        }
    });
    const reservationFee = computed(() => { return store.state.payment.invoice.reservation_fee })

    /**
     * Submits the card to the server for validation.
     */
    function submitCard() {
        // Resets all the validation messages.
        resetValidations();

        // Initialize loading.
        isSubmittingCard.value = true;

        // Disable the fields.
        allowInputs.value = false;

        // If no payment intent id attached, return to bookings
        if (store.state.paymentIntentId === null) {
            localStorage.clear();
            this.$router.push('/bookings');
            console.error('There was no payment intent found on the website. Please try again.')
        }

        // Create the payment method
        createPaymentMethod(store.state.payment.paymentIntentId, {
            cardDetails: cardDetails.value,
            paymentType: 'card'
        });
    }

    /**
     * Resets all the validation messages.
     */
    function resetValidations() {
        inputFields.value.cardNumber.hasError = false;
        inputFields.value.cardNumber.errorText = '';

        inputFields.value.expMonth.hasError = false;
        inputFields.value.expMonth.errorText = '';      

        inputFields.value.expYear.hasError = false;
        inputFields.value.expYear.errorText = '';    

        inputFields.value.cvc.hasError = false;
        inputFields.value.cvc.errorText = '';     
    }

    /**
     * Handles the card payment errors properly and display it on the page
     * for users.
     */
    function handleErrors(errors) {
         showErrorMessage('Please double check your inputs.')
         errors.forEach((error) => {
          switch (error.source.attribute) {
            case 'card_number':
              inputFields.value.cardNumber.errorText = error.detail.replace(
                error.source.pointer,
                inputFields.value.cardNumber.attribute
              );
              inputFields.value.cardNumber.hasError = true;
            break;
            case 'exp_month':
              inputFields.value.expMonth.errorText = error.detail.replace(
                error.source.pointer,
                inputFields.value.expMonth.attribute
              );
              inputFields.value.expMonth.hasError = true;
            break;
            case 'exp_year':
              inputFields.value.expYear.errorText = error.detail.replace(
                error.source.pointer,
                inputFields.value.expYear.attribute
              );
              inputFields.value.expYear.hasError = true;
            break;
            case 'cvc':
              inputFields.value.cvc.errorText = error.detail.replace(
                error.source.pointer,
                inputFields.value.cvc.attribute
              );
              inputFields.value.cvc.hasError = true;
            break;                                                                                 
          }
        });       
    }

    /**
     * Creates the payment method to Paymongo using the provided card details.
     */
    function createPaymentMethod(paymentIntentId, paymentDetails) {
        store.dispatch('payment/createPaymentMethod', {
            paymentIntentId: paymentIntentId,
            cardDetails: paymentDetails.cardDetails,
            paymentType: paymentDetails.paymentType
        }).then((response) => {
            switch (response.status) {
                case 'card_submitted':
                    // If the card has been successfully submitted, attach the
                    // payment method to the intent.
                    attachPaymentMethod(
                      paymentIntentId,
                      response.paymentMethodId
                    );
                    console.log('Attaching payment method to the payment intent.')
                    break;
                case 'already_paid':
                    emit('success');
                    break;
            }
        }).catch((err) => {
            switch (err.response.status) {
                case 400:
                  handleErrors(err.response.data.errors);
                  break;
                default:
                  showErrorMessage('There has been an error while trying to validate your card. Please try again.')
                  break;
            }
        });
    }

    /**
     * Attach the payment method to the payment intent. At this event
     * the payment will now be processed.
     */
    function attachPaymentMethod(paymentIntentId, paymentMethodId) {
        store.dispatch('payment/attachPaymentMethod', {
          paymentIntentId: paymentIntentId,
          paymentMethodId: paymentMethodId
        }).then((response) => {
          switch (response.status) {
            case paymentResponses.authenticationRequired:
              show3DS.value = true;
              card3DSurl.value = response.url;
              console.log('3DS authentication is required.')
              break;
            case paymentResponses.paymentFailed:
              showErrorMessage('The payment attempt failed. No deductions made and your booking is still waiting for payment. Please try again later.');
              break;
            case paymentResponses.processing:
              console.log('The payment is still processing. Retrying in 3 seconds..');
              setTimeout(() => {
                attachPaymentMethod(paymentIntentId, paymentMethodId);
              }, 3000);
              break;
            default:
              emit('success');
          }
        }).catch((err) => {
          if (err.response.status == 404 && err.response.data.status == 'payment_not_available') {
            showGenericError.value = true;
            allowInputs.value = true;
            genericErrorMessage.value = 'We are sorry but card payment is not available at this moment.';
          } else if (err.response.status == 400) {
            isSubmittingCard.value = false;
            showGenericError.value = true;
            allowInputs.value = true;
            if (err.response.data.status == 'generic_decline') {
              genericErrorMessage.value = err.response.data.data.message;
            } else {
              genericErrorMessage.value = 'We have failed to complete the process. Please try again later.';
            }
          }
        });
    }

    /**
     * Shows a message to the user from the card input form.
     */
    function showErrorMessage(message) {
      showGenericError.value = true;
      allowInputs.value = allowInputs;
      genericErrorMessage.value = message;
      isSubmittingCard.value = false;
    }
</script>