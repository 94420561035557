<template>
  <div class="container profileDashboard">
    <ProfileSidebar />

    <section>
      <h5 class="text-accent">Settings</h5>

      <q-separator class="q-my-md" />

      <div class="q-pl-sm">
        <div class="text-caption flex justify-between items-center">
          <div>
            <span class="text-subtitle1 text-bold">Change Password</span>
            <p>It is advised to change your password regularly.</p>
          </div>
          <AppBtn
            :text="'Change'"
            :class="'autoWidth maxWidth200'"
            :dense="true"
            icon="edit"
            v-if="!resetPassword"
            @click="resetPassword = true"
          />
        </div>


        <ChangePassword
          v-if="resetPassword"
          @cancelPasswordReset="cancelPasswordReset"
        />

        <!-- <q-separator class="q-my-md" /> -->
        <!-- Add payment account -->
        <!-- <div class="text-caption flex justify-between items-center q-mb-md">

            <div>
              <span class="text-subtitle1 text-bold">Payment Accounts</span>
              <p class="q-mb-none">Choose and add your preferred payment account to be use. <br> We will only show the first 3 accounts you have on booking page.</p>
            </div>
            <q-btn
            color="secondary"
            flat
            class="q-py-xs q-px-md"
            no-caps
            label="Cancel"
            v-if="addPaymentAccount"
            @click="addPaymentAccount = false"
          />
          <AppBtn
            :text="'Add'"
            :class="'autoWidth maxWidth100'"
            :dense="true"
            v-if="!addPaymentAccount"
            @click="addPaymentAccount = true"
          />
        </div> -->

        <PaymentAccounts class="full-width" v-if="addPaymentAccount" />
        
      </div>
    </section>
  </div>
</template>
<script setup>
import ProfileSidebar from "../components/Profile/ProfileSidebar.vue";
import AppBtn from "../components/Globals/AppBtn.vue";
import ChangePassword from "../components/Settings/ChangePassword.vue";
import PaymentAccounts from "../components/Settings/PaymentAccounts.vue";
import { ref } from "vue";

const resetPassword = ref(false);
const resetPasswordRequestSent = ref(false);
const addPaymentAccount = ref(false);

function cancelPasswordReset() {
  resetPassword.value = false;
  resetPasswordRequestSent.value = false;
}
</script>
<style scoped>
.btn-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.maxWidth100 {
  width: 100px;
}
@media screen and (max-width: 768px) {
  .btn-password {
    display: inline;
  }
}
</style>
