<template>
  <div class="container profileDashboard">
    <ProfileSidebar />
    <section>
      <div class="flex justify-between full-width q-mt-sm">
        <h5 class="q-ma-none">My Vehicles</h5>
        <AppBtn
          :text="'Add Vehicle'"
          to="/vehicle/create"
          :class="'autoWidth'"
          v-if="user.type==2"
          :isDisabled="store.state.user.userData.type != '2'"
          icon="add"
        />
      </div>

      <q-separator class="q-my-md" />

      <div class="car-wrapper q-mb-md" v-if="forListCars && user.type== 2">
        <ForListCars
          v-for="(car, index) in forListCars"
          :key="index"
          :car="car"
        />
      </div>
      <div v-else-if="!forListCars && user.type == 2" class="q-mt-lg text-center">
        <p>You do not have any vehicles. Click Add Vehicle to add a vehicle.</p>
      </div>
      <div v-else>
        <p>You are not yet a verified operator. Please apply <router-link to="/apply-as-operator">here</router-link> and submit your first vehicle application.</p>
      </div>
    </section>
  </div>
</template>
<script setup>
import ForListCars from "../../components/Profile/ForListCars.vue";
import ProfileSidebar from "../../components/Profile/ProfileSidebar.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { useQuasar } from 'quasar'
import AppBtn from "../../components/Globals/AppBtn.vue";

const store = useStore();
const user = store.state.user.userData;
const forListCars = computed(() => store.state.operator.vehicles);
const $q = useQuasar();

$q.loading.show();
function loadVehicles() {
  store.dispatch("operator/getVehicles").finally(() => {
    $q.loading.hide();
  });
}

loadVehicles();
</script>
<style scoped>
.car-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;
  flex-direction: column;
}

p > a {
  color: var(--color-primary);
}
p > a:hover {
  text-decoration:underline;
}
</style>
