<template>
  <div class="q-pt-md q-pl-xl mobileLessPadding1 mobileNoMargin">
    <div class="q-pb-md text-bold text-h6">Vehicle Details</div>
    <div class="q-py-sm text-subtitle2 flex justify-between">
      Plate Number <span class="q-pl-xl q-ml-xl"> {{ vehicle.plate_number }} </span>
    </div>
    <div class="q-py-sm text-subtitle2 flex justify-between">
      Fuel Type
      <span class="q-pl-xl q-ml-xl">
        {{ vehicleData.fuel_options[vehicle.fuel_type_id].label }}
      </span>
    </div>
    <div class="q-py-sm text-subtitle2 flex justify-between">
      Coding Day <span class="q-pl-xl q-ml-xl"> {{ vehicle.coding_day }} </span>
    </div>
    <p>This vehicle's coding day is on <b>{{ vehicle.coding_day }}</b> which means that this vehicle should avoid be driven around areas that have number coding schemes.</p>
  </div>
</template>
<script>
import vehicleData from "../../assets/json/vehicle.json";
export default {
  props: {
    vehicle: Object,
  },
  setup() {
    return {
      vehicleData,
    };
  },
};
</script>
<style lang=""></style>
