<template>
  <div class="booking-item">
    <q-expansion-item>
      <template v-slot:header>
        <q-item-section>
          <span class="text-subtitle1 text-bold">{{props.booking.vehicle_full_name}}  {{ props.booking.booking_code }}</span>
          <p class="text-caption">{{filterDate(props.booking.pickup_date)}} - {{filterDate(props.booking.estimated_return_date)}}</p>
        </q-item-section>
      </template>

      <q-card>
        <q-card-section>
          <div class="flex q-pa-sm q-px-md">
            <div class="half-width">
              <span class="full-width text-subtitle1 text-bold">Renters Details:</span>
              <q-item class="q-pa-none">
                <q-item-section>
                  <q-item-label><q-icon name="person" class="q-mr-sm" />{{props.booking.operator_name}}</q-item-label>
                  <q-item-label caption
                    ><q-icon name="email" class="q-mr-sm" /> johndoe@gmail.com</q-item-label
                  >
                  <q-item-label caption
                    ><q-icon name="phone" class="q-mr-sm" /> +63944233828919</q-item-label
                  >
                </q-item-section>
              </q-item>
            </div>

            <div class="half-width">
              <div class="full-width text-subtitle1 text-bold">
                Booking Details:
              </div>Status: 
              <BookingStatusBadge :status="[props.booking.status]" class="q-mx-sm" />
              <div>Total Price: PHP {{props.booking.total_rental_fee}}</div>
            </div>
            <div class="full-width flex justify-end gap-10">
              <div class="row items-center" v-if="props.booking.status == 1 || props.booking.status == 0">
                <q-btn
                  color="primary"
                  dense
                  label="Reject"
                  size="12px"
                  rounded
                  class="q-px-sm"
                  @click="reject()"
                />
                <q-btn
                  color="positive"
                  dense
                  label="approve"
                  size="12px"
                  class="q-ml-sm q-px-sm"
                  rounded
                  @click="approve()"
                />
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </div>
</template>
<script setup>
import moment from "moment";
import { useStore } from 'vuex'
import { defineProps } from "vue";
import { notify } from "../../utils/notification";
import BookingStatusBadge from "../Globals/BookingStatusBadge.vue";

const store = useStore();
const props = defineProps(["booking","approved"]);


function filterDate(date) {
  return moment(new Date(date)).format("YYYY-MM-DD hh:mm A");
}

function approve() {
  store.dispatch("booking/approveBooking", props.booking.id).then((response) => {
    if(response.status == 'success') {
      notify("positive", "Application Confirmed.");
      location.reload();
    }
  })
}

function reject() {
  const payload = props.booking.id
  store.dispatch("booking/rejectBooking",payload).then((response) => {
    if(response.status == 'success') {
      notify("positive", "Application Rejected.");
    }
  })
}

</script>

